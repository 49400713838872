import azureClient from "../client/azureClient"
import { DEFAULT_PARAMS_ONE } from "../client/default.network"

export async function getSummaryService(userId) {

    return azureClient.get("/getsummary");
}


export async function getRealTimeUserJourney(){
    DEFAULT_PARAMS_ONE.topicId = "";
    return azureClient.get("/realtimeuserjourney");
}