import axiosClient from "../client/axiosClient";
import azureClient from "../client/azureClient";
import { DEFAULT_PARAMS_ONE } from "../client/default.network";

export async function loadJourney(screenName) {
    DEFAULT_PARAMS_ONE.screenName = screenName
    return azureClient.get("/loadjourney")
}

export async function moveForwardJourney(request) {
    return azureClient.post("/moveforwardcircumstances", request)
}

export async function forwardJourney(request) {
    return azureClient.post("/forward", request)
}

export async function backwardJourney(request) {
    return azureClient.post("/backward", request)
}

export async function startJourney(userId) {
    return azureClient.post("/startjourney", userId)
}

export async function editJourney(request) {
    return azureClient.post("/editjourney", request)
}

export async function resumeJourney(request) {
    return azureClient.get("/resumejourney", request)
}


export async function getSupport(id) {
    DEFAULT_PARAMS_ONE.topicId = id
    return azureClient.get("/getccusupport")
}

export async function getSupportOne(id) {
    DEFAULT_PARAMS_ONE.topicId = id
    DEFAULT_PARAMS_ONE.screenName = ""
    return azureClient.get("/getccusupport-onepage")
}

export async function saveSupportOne(body) {
    return azureClient.post("/saveccusupport", body)
}

export async function completeJourney(request) {
    return axiosClient.post("/complete_user_journey", request);
}

export async function getLocalSupport(postcode) {
    const queryParams = new URLSearchParams({
        "postcode": postcode
    });
    const config = {
        params: queryParams
    };

    return axiosClient.get("/get_local_support", config);
}

export async function getNationalSupport(postcode) {
    const queryParams = new URLSearchParams({
        "postcode": postcode
    });
    const config = {
        params: queryParams
    };
    return axiosClient.get("/get_national_support", config);
}

export async function getNationalSupportID() {
    return axiosClient.get("/get_national_support_dashboard");
}


export async function getReviewandRating(supportoptionId, pageno, sortbyrating, filterbyrating, sortbydate) {

    const queryParams = new URLSearchParams();
    queryParams.append("support_option_id", supportoptionId);
    queryParams.append("page_no", pageno);
    queryParams.append("page_size", 10); // page_size is always 10
    if (sortbyrating !== null) queryParams.append("sort_by_rating", sortbyrating);
    if (filterbyrating !== null) queryParams.append("filter_by_rating", filterbyrating);
    if (sortbydate !== null) queryParams.append("sort_by_date", sortbydate);

    const config = {
        params: queryParams
    };

    return axiosClient.get("/get_review_and_rating", config);
}

export async function getLocalSupportID() {
    return axiosClient.get("/get_local_support_dashboard");
}

export async function AddRemoveSupportOptions(body) {
    return axiosClient.post("/update_support_options", body);
}

export async function saveRatingReview(body) {
    return axiosClient.post("/set_rating_and_comment", body)
}

export async function saveLocalSupport(body) {
    return azureClient.post("/savelocalsupportdata", body)
}

export async function saveNationalSupport(body) {
    return azureClient.post("/savenationalsupportdata", body)
}


