import React, { useContext, useEffect } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import literals from "../Literals/Literals";
import { ScrollPanel } from "primereact/scrollpanel";
import ExitButton from "../ExitButton/ExitButton";
import { ExitContext } from "../../contexts/exit.context";
import "../global.scss";
import "./cookiepolicy.scss";

function CookiePolicy () {
  const themeselected = localStorage.getItem("theme");
  const { setServiceExit, isChildNewTab } = useContext(ExitContext);

  useEffect(() => {
    isChildNewTab.current = true;
    setServiceExit(window);
    console.log("theme",themeselected)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="PN-parent-container-2">
      {/*  Header */}
      <div className="top-container-2">
        <Header tabIndex={0} homepage={false} />
        {/* <ProgressBarComponent pageName="feedback" /> */}
   <div className="buttonContainerTop"> 
   <div className="fixExitButton">
      <ExitButton tabIndex={0} />
    </div>
   </div>
   <div className="headContPC">
          <hr className="divider dividerFix privacyFix" />
          <h2 tabIndex={0} className="heading heading-noScroll headContPC">
            {" "}
            {literals.CookiePolicy.heading}
          </h2>
          <hr className="divider dividerFix headingBottom" />
        </div>
      </div>

      <div className="bottom-container-2">
        <ScrollPanel
          pt={{ root: { style: { width: "100%", height: "100%" } } }}
        >
          <div className="internal-container centerIt">
            <div className="PN-internal-child-1  i-child-width">
            <p tabIndex={0} className="text-content justify-text pn-hsmall"> {literals.CookiePolicy.para1} </p>
            <strong tabIndex={0} className="text-content justify-text pn-hsmall"> {literals.CookiePolicy.para2} </strong>
            <li tabIndex={0} className="text-content justify-text pn-hsmall"> {literals.CookiePolicy.para3} </li>
            <li tabIndex={0} className="text-content justify-text pn-hsmall fixULCP"> {literals.CookiePolicy.para4} </li>
            <li tabIndex={0} className="text-content justify-text pn-hsmall fixULCP"> {literals.CookiePolicy.para5} </li>
            <strong tabIndex={0} className="text-content justify-text pn-hsmall"> {literals.CookiePolicy.SubHeadingpara1} </strong>
            <li tabIndex={0} className="text-content justify-text pn-hsmall fixULCPSub"> {literals.CookiePolicy.SubHeadingpara2} </li>
            <li tabIndex={0} className="text-content justify-text pn-hsmall fixULCPSub"> {literals.CookiePolicy.SubHeadingpara3} </li>
            <ul className="square-list">
            <li tabIndex={0} className="text-content justify-text pn-hsmall fixULCPSubpara square-bullets"> {literals.CookiePolicy.SubHeadingpara4} </li>
            <li tabIndex={0} className="text-content justify-text pn-hsmall fixULCPSubpara square-bullets"> {literals.CookiePolicy.SubHeadingpara5} </li>
            <li tabIndex={0} className="text-content justify-text pn-hsmall fixULCPSubpara square-bullets"> {literals.CookiePolicy.SubHeadingpara6} </li>
            </ul>
            <li tabIndex={0} className="text-content justify-text pn-hsmall fixULCPSub"> {literals.CookiePolicy.SubHeadingpara7} </li>
            <li tabIndex={0} className="text-content justify-text pn-hsmall fixULCPSub"> {literals.CookiePolicy.SubHeadingpara7_1} </li>
            <ul className="square-list">
            <li tabIndex={0} className="text-content justify-text pn-hsmall fixULCPSubpara square-bullets"> {literals.CookiePolicy.SubHeadingpara8} </li>
            <li tabIndex={0} className="text-content justify-text pn-hsmall fixULCPSubpara square-bullets"> {literals.CookiePolicy.SubHeadingpara9} </li>
            <li tabIndex={0} className="text-content justify-text pn-hsmall fixULCPSubpara square-bullets"> {literals.CookiePolicy.SubHeadingpara10} </li>
            </ul>
            <li tabIndex={0} className="text-content justify-text pn-hsmall"> {literals.CookiePolicy.SubHeadingpara11} </li>
            <strong tabIndex={0} className="text-content justify-text pn-hsmall fixULCP"> {literals.CookiePolicy.SubHeadingpara12} </strong>            
            <ul className="square-list">
            <li tabIndex={0} className="text-content justify-text pn-hsmall fixULCPSub square-bullets"> {literals.CookiePolicy.SubHeadingpara13} </li>
            <li tabIndex={0} className="text-content justify-text pn-hsmall fixULCPSub square-bullets"> {literals.CookiePolicy.SubHeadingpara14} </li>
            <li tabIndex={0} className="text-content justify-text pn-hsmall fixULCPSub square-bullets"> {literals.CookiePolicy.SubHeadingpara15} </li>
            <li tabIndex={0} className="text-content justify-text pn-hsmall fixULCPSub square-bullets"> {literals.CookiePolicy.SubHeadingpara16} </li>
            <div tabIndex={0} className="chrome "><span className="visual">Google Chrome setting image</span></div>
            <li tabIndex={0} className="text-content justify-text pn-hsmall fixULCPSub square-bullets"> {literals.CookiePolicy.SubHeadingpara17} </li>
            </ul>
            <strong tabIndex={0} className="text-content justify-text pn-hsmall fixULCP"> {literals.CookiePolicy.SubHeadingpara18} </strong>
            <li tabIndex={0} className="text-content justify-text pn-hsmall fixULCPSub"> {literals.CookiePolicy.SubHeadingpara19} </li>
            <ul className="square-list">
            <li tabIndex={0} className="text-content justify-text pn-hsmall fixULCPSubpara square-bullets"> {literals.CookiePolicy.SubHeadingpara20} </li>
            <li tabIndex={0} className="text-content justify-text pn-hsmall fixULCPSubpara square-bullets"> {literals.CookiePolicy.SubHeadingpara21} </li>
            <li tabIndex={0} className="text-content justify-text pn-hsmall fixULCPSubpara square-bullets"> {literals.CookiePolicy.SubHeadingpara22} </li>
            <div tabIndex={0} className="firefox "><span className="visual">firefox setting image</span></div>
            <li tabIndex={0} className="text-content justify-text pn-hsmall fixULCPSubpara square-bullets"> {literals.CookiePolicy.SubHeadingpara23} </li>
            </ul>
            <strong tabIndex={0} className="text-content justify-text pn-hsmall fixULCP"> {literals.CookiePolicy.SubHeadingpara24} </strong>
            <li tabIndex={0} className="text-content justify-text pn-hsmall fixULCPSub"> {literals.CookiePolicy.SubHeadingpara25} </li>
            <ul className="square-list">
            <li tabIndex={0} className="text-content justify-text pn-hsmall fixULCPSubpara square-bullets"> {literals.CookiePolicy.SubHeadingpara26} </li>
            <li tabIndex={0} className="text-content justify-text pn-hsmall fixULCPSubpara square-bullets"> {literals.CookiePolicy.SubHeadingpara27} </li>
            <li tabIndex={0} className="text-content justify-text pn-hsmall fixULCPSubpara square-bullets"> {literals.CookiePolicy.SubHeadingpara28} </li>
            <div tabIndex={0} className="microEdge "><span className="visual"> Microsoft Edge setting image</span></div>
            <li tabIndex={0} className="text-content justify-text pn-hsmall fixULCPSubpara square-bullets"> {literals.CookiePolicy.SubHeadingpara29} </li>
            </ul>
            <li tabIndex={0} className="text-content justify-text pn-hsmall"> {literals.CookiePolicy.SubHeadingpara30} </li>
            </div>
          </div>
        </ScrollPanel>
        <div className="internal-child-3 fixFooter">
                <Footer tabIndex={0} theme={themeselected} homepage={true} />
              </div>
      </div>
    </div>
  );
}


export default CookiePolicy ;