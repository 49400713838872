import React, { useState } from 'react'
import Joyride from "react-joyride";
import './HomeComponent.scss';
import literals from '../Literals/Literals.js';
import ButtonComponent from '../ButtonComponent/ButtonComponent.jsx';
import Footer from "../Footer/Footer";
import { useNavigate } from "react-router-dom";
import PageTimeTracking from "../PageTracking/PageTracker";
import { useResumeJourney } from "../../customHooks/useResumeJourney";
import { useDispatch } from "react-redux";
import { setPrivacyUrl, setTnCUrl } from "../../redux/actions/privacyUrlAction";
import { setSafeToggle } from '../../redux/actions/safeToggleAction.js';
import { clientContactNumberAction } from '../../redux/actions/clientContactNumber.js';
import { setRealTimeUserJourney } from '../../redux/actions/realTimeUserJourneyAction.js';
import { setUserId } from "../../redux/actions/userActions";


const HomeComponent = (props) => {
    const [run,setRun] = useState(false)
    const navigation = useNavigate();
    const getItemFromHelper = useResumeJourney();
    const nextPage = getItemFromHelper.nextPage;
    const steps = [
        {   target:".enterStyle",
            content:<h2>{literals.help.welcome.welcome_Enter}</h2>,
            placement: 'bottom',
            disableBeacon: true,
        }
    ]
  
    console.log("rendering home");
    const dispatch = useDispatch();
    console.log("userID home component",props.userId);
    const userId = props.userId;
    const privacyUrl = props.privacyUrl;
    const tncUrl= props.tncUrl
    const clientContactNumber = props.contactNumber;
    const notifServiceReq = props.clientNotifService;
    dispatch(clientContactNumberAction(clientContactNumber));
    dispatch(setPrivacyUrl(privacyUrl));
    dispatch(setTnCUrl(tncUrl));
    dispatch(setSafeToggle(false));
    dispatch(setRealTimeUserJourney(notifServiceReq));
    const handleShowHelp = () => {
        setRun(true);
    }

    const handleJoyrideCallback = (data) => {
        const { status, index, type } = data;
        const finishedStatuses = ['finished', 'skipped'];
    
        if (
          finishedStatuses.includes(status) ||
          (type === 'step:after' && index === steps.length - 1)
        ) {
          setRun(false);
        }
      };

  return (
    <div>
    <Joyride
    steps={steps}
    run={run}
    continuous
    callback={handleJoyrideCallback}
    spotlightClicks={false}
    scrollToFirstStep
    hideBackButton={true}
        locale={{last: 'Close'}}
    styles={{
      options: {
        arrowColor: '#fff',
        backgroundColor: '#fff',
        overlayColor: 'transparent',
        primaryColor: '#000',
        textColor: '#000',
        zIndex: 1000,
        spotlightPadding:10,
      },
    }}
    />
    <div className='homeStyle' alt="home_background">
        <div className='upper_layer'>
            <div className="sp_logo" alt="sp_logo" tabIndex={0}><span className="visual">Support Point Logo</span>   
            </div>

            <div id="numero1" className="help_w" alt="sp_logo" tabIndex={0} onClick={handleShowHelp}><span className="visual">Help icon</span>   
            </div>
                <div className='headings'>
                    <h1 className='heading1' tabIndex={0}>&ensp;{literals.home.text0}</h1>
                    <h2 className='heading2' id="numero1" tabIndex={0}>{literals.home.text1}</h2>
                    <ButtonComponent   classname="enterStyle"
                        divClass="buttonMobStyle" 
                        tabIndex={0}
                        name="Enter" 
                        userId="numero1"
                        disabled={false} 
                        onClick={() => {
                                    navigation(`/${nextPage}`, { replace: true });
                                }}
                    />
                </div>
                </div>
   
        </div>
        <Footer screenName="Home" theme={props.theme} homepage={true} />
        <PageTimeTracking pageName="Home" />
    </div>



  )
}

export default HomeComponent