import axiosClient from "../client/axiosClient";

export async function Save_Column(body) {
  return axiosClient.post("/save_column", body)
}

export async function final_output(body) {
  return axiosClient.post("/final_output", body);
}

export async function Save_Feedback(body) {
  return axiosClient.post("/save_user_feedback", body);
}