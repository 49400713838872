import React from "react";
import "./BackButton.scss";
        
const BackButton = (props) => {
  const { hideImg, label, onClick, disabled ,tabIndex} = props;
 
  return (
    <>
    {!hideImg ? <div>
      <button tabIndex={tabIndex} className={`backButton`} onClick={onClick} disabled={disabled}>
        <span className="button-label">{label}</span>
      </button>
    </div> : <div>
      <button tabIndex={tabIndex} className={`backButtonWI`} onClick={onClick} disabled={disabled}>
        <span className="button-label">{label}</span>
      </button>
    </div>}
    </>
  );
};

export default BackButton;
