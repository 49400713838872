import { createContext, useRef } from "react";

export const GlobalContext = createContext({
    isDemographicsConsent: null,
    setDemographicsConsent: () => null
})

export const GlobalProvider = ({ children }) => {

    const isDemographicsConsent = useRef(true);

    const setDemographicsConsent = (status) => {
        isDemographicsConsent.current = status
    }

    const value = {
        isDemographicsConsent,
        setDemographicsConsent
    };

    return (
        <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
    )
}