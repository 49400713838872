const literals = {
    buttons: {
        startButton: {
            buttonText: "Proceed",
            variant: "primary",
        },
        disagreeButton: {
            buttonText: "Not happy to proceed",
            variant: "primary",
        },
        getStartedButton: {
            buttonText: "Get started",
            variant: "primary",
        },
        nextStep: {
            buttonText: "Next",
            variant: "primary",
        },
        back: {
            buttonText: "Home",
            variant: "primary",
        },
        backNav: {
            buttonText: "Back",
            variant: "primary",
        },
        okay: {
            buttonText: "Okay",
            variant: "primary",
        },
        yes: {
            buttonText: "Yes",
            variant: "primary",
        },
        no: {
            buttonText: "No",
            variant: "primary",
        },
        SubmitNav: {
            buttonText: "Submit",
            variant: "primary",
        },
        DownloadActionPlan: {
            buttonText: "Download Action Plan",
            variant: "primary",
        },
        viewActionPlan: {
            buttonText: "View Action Plan",
            variant: "primary",
        },
        shareOnEmail: {
            buttonText: "Share On Email",
            variant: "primary",
        },
        shareOnWhatsapp: {
            buttonText: "Share On Whatsapp",
            variant: "primary",
        },
        showFeedback: {
            buttonText: "Feedback",
            variant: "primary",
        },
        submitForm: {
            buttonText: "Submit & Proceed",
            variant: "primary",
        }


    },
    footer: {
        link1: "Privacy Notice",
        link2: "About this service",
        link3: "Give Feedback",
        link4: "Cookie Policy"
    },
    home: {
        text0: "Supporting our customers",
        text1: "when they need it most",
        footerText: "Brought to you by Sopra Steria and The University of Edinburgh in collaboration with various charities",
        text2: "Copyright",
        text3: "Support Point",
        text4: "Sopra Steria 2023"
    },
    needhelp: {
        notSureOption: "I'm not sure",
        inputAdvisory: "We will be in touch to offer relevant support."
    },
    welcome: {
        heading: "Welcome to Covid-19 Support Finder",
        text2: "You can find support in four easy steps:",
        text3: "How does it work?",
        text4: "This tool is designed to help you share your circumstances and find relevant support.",
        text5: "Thank you for providing details about your situation. These are the steps you can take:",
        text6: "Learn from others:",
        title1: "My circumstances",
        description1: "Short questions about your health, finances, life events and how you use services",
        title2: "How this impacts me",
        description2: "Short questions to help us understand how these circumstances impact you when using our services",
        title3: "Choose support",
        description3: "Support recommendations from us as well as national and local services",
        title4: "My support plan",
        description4: "An interactive tool, to help you manage your support options and decide which ones to look at today",
        ribbonButtonsPre: ["Tell us about yourself", "View your options", "Know your rights", "View you Action Plan", "Hear from others"],
        ribbonButtons: [{ pretext: "Tell us about yourself", displayText: "We will ask you a few questions about your situation. None of your personal information will be stored or shared." },
        { pretext: "View your options", displayText: "Based on your responses, we will provide you with a number of free support options and guide you to a set of useful on-line tools." },
        { pretext: "View your action plan", displayText: "Take a set of actions away with you which will help you today, and in the longer term by building up a stronger financial and emotional resilience." },
        { pretext: "Hear from others", displayText: "Listen to others tell their stories of how they overcame similar challenges and get some top tips from the experts." }]
    },
    ConsentPage: {
        subheading2: "Everything you share is confidential. We will use this information to tailor the service we provide you.",
        subheading3: "You can click ‘Exit’ at the top right at any time. If you return, your journey will be saved.",
        linkText: "Privacy Notice",
        linkText2: " Terms & Conditions",
        subheading4: "Please read our Privacy Notice, as well as our",
        subheading5: " to understand how we will use your data. Each will open in a new tab.",
        checkBoxString1: "I agree to the",
        checkBoxString2: "and"
    },
    Circumstances: {
        description1: "First, let's explore what's happening in your life, which can make it harder to use services as you'd like to. Click on a category below and then select any of the circumstances that impact you. You can select as many or as few as you like."
    },
    Impacts: {
        description1: "For each of the circumstances you selected in Step 1, we will suggest how this might impact you. Select all options that apply to you.",
        description2: "That's the end of Step 2.",
        description3: "Before we show you some support options, we'd like to check whether you're happy with the impacts you've selected for each circumstance.",
        description4: "How this impacts me ",
        description5: "Then we can move onto Step 3 - choose support.",
        heading: "How this impacts me",
        subheading: "For each of the circumstances you selected in Step 1, we will suggest how this might impact you. Select all options that apply to you.",
        optionSubheading: "Select all that apply",
    },
    Demographic: {
        heading: "Well done!",
        subheading1: " That's the hard work done.",
        subheading2: "Based on what you have shared, we're preparing your Support Plan.",
        subheading3: "Before we share this, would you be happy to share some feedback on your experience so far. This will help us improve the support we offer."
    },
    Options: {
        heading: "You’ve indicated that none of the circumstances we’ve listed apply to you. Would you like to look again, or write us a message to say how we can help?",
        option1: "Look at circumstances again",
        option2: "Let us know how can we help",
        placeholder: "Please let us know about your circumstances, how they impact you when using our service, and what help you’d like from us."
    },
    ExitPage: {
        heading1: "Thank you",
        heading2: "We have the information you've shared and will get in touch with you soon",
        description: "We'll be able to discuss your circumstances and find out what support would help you. If you'd like to call us,our number is 0800 123 4567. We're available 8am - 8pm, Monday to Saturday.",
        description2: "If you'd like to have another look at the circumstances we cover, and continue to Step 2, please click 'back'.",
    },
    NationalAndLocal: {
        heading: "Support from other organisations",
        title: "These organisations may provide relevant support to you. We'll include their website links in your Support Pack so you can find out more and decide whether to contact them. Please untick any that you don't want in your Pack."
    },
    ChoosingSupport: {
        title: "In this final section, you can choose support options from us and other organisations. Firstly, how can we help? Select all options that apply to you.",
        noData: "We're sorry our list of impacts didn't fit your circumstances. This means we aren't able to suggest what support we can provide, but if you know what would help you, please complete the box below. Either way, we will be in touch to discuss this with you. Please click 'continue' to see support options from other organisations.",
        title2: "Support from us",
        tempHeading: "We want to make sure the information we hold stays up to date.",
        tempPara: "Please can you confirm when you'd like to review your support needs?",
        tempReviewPeriod: [{ id: 3, value: "3 months" }, { id: 6, value: "6 months" }, { id: 9, value: "9 months" }, { id: 12, value: "12 months" }],
    },
    Output: {
        heading: "Your Support Pack",
        subheading1: "Your Support Pack contains information that will help you, based on the information you provided.",
        subheading2: "Click below to download, view and save your Pack. Then you can print it or email to yourself or a trusted person.",
        subheading3: "If you are using assisstive technology, a downloaded, save copy of the Pack is accessible.",
        text0: "Sorry, that's not worked. Please try again.",
        PDF_text3: "Action Plan",
        PDF_text4: "Credit Union support",
        PDF_text2: "Support Pack",
        PDF_text1: "Thank you for sharing some information about your circumstances and the impact these have on you. Support Point has used this to create support recommendations that are tailored to you. Save this document so that you can refer back to it. Your credit union will also have a copy, so they know how to support you.",
        PDF_text5: 'Support from other organisations',
        PDF_text6: "Based on what you've shared, we think these organisations might also be able to support you too. Click on each organisation's name to view their website and find out more. If you're viewing this document in your browser, websites will open in the same tab. Navigate back to return to your Support Pack.",
        PDF_text7: "We understand that it can be difficult to consume so much information at once. So, based on your responses, we have included a step-by-step action plan designed to help you move at your own pace. It is also designed to support you in building up longer-term financial, emotional, and physical resilience."
    },
    ThankYou: {
        heading: "Thank you",
        subheading1: "We know it can be hard to think about these circumstances. In step 2 we will ask how they impact you.",
        subheading2: "You can now exit the tool. Remember, your answers have been saved so you can come back any time to review them or download your Pack.",
    },
    ThankYouOne: {
        heading: "Thank you",
        subheading1: "We know it can be hard to think about these circumstances. In step 2 we will ask how they impact you.",
        subheading2: "Select all that apply"
    },
    ThankYouTwo: {
        heading: "Thank you",
        subheading1: "That's the end of Step 2.",
        subheading2: "Before we show you some support options, we'd like to check whether you're happy with the impacts you've selected for each circumstance.",
        subheading3: "Then we can move onto Step 3 - choose support."
    },
    DemoConsentPage: {
        subheading1: "That's the hard work done. Based on what you have shared, we're preparing your Support Pack.",
        subheading2: "Before we share this, would you be happy to answer a few more quick questions? This will help us understand your answers and improve the support we offer.",
    },
    FeedBack: {
        mainHeading: "Please share your feedback",
        mainSubheading: "Please select one or more options to describe your circumstances in more detail",
        heading: "How did we do?",
        subheading: "Overall, how do you feel about using Support Point today?",
        subheadingComment: "What is good about Support Point? What could be improved?",
        commentPlaceHolder: "Enter your comments here",
        options: [{ id: 1, value: "Very satisfied" }, { id: 2, value: "Satisfied" }, { id: 3, value: "Neither satisfied or dissatisfied" }, { id: 4, value: "Dissatisfied" }, { id: 5, value: "Very dissatisfied" }],
        thankYou: "Thank you!",
        thankyouText: "We really value your feedback, we will get in touch with you",
        buttonTextShare: "Share feedback",
        buttonTextProceed: "Proceed to app"
    },
    CookiePolicy: {
        heading: "Support Point Local Storage Policy",
        para1: "Our website uses “Local Storage” technology to distinguish you from other users of our website. Our aim is to provide you with a good experience when you browse our website and allows us to constantly identify improvments to our site. By continuing to browse the site, you are agreeing to our use of this local storage policy.",
        para2: "Local Storage Technologies are a types of data storage and retrieval mechanism used on the user’s device to persist useful information.",
        para3: "The most common are:",
        para4: "• The local storage of the browser - Some websites use local storages called “Local Storage” to store information.",
        para5: "• Local Storage of browser add-ons - Local Flash storage (“Flash Local Shared Objects”) or the Silverlight local storage (“Isolated Objects”).",
        SubHeadingpara1: "Local Storage of browser:",
        SubHeadingpara2: "• Local storage (localStorage) allows JavaScript sites and applications to save key-value pairs in a web browser with no expiration date.",
        SubHeadingpara3: "• Key points:",
        SubHeadingpara4: "   Data stored in local storage persists even after the user closes the browser or restarts the computer.",
        SubHeadingpara5: "   it is more secure than cookies and has a larger storage limit (at least 5MB).",
        SubHeadingpara6: "   Information is never transferred to the server.",
        SubHeadingpara7: "• Use cases:",
        SubHeadingpara7_1: "Local storage is commonly used for:",
        SubHeadingpara8: "   Saving user input in forms until submission.",
        SubHeadingpara9: "   Storing user preferences in static websites.",
        SubHeadingpara10: "  Basic persistence to enhance user experience.",
        SubHeadingpara11: "To delete local storage data in a web browser, follow these steps:",
        SubHeadingpara12: "1. Google Chrome:",
        SubHeadingpara13: "    Open Google Chrome and navigate to the website for which you want to clear local storage.",
        SubHeadingpara14: "    Press F12 to open the Developer Tools menu.",
        SubHeadingpara15: "    Click the Application tab in the Developer Tools panel.",
        SubHeadingpara16: "    Under Storage, select Local Storage.",
        SubHeadingpara17: <p>
            You’ will see a list of all the storage keys saved for the website. <a href='https://www.groovypost.com/howto/clear-local-storage-for-a-website-or-extension-in-google-chrome/'>Right-click on your site(s) and choose Clear to delete the local storage.</a>
        </p>,
        SubHeadingpara18: "2. Firefox:",
        SubHeadingpara19: "   • The process is similar in Firefox:",
        SubHeadingpara20: "      Open the Developer Tools (press F12 or right-click and select Inspect Element).",
        SubHeadingpara21: "      Go to the Storage tab.",
        SubHeadingpara22: "      Choose Local Storage.",
        SubHeadingpara23: <p> <a href='https://www.leadshook.com/help/how-to-clear-local-storage-in-google-chrome-browser/'> Right-click on your site(s) and select Clear.</a></p>,
        SubHeadingpara24: "3. Microsoft Edge (Chromium-based):",
        SubHeadingpara25: "   • To view, edit, and delete local storage key-value pairs in Microsoft Edge:",
        SubHeadingpara26: "      Open the Developer Tools (press F12 or right-click and select Inspect).",
        SubHeadingpara27: "      Navigate to the Application tab.",
        SubHeadingpara28: "      Click on Local Storage in the left menu.",
        SubHeadingpara29: <p> <a href='https://learn.microsoft.com/en-us/microsoft-edge/devtools-guide-chromium/storage/localstorage'>You can then manage your local storage data.</a></p>,
        SubHeadingpara30: "Please note, however, that if you choose to delete or decline certain local storage key-value, this may affect certain features or services on our site."
    },
    PrivacyNotice: {
        heading: "Sopra Steria Financial Services Limited",
        PrivacyNotice: "Privacy Notice",
        para1: "This Privacy Notice aims to give you information on how Sopra Steria Financial Services Limited (SSFSL) collects and processes your personal data, including any data you may provide to our clients.",
        para2: "This Privacy Notice should be considered alongside any other Privacy Notices, Informed Consent Notices, or Fair Processing Statements which we may provide relating to particular activities and is not intended to override them.",
        sub1: "WHO WE ARE ",
        subpara1: "For the purpose of this Privacy Notice, we are Sopra Steria Financial Services  Limited UK. Our contact details are: ",
        subtableHeadings: ["COMPANY NAME ", "CO REGISTERED NUMBER", "ICO REGISTRATION", "REGISTERED OFFICE", "HOME PAGE URL"],
        subtablecontent: ["Sopra Steria Financial Services Limited", "13181306", "Z5873869", ["Three Cherry Trees Lane", "Hemel Hempstead", "HP2 7AH", "Herts", "Tel + 44 (0) 370 600 4466", "info.uk@soprasteria.com"], "www.supportpoint.co.uk"],
        subpara2: "This Privacy Notice is issued by Sopra Steria Financial Services Limited so when we mention “Company,” “we”, “us”, “our” or “Sopra Steria” in this privacy notice, we are referring to Sopra Steria Limited.",
        subpara3: "SSFSL have appointed a Data Protection Officer (DPO) who is responsible for overseeing questions in relation to this Privacy Notice. If you have any questions, including any requests to exercise your legal rights, please contact the DPO using the details below.",
        sub2: "Data Protection Officer",
        sub2para1: <p>The Data Protection Officer, based at Sopra Steria Limited, Three Cherry Trees Lane, Hemel Hempstead, HP2 7AH. The contact email address is <a href="mailto:dpo.uk@soprasteria.com">dpo.uk@soprasteria.com</a>.</p>,
        sub2para2: <p>
            The Supervisory Authority is the UK Information Commissioner’s Office (ICO) <a href="https://www.ico.org.uk">https://www.ico.org.uk</a>, and you have the right to make a complaint to them for data protection issues at any time. Please raise any concerns or complaints with our DPO first, so we can resolve the issue.
        </p>,
        sub3: "Our Commitment to your privacy ",
        sub3para1: "Sopra Steria respects your privacy and is committed to protecting your personal data. This Privacy Notice details how we collect and manage your personal data, your privacy rights and how the law protects you.",
        sub3para2: "We provide this information in a layered format so you can click through to the specific areas set out below. Alternatively, you can download a full pdf version. Definitions are in section 13, at the end.",
        sub3para3: "We may change this Notice in future. Please check it every time you use Support Point. Any changes will be listed in section 12.",
        sections: [
            {
                title: "Contents",
                content: <>
                    <ol className="fixUL">
                        <li className="justify-text pn-hsmall" tabIndex={0}>Purpose</li>
                        <li className="justify-text pn-hsmall" tabIndex={0}>Data Protection Principles</li>
                        <li className="justify-text pn-hsmall" tabIndex={0}>The types of information we hold about you</li>
                        <li className="justify-text pn-hsmall" tabIndex={0}>How your personal information is collected</li>
                        <li className="justify-text pn-hsmall" tabIndex={0}>How we may use your personal data</li>
                        <li className="justify-text pn-hsmall" tabIndex={0}>Purposes for which we may use your personal data</li>
                        <li className="justify-text pn-hsmall" tabIndex={0}>Data sharing</li>
                        <li className="justify-text pn-hsmall" tabIndex={0}>Data security</li>
                        <li className="justify-text pn-hsmall" tabIndex={0}>Data retention</li>
                        <li className="justify-text pn-hsmall" tabIndex={0}>Legal Rights</li>
                        <li className="justify-text pn-hsmall" tabIndex={0}>Data Protection Officer</li>
                        <li className="justify-text pn-hsmall" tabIndex={0}>Changes to this Privacy Notice</li>
                        <li className="justify-text pn-hsmall" tabIndex={0}>Definitions</li>
                    </ol>
                </>
            },
            {
                title: "0. Purpose",
                content: <>
                    <p className="text-content justify-text pn-hsmall" tabIndex={0} >The purpose of this Privacy Notice is to inform you when and how your personal data is used by us and to outline your rights. This complies with UK data protection law including the Data Protection Act 2018 (DPA 2018), the UK General Data Protection Regulation (UK GDPR), and the Privacy in Electronic Communications Regulations 2003 (PECR).</p>
                    <p className="text-content justify-text pn-hsmall" tabIndex={0} >We are the "data processor" and only process your personal data on documented instructions we receive from our clients. They are the “data controllers” and are responsible for actions related to how we hold and use your personal information.</p>
                </>
            },
            {
                title: "1. Data Protection Principles",
                content: <>
                    <p className="text-content justify-text pn-hsmall" tabIndex={0} >As a data processor we actively comply with the data protection principles set out in UK law. Together these say that personal information we hold about you must be:</p>
                    <ul className="fixUL">
                        <li className="justify-text pn-hsmall" tabIndex={0}>Used lawfully, fairly and in a transparent way;</li>
                        <li className="justify-text pn-hsmall" tabIndex={0}>Collected and used only for valid purposes that we have clearly explained to you;</li>
                        <li className="justify-text pn-hsmall" tabIndex={0}>Relevant to the purposes we have told you about and limited only to those purposes;</li>
                        <li className="justify-text pn-hsmall" tabIndex={0}>Accurate and kept up to date;</li>
                        <li className="justify-text pn-hsmall" tabIndex={0}>Kept only as long as necessary for the purposes we have told you about;</li>
                        <li className="justify-text pn-hsmall" tabIndex={0}>Kept securely and inform you if it’s shared with third parties, particularly if this is outside of the UK.</li>
                    </ul>
                </>
            },
            {
                title: "2. The types of information we hold about you",
                content: <>
                    <p tabIndex={0} className="text-content justify-text pn-hsmall" >Personal data, means any information from which a living person can be identified. It does not include data where your identity has been removed and cannot be traced (anonymous data).</p>
                    <p tabIndex={0} className="text-content justify-text pn-hsmall" >As a site user, you agree that we can collect, use, store and transfer personal data about you, including: </p>
                    <ul className="fixUL">
                        <li className=" justify-text pn-hsmall" tabIndex={0}><strong>Identity Data</strong> including first name, last name, title.</li>
                        <li className=" justify-text pn-hsmall" tabIndex={0}><strong>Contact Data</strong> including physical address, email address and telephone numbers, location/country.</li>
                        <li className=" justify-text pn-hsmall" tabIndex={0}><strong>Financial data</strong> including transaction data, account information, debt management data, or payment card information.</li>
                        <li className=" justify-text pn-hsmall" tabIndex={0}><strong>Technical Data</strong> including internet protocol (IP) address, login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform and other technology on the devices you use to access this website.</li>
                        <li className=" justify-text pn-hsmall" tabIndex={0}><strong>Usage Data</strong> including information you share on Support Point and about how you use the tool.</li>
                        <li className=" justify-text pn-hsmall" tabIndex={0}><strong>Client Data</strong> where we perform a service on behalf of our clients.</li>
                        <li className=" justify-text pn-hsmall" tabIndex={0}><strong>Health data</strong> including information about your health, mental health, emotional wellbeing, disability information, and vulnerability information, where you share this with us.</li>
                    </ul>
                    <p tabIndex={0} className="text-content justify-text pn-hsmall">We may also use your personal data to generate Aggregated Data. For example, we may aggregate your Usage Data to calculate the percentage of users accessing a specific website feature. Aggregated Data is not personal data in law as it is anonymised.<br />
                        There are "Special Categories" of more sensitive personal data which require a higher level of protection, such as details about your race or ethnicity, religious or philosophical beliefs, sexual orientation, financial wellbeing, health and vulnerabilities.
                    </p>
                </>
            },
            {
                title: "2.1 Maintaining data accuracy",
                content: <>
                    <p tabIndex={0} className="text-content justify-text pn-hsmall">1. It is important to us that the information we hold about you is accurate and current. Please update your Support Point record any time your circumstances, impacts or support needs change. How your personal information is collected. <br /> We use different methods to collect data from and about you including through:</p>
                    <ul className="fixUL">
                        <li className="justify-text pn-hsmall" tabIndex={0}><strong>Direct interactions.</strong> You may give us your Identity, Contact, Financial, Health, Vulnerability and other data by filling in forms or use our website or portal, or by corresponding with us by post, phone, email or otherwise.</li>
                        <li className="justify-text pn-hsmall" tabIndex={0}><strong>Third party sources.</strong> We may receive personal data about you from various third-party sources set out below:
                            <ul>
                                <li className="text-content justify-text pn-hsmall" tabIndex={0}>Identity, contact details, financial information, health, and vulnerability information from our clients for whom we provide a service to, or other third parties in the provision of those services.</li>
                            </ul>
                        </li>
                        <li className="justify-text pn-hsmall" tabIndex={0}><strong>Automated technologies or interactions.</strong> As you interact with our website, we may automatically collect Technical Data about your equipment, browsing actions and patterns which might be used with other identifying data to establish other personal information about you. We collect this data by using cookies, server logs and other similar technologies. We may also receive Technical Data about you if you visit other websites employing our cookies. Please see our Cookie Policy for further details.</li>
                    </ul>
                </>

            },
            {
                title: '3. How we may use your personal data',
                content: <>
                    <p tabIndex={0} className="text-content justify-text pn-hsmall">
                        We will only use your personal data when the law allows us to. SupportPoint will use your personal data in the following circumstances:
                    </p>
                    <ul className="fixUL">
                        <li className="justify-text pn-hsmall" tabIndex={0}>Where it is necessary in the performance of a contract, between us and our clients or suppliers;</li>
                        <li className="justify-text pn-hsmall" tabIndex={0}>Where it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override our legitimate interests (or those of the relevant third party), such as communicate with you about the services you use;</li>
                        <li className="justify-text pn-hsmall" tabIndex={0}>Where we are bound to comply with a legal or regulatory obligation.</li>
                    </ul>
                </>
            },
            {
                title: '4. Purposes for which we may use your personal data',
                content: <><p className="justify-text pn-hsmall" tabIndex={0} >We have set out below, in a table format, a description of all the ways we may use your personal data, and the lawful bases we rely on to do so. We have also identified what our legitimate interests are where appropriate.
                    Generally, we do not rely on consent as a lawful basis for processing your personal data. Where we do use consent, you have the right to withdraw consent to marketing any time by contacting us at <a href="info.uk@soprasteria.com">info.uk@soprasteria.com</a>, or by unsubscribing within any email communication we may send to you (found at the footer of each email).
                    Note that we may process your personal data for more than one lawful basis depending on the specific purpose(s) for which we are using your data.
                </p>
                    <table className="static-table">
                        <tr className="tr-heading">
                            <td tabIndex={0} className="td-text">Purpose/Activity</td>
                            <td tabIndex={0} className="td-text">Lawful basis</td>
                        </tr>
                        <tr className="tr-row">
                            <td tabIndex={0} className="td-text">To deliver services to our clients</td>
                            <td tabIndex={0} className="td-text">(a) In the performance of a contract</td>
                        </tr>
                        <tr className="tr-row">
                            <td tabIndex={0} className="td-text">To manage our relationship with you including:
                                <br />
                                (a) Notifying you about changes to our terms
                                <br />
                                (b) Asking you to provide feedback
                                <br />
                            </td>
                            <td tabIndex={0} className="td-text">(a) Necessary for our legitimate interests (for running our business, provision of administration and IT services, network security, to prevent fraud and in the context of a business reorganisation or group restructuring exercise)
                                <br />
                                (b) Necessary to comply with a legal obligation
                                <br />
                                (c) In the performance of a contract
                                <br />
                            </td>
                        </tr>
                        <tr className="tr-row">
                            <td tabIndex={0} className="td-text">To administer and protect our business and websites (including troubleshooting, data analysis, testing, system maintenance, support, reporting and hosting of data)</td>
                            <td tabIndex={0} className="td-text">(a) Necessary for our legitimate interests (for running our business, provision of administration and IT services, network security, to prevent fraud and in the context of a business reorganisation or group restructuring exercise)
                                <br />
                                (b) Necessary to comply with a legal obligation
                            </td>
                        </tr>
                        <tr className="tr-row">
                            <td tabIndex={0} className="td-text">To use data analytics to improve our website and related services</td>
                            <td tabIndex={0} className="td-text">Necessary for our legitimate interests (to define types of customers for services, to keep our website updated and to develop our business </td>
                        </tr>
                        <tr className="tr-row">
                            <td tabIndex={0} className="td-text">To meet our legal obligations and defend our interests in the event of a dispute.</td>
                            <td tabIndex={0} className="td-text">We may be subject to legal and regulatory obligations to disclose data and may also have a legitimate interest in using the data to defend ourselves in the event of legal action </td>
                        </tr>
                    </table>
                </>
            },
            {
                title: '4.1 Marketing',
                content: <p className="text-content justify-text pn-hsmall" tabIndex={0} >We do not actively market directly to customers of our clients.</p>
            },
            {
                title: '4.2 Cookies',
                content: <>
                    <p className="text-content justify-text pn-hsmall" tabIndex={0} >When you visit our websites or portals, they may leave a packet of information about your visit on your computer, and in return your computer may leave its IP address on the websites you visit - these are called cookies. You can set your browser to refuse all or some browser cookies, or to alert you when websites set or access cookies. You can find more information here. For more information about the cookies we use, please see our Cookie Policy.  You can opt out of cookies on this site at any time via our integrated “Cookie Management” link available in the footer on all pages and as a banner when you access our sites.
                        <br />  If you refuse the cookies, they will not be installed unless they are strictly necessary (a cookie to save your refusal).
                        <br />  If you disable or refuse cookies, please note that some parts of this website may become inaccessible or not function properly.
                    </p>
                </>
            },
            {
                title: '4.3 Change of purpose',
                content: <>
                    <p className="text-content justify-text pn-hsmall" tabIndex={0} >We will only use your personal data for the purposes for which we collected it.
                        <br />
                        If we need to use your personal data for an unrelated purpose, we will notify you and we will explain the lawful basis which allows us to do so.
                        <br />
                        Please note that we may process your personal data without your knowledge or consent, in compliance with this notice, where this is required or permitted by law.
                    </p>
                </>
            },
            {
                title: '4.4 Automated decision-making',
                content:
                    <p className="text-content justify-text pn-hsmall" tabIndex={0} >You will not be subject to decisions that will have a significant impact on you based solely on automated decision-making unless we have a lawful basis for doing so and we have notified you.</p>
            },
            {
                title: "4.5 Recording Calls",
                content: <>
                    <p className="text-content justify-text pn-hsmall" tabIndex={0} >We record some of the telephone calls you make to us, or we make to you or any other third party. This is for training, monitoring and quality purposes and to meet our legal and regulatory obligations. Some telephone calls may be observed by staff for training and development purposes.
                        <br />
                        We may keep a copy of the telephone calls for up to 6 years from the date the telephone call was made.
                    </p>
                </>
            },
            {
                title: "5. Data sharing",
                content: <>
                    <p className="text-content justify-text pn-hsmall" tabIndex={0} >We may in some circumstances have to share your data with third parties, including third-party service providers and other Sopra Steria Group entities. We require third parties to respect the security of your data and to treat it in accordance with the law.
                        <br /> We may in some circumstances transfer your personal information outside the UK or EU. Where this is required, we will ensure your rights are adequately protected through:
                    </p>
                    <ul className="fixUL">
                        <li tabIndex={0} className="justify-text pn-hsmall" >Findings of adequacy (countries deemed by the European Commission and the ICO to have adequate protections for personal data);</li>
                        <li tabIndex={0} className="justify-text pn-hsmall" >Standard Contractual Clauses (EU Model Clauses with UK the International Data Transfer Addendum)</li>
                        <li tabIndex={0} className="justify-text pn-hsmall" >Or any other protection deemed adequate by the ICO.</li>
                    </ul>
                </>
            },
            {
                title: "5.1 Why might we share your personal data with third parties?",
                content: <><p className="text-content justify-text pn-hsmall" tabIndex={0}>We will share your personal information with third parties where required by law. This may, in some circumstances, involve sharing special categories of personal data or, where relevant, data relating to criminal allegations. <br />
                    We may also share your personal information with:
                </p>
                    <ul className="fixUL">
                        <li className="justify-text pn-hsmall" tabIndex={0}><strong>Suppliers</strong> involved in providing services as part of our contract with you or for the purpose of communications or events;</li>
                        <li className="justify-text pn-hsmall" tabIndex={0}><strong>Professional</strong> advisers including lawyers, bankers, auditors, and insurers who provide consultancy, banking, legal, insurance and accounting services;</li>
                        <li className="justify-text pn-hsmall" tabIndex={0}><strong>Government</strong> Authorities like HM Revenue & Customs, regulators and other authorities who require reporting of processing activities in certain circumstances;</li>
                        <li className="justify-text pn-hsmall" tabIndex={0}><strong>Third parties</strong> to whom we may choose to sell, transfer, or merge parts of our business or our assets. Alternatively, we may seek to acquire other businesses or merge with them. If a change happens to our business, then the new owners may use your personal data in the same way as set out in this Privacy Notice.</li>
                    </ul>
                </>
            },
            {
                title: "5.2 Which third-party service providers process your personal information?",
                content: <p className="text-content justify-text pn-hsmall" tabIndex={0}>Some of our group systems are hosted by third parties who also process the data for the purposes set out in this Privacy Notice. These include our site hosting provider. Our site also contains cookies set by third parties, as set out in our Cookie Policy.</p>,
            },
            {
                title: "5.3	How secure is your information with third-party service providers?",
                content: <p className="text-content justify-text pn-hsmall" tabIndex={0}>All our third-party service providers are required to take appropriate security measures to protect your personal information in line with our policies. We only permit them to process your personal data for specified purposes and in accordance with our instructions.</p>,
            },
            {
                title: "5.4	When might we share your personal data with other organisations within the Sopra Steria Group?",
                content: <p className="text-content justify-text pn-hsmall" tabIndex={0}>Our group systems (including this website) are hosted in UK. Your personal information may be transmitted to, stored, and processed in France. We may share your personal information with other companies in the UK group or with wider Sopra Steria Group organisations for the purposes set out in ‘Purposes for which we will use your personal data’ above.</p>,
            },
            {
                title: "6. Data security",
                content: <>
                    <p className="text-content justify-text pn-hsmall" tabIndex={0} >We have put in place measures to protect the security of your information.<br />
                        Third parties will only process your personal information on our instructions and where they have agreed to treat the information confidentially and to keep it secure.<br />
                        We have put in place appropriate security measures to prevent your personal information from being accidentally lost, used, or accessed in an unauthorised way, altered, or disclosed. In addition, we limit access to your personal information to those employees, agents, contractors and other third parties who have a business need to know. They will only process your personal information on our instructions.<br />
                        We have put in place procedures to deal with any suspected data security breach and will notify you and any applicable regulator of a suspected breach where we are legally required to do so.<br />
                        We take the protection of personal information very seriously and we will maintain appropriate measures to maintain the confidentiality, integrity and availability of the information you have provided. Such measures include:<br />
                    </p>
                    <ul className="fixUL">
                        <li className="justify-text pn-hsmall" tabIndex={0}>staff security awareness</li>
                        <li className="justify-text pn-hsmall" tabIndex={0}>role based and biometric access controls to prevent unauthorised access to the information.</li>
                        <li className="justify-text pn-hsmall" tabIndex={0}>encryption and anonymisation technology.</li>
                        <li className="justify-text pn-hsmall" tabIndex={0}>Company security policies and standards</li>
                        <li className="justify-text pn-hsmall" tabIndex={0}>anti-malware technologies</li>
                        <li className="justify-text pn-hsmall" tabIndex={0}>security monitoring</li>
                        <li className="justify-text pn-hsmall" tabIndex={0}>security testing</li>
                        <li className="justify-text pn-hsmall" tabIndex={0}>secure archiving and deletion </li>
                        <li className="justify-text pn-hsmall" tabIndex={0}>compliance with industry regulation and legislation.</li>
                    </ul>
                </>
            },
            {
                title: "7. Data retention",
                content:
                    <>
                        <p className="text-content justify-text pn-hsmall" tabIndex={0} ><strong>How long will we keep your information for?</strong></p>
                        <p className="text-content justify-text pn-hsmall" tabIndex={0} >
                            We will retain your personal information for as long as necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, or reporting requirements.<br />
                        </p>
                    </>
            },
            {
                title: "8. Legal rights",
                content: <>
                    <p className="text-content justify-text pn-hsmall" tabIndex={0} ><strong>Your rights in connection with personal information</strong></p>
                    <p className="text-content justify-text pn-hsmall" tabIndex={0} >
                        You have the following legal rights in connection with your personal information. You are legally entitled to:<br />
                    </p>
                    <ul className="fixUL">
                        <li className="justify-text pn-hsmall" tabIndex={0}><strong>Request access to your personal data.</strong> This enables you to receive a copy of the personal data we hold about you as well as information on how your Personal Data will be processed and the lawful basis for this processing.</li>
                        <li className="justify-text pn-hsmall" tabIndex={0}><strong>Request correction of the personal data</strong> that we hold about you. You can request to have any incomplete or inaccurate data we hold about you corrected.</li>
                        <li className="justify-text pn-hsmall" tabIndex={0}><strong>Request deletion of your personal data.</strong> You can ask us to delete or remove personal data where there is no other reason for us continuing to process it. You also have the right to ask us to delete or remove your personal data where you have successfully exercised your right to object to processing (see below). Please note, however, that the right to erasure is not absolute. We may not always be able to comply fully with your request of erasure due to specific legal obligations which will be notified to you at the time of your request.</li>
                        <li className="justify-text pn-hsmall" tabIndex={0}><strong>Object to processing</strong> of your personal data where we are relying on a legitimate interest (or those of a third party), and you want to object to processing on this ground as you feel it impacts on your fundamental rights and freedoms. In some cases (with the exception of direct marketing), we may demonstrate that we have compelling legitimate grounds to process your information which override your rights and freedoms.</li>
                        <li className="justify-text pn-hsmall" tabIndex={0}><strong>Request restriction of processing of your personal data.</strong> This enables you to ask us to suspend the processing of your personal data in the following scenarios:
                            <br />
                            (a)	if you want us to establish the data’s accuracy;
                            <br />
                            (b)	where our use of the data is unlawful, but you do not want us to erase it;
                            <br />
                            (c)	you have objected to our use of your data, but we need to verify whether we have overriding legitimate grounds to use it.
                            <br />
                        </li>
                        <li className="justify-text pn-hsmall" tabIndex={0}><strong>Withdraw consent</strong> at any time where we are relying on consent to process your personal data. However, this will not affect the lawfulness of any processing conducted before you withdraw your consent. If you withdraw your consent, we may not be able to provide certain products or services to you. We will advise you if this is the case at the time you withdraw your consent.</li>
                    </ul>
                    <p className="justify-text pn-hsmall" tabIndex={0} >If you want to exercise any of these rights, please contact Data Protection Officer, Sopra Steria Limited, Three Cherry Trees Lane, Hemel Hempstead HP2 7AH in writing, or email <a href="mailto:dpo.uk@soprasteria.com">dpo.uk@soprasteria.com.</a>
                        Please note that exemptions set out in the Data Protection Act 2018 may apply, meaning that we do not have to grant your request in full. However, we will always meet your request as far as we are able.
                    </p>
                </>
            },
            {
                title: "No fee usually required",
                content:
                    <p className="text-content justify-text pn-hsmall" tabIndex={0}>
                        You will not have to pay a fee to access your personal information (or to exercise any of the other rights). However, we are allowed under the law to charge a reasonable fee if your request for access is clearly unfounded or excessive. Alternatively, we can refuse to comply with the request in such circumstances.
                    </p>
            },
            {
                title: "What we may need from you",
                content:
                    <p className="text-content justify-text pn-hsmall" tabIndex={0}>
                        We sometimes need to request specific information from you to help us confirm your identity and ensure your right to access the information (or to exercise any of your other rights). This is a security measure to ensure that personal information is not disclosed to any person who has no right to receive it. If you do not provide such information in a timely manner, this may delay or deny your request.
                    </p>
            },
            {
                title: "9. Data Protection Officer",
                content:
                    <p className="justify-text pn-hsmall" tabIndex={0} >
                        We have appointed a data protection officer (DPO) to oversee compliance with this Privacy Notice. If you have any questions about this Privacy Notice or how we handle your personal information, please contact the DPO at Data Protection Officer, The Company Limited, Three Cherry Trees Lane, Hemel Hempstead HP2 7AH or <a href="mailto:dpo.uk@soprasteria.com">dpo.uk@soprasteria.com</a>
                    </p>
            },
            {
                title: "10. Changes to this Privacy Notice",
                content:
                    <>
                        <p className="text-content justify-text pn-hsmall" tabIndex={0} >We reserve the right to update this Privacy Notice at any time. We will notify you from time to time about the processing of your personal information within our communications. Any material changes to the privacy are listed below.
                        </p>
                        <ul>
                            <li className="justify-text pn-hsmall" tabIndex={0}>Initial document created,created March 2024</li>
                        </ul>
                    </>
            },
            {
                title: "11. Definitions",
                content: <>
                    <p className="justify-text pn-hsmall" tabIndex={0} ><strong>Comply with a legal or regulatory obligation</strong> means processing your personal data where it is necessary for compliance with a legal or regulatory obligation to which we are subject.</p>
                    <p className="justify-text pn-hsmall" tabIndex={0} ><strong>In the performance of a contract</strong> is where we process personal information upon instructions contained within a contract (i.e., to provide a contractual service), between ourselves and third parties (typically our clients or our suppliers).</p>
                    <p className="justify-text pn-hsmall" tabIndex={0} ><strong>Legitimate Interest</strong> means the interest of our business in conducting and managing our business to enable us to give you the best service/product and the best and most secure experience. We make sure we consider and balance any potential impact on you (both positive and negative) and your rights before we process your personal data for our legitimate interests. We do not use your personal data for activities where our interests are overridden by the impact on you (unless we have your consent or are otherwise required or permitted to by law). You can obtain further information about how we assess our legitimate interests against any potential impact on you in respect of specific activities by contacting us.</p>
                    <p className="pn-hsmall" tabIndex={0} ><strong>If you have any questions about this privacy notice, please contact Data Protection Officer, Sopra Steria Limited, Three Cherry Trees Lane, Hemel Hempstead HP2 7AH or <a style={{ color: "black" }} href="mailto:dpo.uk@soprasteria.com">dpo.uk@soprasteria.com</a> </strong></p>
                    <p className="justify-text pn-hsmall" tabIndex={0} >Copyright Sopra Steria Financial Services Limited – 21 November 2023</p>
                </>
            }
        ]
    },
    TnC: {
        heading: "SupportPoint.co.uk",
        tnc: "Terms and Conditions",
        para1: <p>The terms and conditions of use set out below apply to <a href="https://www.Supportpoint.co.uk" target="_blank" rel="noopener noreferrer" >www.Supportpoint.co.uk</a> "the website". SupportPoint is a brand of Sopra Steria Financial Services Limited (SSFSL), operated and controlled by SSFSL.</p>,
        para2: "The website is intended for use by residents of the United Kingdom of Great Britain and Northern Ireland only. By using the website, users are deemed to have full knowledge of the following terms and conditions and to accept them.",
        sub1: "Copyright",
        subpara1: "All information accessible on the website is protected by copyright. Users may view the website pages on screen and may print or download extracts for personal use or for use by others within their organisations. ",
        subpara2: "Users may supply a copy of any such extract to any third party provided that the extract is for their own personal use;",
        subpara3: "the extract is not supplied as part of or incorporated in another work, website or publication; the extract is not supplied either directly or indirectly in return for commercial gain; and the third party is made aware that the source of the copy is this website and that these terms and conditions apply equally to them.",
        subpara4: "No part of the website may be reproduced, transmitted to, or stored on any other website or in any other form of electronic medium without express written consent from an authorised agent of SSFSL.",
        sub2: "Continuity and quality of service",
        sub2para1: "SSFSL will use reasonable endeavour to provide users with a prompt and continuing service. SSFSL does not, however, warrant that the service it provides will continue uninterrupted or without delay or that it will remain unchanged. In particular, serves the right to bring the website down as and when necessary for the purposes of maintenance.",
        sub2para2: "Whilst SSFSL will use its reasonable endeavour to ensure that the information contained in the website is correct and reliable, no warranty, either expressly or implied, is given as to the accuracy or completeness of that information.",
        sub2para3: "The website is intended to provide users with general information relating to the SSFSL service, its various products and services and other financial information which may be of relevance or interest to users. The website is not intended to provide exhaustive details in respect of any of the issues or matters referred to within. In particular, information generated via the SSFSL analyser the website is intended to be considered as a high-level indicator only and should be treated by users as non-binding guidance.",
        sub2para4: "Prior to making any decision or taking any action which may potentially affect users' personal finances or business, users are urged to take independent advice from suitably qualified advisors.",
        sub3: "Links to external sites",
        sub3para1: "The website may from time to time contain hypertext links to a number of websites owned, operated and controlled by third parties. SSFSL has no control over or proprietary interest in any of these websites and, as such, makes no warranties with regard to the quality, security, accuracy or any other aspect of such sites, and excludes all liability arising from use of the same.",
        sub4: "Enquiries",
        sub4para1: "Whilst every effort is made to ensure that any enquiries submitted are dealt with promptly, SSFSL cannot guarantee that queries will always be dealt with within the timescale users may expect.",
        sub5: "Disclaimer",
        sub5para1: "SSFSL shall not be liable for any third party claims or losses of any nature, including, but not limited to, loss of profits, indirect or consequential loss or loss due to circumstances beyond its reasonable control.",
        sub5para2: "SSFSL does not accept any liability for any acts or omissions resulting from users' decisions or opinions formed due to the use of the website.",
        sub5para3: "SSFSL may, at any time change, modify, add to or remove part or all these terms and conditions. Users should therefore check these terms and conditions periodically to see if they have changed. Continued use of this website following any change in the terms and conditions will be deemed to constitute the users' acceptance of those changes.",
        sub5para4: "SSFSL makes every effort to ensure that the information and data contained on the website is correct. SSFSL accepts no liability and does not guarantee that the information and data provided is up to date, correct and complete. This also applies for all other websites referred to by means of a hyperlink from time to time. SSFSL is not responsible for the contents of such websites reached by means of such a link and reserves the right to modify or supplement the information or data provided without prior notice.",
        sub6: "General",
        sub6para1: "These Terms and Conditions are governed by and shall be construed in accordance with English law. Disputes in connection with the website shall be subject to the jurisdiction of English Courts.",
        sub7: "Feedback",
        sub7para1: "We hope that users will find the website informative and easy to use. We will be pleased to receive any comments or queries that users have concerning the website",
        sub7para2: <p>Please e-mail us at <a href="mailto:info.uk@soprasteria.com">info.uk@soprasteria.com</a></p>
    },
    celebration: {
        heading: "Well done!",
        text: "You've completed all the support options you selected for this circumstance. Have a look at your Schedule List to check if there's anything more to look at.  We hope you're finding Support Point helpful."
    },
    AboutService: {
        heading: "About this service",
        subheading0: (
            <span>
                This service is provided by{" "}
                <a
                    href="https://www.soprasteria.com/about-us"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "blue" }}
                    className="underline-link" tabIndex={5}
                >
                    Sopra Steria
                </a>{" "}
                in partnership with your Credit Union.
            </span>
        ),
        subheading1: "It has been designed for Credit Union members who may need some extra support, but don’t know what to ask for.",
        subheading2: "By asking some questions about your circumstances, Support Point aims to understand your needs and point you towards a range of different types of support. ",
        subheading3: "Support options are tailored to each individual user:",
        subheading4: "•	This includes support your Credit Union may provide as well as local and national organisations you may find helpful.",
        subheading5: "•	You can choose support options that sound most relevant to your needs.",
        subheading6: "•	You are free to change your mind, select different options and come back into the tool another time if you want to update or change your circumstances.",
        subheading7: "•	If you move to a new postcode area, you can update your postcode to see if this provides new local organisations offering relevant support.",
        subheading8: "•	You can also notify your Credit Union of the kind of support you would like them to provide to you. ",
        subheading9: "You will be able to download your Support Pack which will have a summary of the support options you have selected.",
        subheading10: "All these options are provided on an information-only basis. The tool doesn’t provide advice and it hasn’t verified the quality of support provided by these organisations. However, they are government organisations or charities whose information indicates that they provide relevant support.",
    },
    PostCode: {
        Placeholder: "For example AB10 1",
        ShowMore: "Show more",
        ShowLess: "Show less",
        ButtonText: "Show my support options",
        label: "Sector code",
        heading: "Support from other organisations",
        subheading: "Next, we’ll share support options from national organisations.",
        subheading2: "We may also be able to share options that are local to you. To see these as well, please share the Sector code.",
        Example: "EH7",
        paratext: "These organisations may provide relevant support to you. We'll include their website links in your Support Pack so you can find out more and decide whether to contact them. Please untick any that you don't want in your Pack."
    },
    ReviewComponents: {
        ViewReview: "View Review & Ratings",
        Averageuserrating: "Average user rating",
        RatingsfromOtherUsers: "Ratings from other users",
        Filterby: "Filter by",
        Sortby: "or Sort by"
    },
    actionPlanPara1: "Peace of mind, one step at a time.",
    actionPlanPara2: "Action plan to manage your circumstances based on insight from Citizens Advice.",
    actionPlanPara3: "Action plan based on insights from Citizens Advice",
    gotoHome: "If you go to home page, your current journey will be discarded.\nDo you want to continue?",
    gotoHomefromFeedback: "If you go to home page, your feedback will not be saved.\nDo you want to continue?",
    optionText: "You can only select one option at a time",
    errorMessage: "*Please select an option",
    warning: "Are you sure you want to continue? You have not sent your member their support options.",
    summary: {
        heading: "Check your answers",
        change: "Edit"
    },
    exit: {
        redirectionURL: "https://www.google.com"
    },
    characterLength: "250",
    InteractiveDashboardComponent: {
        headingNational: "Support from national organisations",
        headingLocal: "Support from local organisations",
        subheadingNational: "These support options are provided by national charities and public services. They are available online and some provide support by phone too.",
        subheadingLocal: "These support options are provided by local charities and public services. They provide information online and most offer in-person support near you.",

        headingSS: "Support we will give you​",
        subheadingSS: "This is the support you asked us to provide. All options are free and our team will know about them when you get in touch.​",
        tabNames: ["National Support", "Local Support", "Credit Union support"],
        NationalOptions: [
            { value: 'R', label: 'Ratings' },
            { value: 'P', label: 'Progress status' },
        ],
        LocalOptions: [
            { value: 'R', label: 'Ratings' },
            { value: 'P', label: 'Progress status' },
            { value: 'D', label: 'Distance' },
        ],
        reviewtext: "Review due ",
        Pdftext: "Download PDF",
        Edittext: "Edit my inputs",
        sortText: "Sort by",
        distanceInfo: "Distance displayed is an approximate distance from the postcode you input",
        edit: "Edit",
        schedule: "Schedule",
        EditList: {
            Heading: "Edit list - Help with managing your mobility",
            RemoveHead: "Remove Support Options",
            RemoveSub: "Click on the support options that you want to remove from your list",
            AddHead: "Add Support Options",
            AddSub: "Click on the support options that you want to add to your list",
            submit: "Update list"
        },
        ScheduleList: {
            Heading: "Schedule list - Help with managing your mobility",
            startNow: "Start now",
            startNowSub: "Click on the support options that you want to start later",
            startLater: "Start later",
            startLaterSub: "Click on the support options that you want to reset",
            submit: "Update list"
        },
        MarkComplete: {
            ratingheading: "Review & Ratings",
            averagerating: "Average user rating",
            congratsmessage: "Well done!! We hope you found this support helpful. Please rate it below.",
            whatgood: "What is good about this support option? What could be improved?",
            entercomments: "Enter your comments here",
            sharebutton: "Share your ratings",
            completebutton: "Mark as Complete",
            myrating: "My rating"
        }
    },
    help: {
        welcome: {
            welcome_Enter: "Support Point is a digital tool. It’s free and easy to use. On the next page we explain how it works. This tool is not for emergency support. In an emergency, phone 999. Cilck ‘enter’ to continue.",
        },
        help: {
            help_feedback: "At the bottom of the screen are the Privacy Notice and a feedback form. You can click on these on any page. Please do share your views on what’s good and what could be better.",
            help_exit: "The Exit button closes Support Point and opens a Google search page. Use this if you want to hide Support Point quickly. The Exit button is on every page. To completey hide your visit to Support Point you’ll need to delete your browser history too.",
        },
        consent: {
            consent_Privacy: "The Privacy Notice describes how we will collect and use your data. It’s part of how we keep your information safe. The Terms & Conditions describe the service we are providing. Click on the links to open the documents in new screens.",
            consent_CheckBox: "Click here when you’re ready to use Support Point, then click ‘Continue’.",
            consent_Continue: "‘Continue’ takes you to the next page. If the wording is grey, there’s an action to complete before you can click on. The ‘Back’ button at the left will take you back to the previous page. Your inputs are saved automatically.",
        },
        needhelp:{
            needhelp_Number:"If you need help from us to complete Support Point, call us on this number.",
            needhelp_category: "Select one of the 3 categories. Each one has a list of circumstances for you to consider. You can select one or more circumstances in each category, or ‘not applicable for me’. There will be a blue tick on the category once you have input something. Once you have a tick for all 3 categories, click ‘continue.",
            needhelp_Progress:"Track your progress through these 3 steps, to create your tailored Support Plan. You can take a break any time. Your inputs are saved automatically. Simply come back into Support Point the same way as you did this time.",
            needhelp_Circumstances:"These are high-level circumstances – they’re very broad groups of experiences! Click on one to see a list of more specific circumstances.Next, select any that are relevant to you, or type in the ‘Something else’ box. If something listed is relevant to you, but you prefer to keep it private, click ‘I’d prefer not to say’. Click ‘save’ to come back to this page again.",
            needhelp_Continue:"Click ‘Continue’ when you have selected one or more option on this page. That includes ‘other’ or ‘not applicable for me’. You’ll return to the menu page for ‘My circumstances’, with a tick for this category to show it’s complete.",        
        },
        impacts: {
            impacts_Options: "Each of these options is a suggestion of how your circumstance may impact you. Select any that are relevant. You can suggest another impact by typing in the ‘Other’ box. Or select ‘I prefer not to say’ if that’s true.",
        },
        endofstep2:{
            endofstep1_Tracker:"The tracker bar shows you have completed 2 of the 3 steps. ",
            endofstep1_Continue:"Click ‘Continue’ when you are ready. If you need a break, that’s OK. Your inputs have been saved. Simply come back into Support Point the same way as you did this time.",
        },
        checkyouranswers: {
            help_checkyouranswers: "These are the impacts you selected in Step 2. We’ll use these to suggest relevant support options. Click ‘Edit’ if you’d like to change any impacts. If you do, when you click ‘Continue’ you will return to this page. Click ‘Step 3’ when you’re ready to choose your support.",
        },
        support: {
            support_Listing: "These are types of support we can provide. We’re listing options that may be helpful to you, based on the impacts you selected in Step 2.",
            support_Suggestion: "If you don’t see anything helpful, you can suggest other ways we can help you at the bottom. We’ll be in touch to confirm if that’s possible.",
            support_Review: "At the bottom you can let us know how soon you’d like to review this. We know circumstances change, so the support you need might change too.",
        },
        support_postcode: {
            postcode_Local: "Input your postcode to see options local to you. If you don’t want to, simply click on ‘Continue without local options’.",
            postcode_Local_OptionList: "Each support option is automatically selected. Click on any you don’t want to include in your tailored Support Plan. If you change your mind, click it again.",
            postcode_Show_More: "Click on ‘Show more’ to expand the list of support options for this circumstance, if there are 3 showing already. You can click ‘Show less’ to hide them away again.",
        },
        demographic_Consent: {
            demograhic_Consent_SupportPack: "Click here if you don’t want to provide feedback now. You can give us feedback later, if you prefer.",
            demographic_Feedback: "Click here if you would like to share feedback now on your experience of using Support Point. Or you can call us if you prefer."
        }
    },
    national_support: {
        nationalsupport_Tittle: "There are three types of support available – national support (online), local support (in person) and support from us. Click each tab to explore options and make your own plan.",
        national_support_Download: "Download a PDF of all your support options. You can email this to someone or print it off. The PDF includes phone numbers for support organisations, where these are available online.",
        Edit_my_inputs: "Your circumstances may change in future. To update these, or the impact they have on you, click ‘Edit my inputs’. We can update you records and suggest new support options.",
        national_support_sort: "Sort the support options for each circumstance by ‘ratings’ or ‘progress’. These are shown to the right of each support option.",
        national_support_review: "This is the date you let us know you’d like to review your support options. Click ‘Edit my inputs’ at the right to review and update.",
        national_support_Showmore: "Click ‘Show more’ to see more than 4 support options on your action list.",
        national_support_start: "This icon help you your progress in using support options. Click ‘start’ when you first look at a support option. This updates the icon to ‘in progress’. When you’re done, click again to display ‘complete’, and add your rating and feedback.",
        national_support_rating: "This shows the average rating given to this support option by Support Point users. It could help you decide which support options to look at first.",
        national_support_organisation: "This is a national support option. The bold words are the name of the organisation providing free support. We’ve summarised this to help you decide what might be helpful to you. Click on the name to open the website in a new window.",
        national_support_Schedule: "Use ‘Schedule’ to prioritise your support options. Choose ‘start later’ for options you’d like to keep on your action list, but not start yet. This updates the progress icon by each support option. Sort by ‘Progress Status’ at the top left to move the ‘start now’ options to the top of your list.",
        national_support_Editlist: "Use ‘Edit’ to hide support options without deleting them. You can choose some to ‘remove’ from the list visible on this page. Add them back on again later when you’re ready.",
    },
    local_support: {
        localsupport_Tittle: "There are three types of support available – national support (online), local support (in person) and support from us. Click each tab to explore options and make your own plan.",
        localsupport_support_Download: "Download a PDF of all your support options. You can email this to someone or print it off. The PDF includes phone numbers for support organisations, where these are available online.",
        Edit_my_inputs: "Your circumstances may change in future. To update these, or the impact they have on you, click ‘Edit my inputs’. We can update you records and suggest new support options.",
        localsupport_support_sort: "Sort the support options for each circumstance by ‘ratings’, ‘progress’ or ‘distance. These are shown to the right of each support option.",
        localsupport_support_review: "This is the date you let us know you’d like to review your support options. Click ‘Edit my inputs’ at the right to review and update.",
        localsupport_support_Showmore: "Click ‘Show more’ to see more than 4 support options on your action list.",
        localsupport_support_start: "This icon help you your progress in using support options. Click ‘start’ when you first look at a support option. This updates the icon to ‘in progress’. When you’re done, click again to display ‘complete’, and add your rating and feedback.",
        localsupport_support_rating: "This shows the average rating given to this support option by Support Point users. It could help you decide which support options to look at first.",
        localsupport_support_organisation: "This is a local support option. The bold words are the name of the organisation providing free support. We’ve summarised this to help you decide what might be helpful to you. Click on the name to open the website in a new window.",
        localsupport_support_Schedule: "Use ‘Schedule’ to prioritise your support options. Choose ‘start later’ for options you’d like to keep on your action list, but not start yet. This updates the progress icon by each support option. Sort by ‘Progress Status’ at the top left to move the ‘start now’ options to the top of your list.",
        localsupport_support_Editlist: "Use ‘Edit’ to hide support options without deleting them. You can choose some to ‘remove’ from the list visible on this page. Add them back on again later when you’re ready.",
    },
    organisations_support: {
        organisations_Download: "Download a PDF of all your support options. You can email this to someone or print it off. The PDF includes phone numbers for support organisations, where these are available online.",
        organisations_Edit: "Your circumstances may change in future. To update these, or the impact they have on you, click ‘Edit my inputs’. We can update you records and suggest new support options.",
        organisations_review: "This is the date you let us know you’d like to review your support options. Click ‘Edit my inputs’ at the right to review and update. "
    }
};

export default literals;