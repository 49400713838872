import literals from "../components/Literals/Literals";
import { BROWSER_TYPE } from "./constant";

export function saveResultIntoStorage(key, result) {
    localStorage.setItem(key, JSON.stringify(result))
}

export function getStorageItem(key) {
    return JSON.parse(localStorage.getItem(key))
}

export const redirectGoogleOrClose = (tab,label) => {
    if (tab) {
        const closeParent = tab.close();
        //const closeParent = false;
        if (closeParent) {
            console.log('CLOSE DONE');
        } else {
            console.log('CLOSE NOT DONE Redirect to Google: ',label);
            tab.location.replace(literals.exit.redirectionURL);
        }        
    }
}

export const handleNetworkError = (requestCall) => {
    requestCall.catch(function (error) {
        console.log('handleNetworkError');
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
        }
        console.log(error.config);
    });
    return requestCall;
}

export const checkBrowser = () => {
          
    // Get the user-agent string
    let userAgentString = 
        navigator.userAgent;
  
    // Detect Chrome
    let chromeAgent = 
        userAgentString.indexOf("Chrome") > -1;
  
    // Detect Internet Explorer
    let IExplorerAgent = 
        userAgentString.indexOf("MSIE") > -1 || 
        userAgentString.indexOf("rv:") > -1;
  
    // Detect Firefox
    let firefoxAgent = 
        userAgentString.indexOf("Firefox") > -1;
  
    // Detect Safari
    let safariAgent = 
        userAgentString.indexOf("Safari") > -1;
          
    // Discard Safari since it also matches Chrome
    if ((chromeAgent) && (safariAgent)) 
        safariAgent = false;
  
    // Detect Opera
    let operaAgent = 
        userAgentString.indexOf("OP") > -1;
          
    // Discard Chrome since it also matches Opera     
    if ((chromeAgent) && (operaAgent)) 
        chromeAgent = false;
    
    if(safariAgent) {
        return BROWSER_TYPE.SAFARIAGENT
    } else if(chromeAgent) {
        return BROWSER_TYPE.CHROMEAGENT
    } else if(IExplorerAgent) {
        return BROWSER_TYPE.IEXPLORERAGENT
    } else if(operaAgent) {
        return BROWSER_TYPE.OPERAAGENT
    } else if(firefoxAgent) {
        return BROWSER_TYPE.FIREFOXAGENT
    }
}

export const sanitizeInput = (input) => {
    const maxLength = 1000;
    const urlPattern = /(?:https?:\/\/)?(?:www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\/[^\s]*)?/g;
    const emailPattern = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/g;
    const sqlInjectionPattern = /\b(SELECT(?!\s+\*|\s+FROM)|INSERT(?!\s+INTO)|UPDATE(?!\s+SET)|DELETE|DROP|ALTER|CREATE|EXEC|UNION|WHERE|OR|AND)\b/g; 
    const sqlQueryPattern = /\b(SELECT\s+\*?\s+FROM|INSERT\s+INTO|UPDATE\s+\w+\s+SET|DELETE\s+FROM|DROP\s+\w+|ALTER\s+\w+|CREATE\s+\w+|EXEC\s+\w+|UNION\s+.*?|WHERE\s+.*?|OR\s+.*?|AND\s+.*?)\b/gi; 
    const xssPattern = /<script.*?>.*?<\/script>/gi; 

    let sanitizedInput = input
        .replace(urlPattern, '')          // Remove URLs
        .replace(emailPattern, '')        // Remove emails
        .replace(sqlQueryPattern, '')     // Remove full SQL queries
        .replace(sqlInjectionPattern, '')  // Remove risky standalone SQL commands
        .replace(xssPattern, '');         // Remove XSS patterns

    sanitizedInput = sanitizedInput.replace(/[^a-zA-Z0-9 .-]/g, '');

    if (sanitizedInput.length > maxLength) {
        return sanitizedInput.slice(0, maxLength); // Truncate to maxLength
    }

    return sanitizedInput;
};