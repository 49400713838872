import React, { useContext, useEffect } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { ScrollPanel } from "primereact/scrollpanel";
import ExitButton from "../ExitButton/ExitButton";
import { ExitContext } from "../../contexts/exit.context";
import "../global.scss";
import "./TermsAndConditions.scss";
import literals from "../Literals/Literals";

function TermsAndConditions(props) {
  const themeselected = props.theme;
  const { setTncExit, isChildNewTab } = useContext(ExitContext);

  useEffect(() => {
    isChildNewTab.current = true;
    setTncExit(window);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="PN-parent-container-2">
      {/*  Header */}
      <div className="top-container-2">
        <Header tabIndex={0} className="header" homepage={false} />
        {/* <ProgressBarComponent pageName="feedback" /> */}
   <div className="buttonContainerTop"> 
   <div className="fixExitButton">
      <ExitButton tabIndex={0} />
    </div>
   </div>
        <div className="headContPC">
          <hr className="divider dividerFix privacyFix" />
          <h2 tabIndex={0} className="heading heading-noScroll fixHeadingTnC">
            {" "}
            {literals.TnC.heading}
          </h2>
          <h2 tabIndex={0} className="heading heading-noScroll fixHeadingTnC">
            {literals.TnC.tnc}
          </h2>
          {/* <h2 tabIndex={0} className="heading heading-noScroll">{literals.TnC.tnc}</h2> */}
          <hr className="divider dividerFix headingBottom" />
        </div>
      </div>

      <div className="bottom-container-2">
        <ScrollPanel
          pt={{ root: { style: { width: "100%", height: "100%" } } }}
        >
          <div className="internal-container alignCenter">
            <div className="PN-internal-child-1 i-child-width">
              <p tabIndex={0} className="text-content justify-text pn-hsmall"> {literals.TnC.para1} </p>
              <p tabIndex={0} className="text-content justify-text pn-hsmall"> {literals.TnC.para2} </p>
              <h3 tabIndex={0} className="heading subheadingPrivacy">{literals.TnC.sub1}</h3>
              <p tabIndex={0} className="text-content justify-text pn-hsmall"> {literals.TnC.subpara1} </p>
              <p tabIndex={0} className="text-content justify-text pn-hsmall"> {literals.TnC.subpara2} </p>
              <p tabIndex={0} className="text-content justify-text pn-hsmall"> {literals.TnC.subpara3} </p>
              <p tabIndex={0} className="text-content justify-text pn-hsmall"> {literals.TnC.subpara4} </p>
              <h3 tabIndex={0} className="heading subheadingPrivacy">{literals.TnC.sub2}</h3>
              <p tabIndex={0} className="text-content justify-text pn-hsmall"> {literals.TnC.sub2para1}</p>
              <p tabIndex={0} className="text-content justify-text pn-hsmall"> {literals.TnC.sub2para2} </p>
              <p tabIndex={0} className="text-content justify-text pn-hsmall"> {literals.TnC.sub2para3} </p>
              <p tabIndex={0} className="text-content justify-text pn-hsmall"> {literals.TnC.sub2para4} </p>
              <h3 tabIndex={0} className="heading subheadingPrivacy">{literals.TnC.sub3}</h3>
              <p tabIndex={0} className="text-content justify-text pn-hsmall"> {literals.TnC.sub3para1} </p>
              <h3 tabIndex={0} className="heading subheadingPrivacy">{literals.TnC.sub4}</h3>
              <p tabIndex={0} className="text-content justify-text pn-hsmall"> {literals.TnC.sub4para1} </p>
              <h3 tabIndex={0} className="heading subheadingPrivacy">{literals.TnC.sub5}</h3>
              <p tabIndex={0} className="text-content justify-text pn-hsmall"> {literals.TnC.sub5para1} </p>
              <p tabIndex={0} className="text-content justify-text pn-hsmall"> {literals.TnC.sub5para2} </p>
              <p tabIndex={0} className="text-content justify-text pn-hsmall"> {literals.TnC.sub5para3} </p>
              <p tabIndex={0} className="text-content justify-text pn-hsmall"> {literals.TnC.sub5para4} </p>
              <h3 tabIndex={0} className="heading subheadingPrivacy">{literals.TnC.sub6}</h3>
              <p tabIndex={0} className="text-content justify-text pn-hsmall"> {literals.TnC.sub6para1} </p>
              <h3 tabIndex={0} className="heading subheadingPrivacy">{literals.TnC.sub7}</h3>
              <p tabIndex={0} className="text-content justify-text pn-hsmall"> {literals.TnC.sub7para1} </p>
              <p tabIndex={0} className="text-content justify-text pn-hsmall"> {literals.TnC.sub7para2} </p>
            </div>
          </div>
        </ScrollPanel>
        <div className="internal-child-3 fixFooter">
                <Footer tabIndex={0} theme={themeselected} homepage={true} />
              </div>
      </div>
    </div>
  );
}

export default TermsAndConditions;