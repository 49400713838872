import { configureStore } from '@reduxjs/toolkit';
import userReducer from '../redux/reducers/userReducer';
import {privacyUrlReducer, tncUrlReducer} from './reducers/privacyUrlReducer';
import safeToggleReducer from './reducers/safeToggleReducer';
import clientContactNumberReducer from './reducers/clientContactNumberReducer';
import reviewDateReducer from './reducers/reviewDateReducer';
import realTimeUserJourneyReducer from './reducers/realTimeUserJourneyReducer';

const store = configureStore({
  reducer: {
    dataNotifService: realTimeUserJourneyReducer, 
    user: userReducer,
    privacyUrl:privacyUrlReducer,
    tncUrl:tncUrlReducer,
    safeToggle:safeToggleReducer,
    clientContactNumber:clientContactNumberReducer,
    reviewDate:reviewDateReducer
  },
});

export default store;