import React, { useState } from "react";
//import { useNavigate } from "react-router";
import BackButton from "../../BackButton/BackButton";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import PageTimeTracking from "../../PageTracking/PageTracker";
import { ProgressBar } from '../../ProgressBar/ProgressBar';
import "../../EndofStepOneComponents/EndofStepOneComponents.scss";
import literals from "../../Literals/Literals";
//import { useResumeJourney } from "../../../customHooks/useResumeJourney";
import ButtonComponent from "../../ButtonComponent/ButtonComponent";
import { Save_Column } from "../../../services/saveColumnService";
import './OptionComponent.scss';
import { startJourney } from "../../../services/journeyService";
import { useNavigate } from "react-router";
import { saveCategoryService } from "../../../services/saveCategoryService";
import { sanitizeInput } from "../../../utils/helper.methods";

function OptionComponent(props) {
  const [showTextBox, setShowTextBox] = useState(false);
  const [IsDisabled, setIsDisabled] = useState(false);
  const [textValue, setTextValue] = useState("");
  //   const getItemFromHelper = useResumeJourney();
  //   const prevPage = getItemFromHelper.prevPage;
  //   const nextPage = getItemFromHelper.nextPage;
  const navigation = useNavigate();
  const buttonText = "Back";
  const themeselected = props.theme;

  const santizeData = (data) => {
    setTextValue(sanitizeInput(data));
  }

  const handleBack = () => {
    console.log("tdfedjfejafd");
    navigation("/needhelp")
  }

  const handleOptionTwoClick = () => {
    setShowTextBox(!showTextBox);
    setIsDisabled(!IsDisabled)
  }

  const handleSubmit = async (val) => {
    try {
      const data = {
        value: val,
        column: "user_journey_how_can_we_help",
      };
      const response = await Save_Column(data);
      console.log("Save postcode ", response.data);
    } catch (error) {
      console.error(error);
    }
    navigation("/exitpage", { replace: true })
  };

  const handleCancel = () => {
    setShowTextBox(!showTextBox);
    setIsDisabled(!IsDisabled)
  }

  const saveCategoryFunction = () => {
    const getJourneyOptions = localStorage.getItem("journeyOptions");
    const updatedOptions = JSON.parse(getJourneyOptions).map((opt) => {
      if (opt.selected === true) {
        return { ...opt, selected: !opt.selected };
      }
      return opt;

    });
    const responseCreation = updatedOptions.map(item => {
      return {
        id: item.id,
        selected: item.selected
      }
    });
    console.log("Response creation", responseCreation);
    const req = {
      responses: responseCreation
    }
    console.log("Request", req);
    saveCategoryService(req);
    localStorage.setItem("journeyOptions", JSON.stringify(updatedOptions));
  }

  const handleOptionOneClick = () => {
    console.log("Option 1 clicked");
    (async () => {
      const request = {
        "userId": "",
        "clientId": "",
      }
      const response = await startJourney(request);
      localStorage.setItem("journeyId", response.data.userJourneyId);
      const data = {
        value: true,
        column: "user_journey_is_consented",
      };
      const response_save = await Save_Column(data);
      console.log("Save Consent ", response_save.data);
      saveCategoryFunction();
      navigation("/needhelp")
    }
    )();
  }

  return (
    <div>
      <Header tabIndex={0} homepage={false} />
      <ProgressBar tabIndex={0} pageName="needhelp" progressValueC={100} progressValueI={0} progressValueS={0} />
      <div className='headContainer'>
        <div className='firstImage myCir' tabIndex={0} ><span className="visual">My circumstances Image</span></div>
        <div>
          <h3 className='headingCircumstance' tabIndex={0} >My circumstances</h3>
          <p className='descCircumstance' tabIndex={0} >{literals.Circumstances.description1}</p>
        </div>
      </div>


      <div className="greyContainer TYgrey-area">
        <div className='card leftThankImage col-sm-3' tabIndex={0}> <span className="visual">A customer service individual with headphones</span> </div>
        <div className="m-5">
          <div className="headOptionComponent">
            <p className='options-msg' tabIndex={0} >{literals.Options.heading}</p>
          </div>
          {showTextBox ?
            <div className="mt-4">

              <div tabIndex={0} className="optionTwoHeadingImg"><h2 className="optionTwoHeading">
                {literals.Options.option2}
              </h2></div>

              <textarea className="textComponent " placeholder={literals.Options.placeholder} value={textValue} onChange={(e) => santizeData(e.target.value)}></textarea>
              <div className="csButtonComponent">
                <ButtonComponent
                  classname="cancelButton"
                  tabIndex={0}
                  name="Cancel"
                  disabled={false}
                  onClick={() => {
                    handleCancel()
                  }}
                />
                <ButtonComponent
                  classname="submitButton ms-3"
                  tabIndex={0}
                  name="Submit"
                  disabled={false}
                  onClick={() => {
                    handleSubmit(textValue)
                  }}
                />
              </div>

            </div>
            : <div className="optionComponent mt-5">
              <ButtonComponent
                classname={IsDisabled ? "optionButtonDisabled" : "optionOneButton"}
                tabIndex={0}
                name={literals.Options.option1}
                disabled={IsDisabled}
                onClick={() => {
                  handleOptionOneClick()
                }}
              />
              <span tabIndex={0} className="orText ms-4 me-4"><p>or</p></span>
              <ButtonComponent
                classname="optionTwoButton"
                tabIndex={0}
                name={literals.Options.option2}
                disabled={false}
                onClick={handleOptionTwoClick}
              />
            </div>
          }
        </div>
      </div>
      <div className="bottomBtn">
        <BackButton label={buttonText} onClick={handleBack} className="float-start" />
      </div>
      <div className='third footer_container'>
        <Footer screenName="EndOfStep-1" theme={themeselected} homepage={false} />
      </div>
      <PageTimeTracking pageName="EndOfStep-1" />
    </div>
  );
}

export default OptionComponent;
