import React from 'react';
import ReactDOM from 'react-dom/client';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './index.css';
import App from './App';
import { ExitProvider } from './contexts/exit.context';
import { GlobalProvider } from './contexts/global.app.context';
import { BrowserRouter } from 'react-router-dom';
import store from './redux/store';
import { Provider } from 'react-redux';
// import '../src/i18n/config';
if(process.env.REACT_APP_PROD === "true"){
console.log = () => {}
console.debug= () => {}
}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <GlobalProvider>
        <ExitProvider>
            <BrowserRouter>
            <Provider store={store}>
                <App />
            </Provider>
            </BrowserRouter>
        </ExitProvider>
    </GlobalProvider>
);
