import React from "react";
import "./ContinueButton.scss";

const ContinueButton = (props) => {
  const { classname, label, onClick, disabled,tabIndex } = props;
 
  return (
    <div>
      {disabled === true ? (<button tabIndex={tabIndex} className={classname} disabled={disabled}>
        <span className="button-label-continue">{label}</span>
      </button>) :
      (<button tabIndex={tabIndex} className={classname} onClick={onClick} disabled={disabled}>
        <span className="button-label-continue">{label}</span>
      </button>)}
    </div>
  );
};

export default ContinueButton;
