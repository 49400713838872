export const DEFAULT_PARAMS = {
    'Ocp-Apim-Subscription-Key': process.env.REACT_APP_APIM_KEY,
}

export const DEFAULT_PARAMS_ONE = {
    'screenName': '',
    'topicId': '',
    'postCode': '',
}

export const APP_WORKFLOW = "home,welcome,consent,needhelp,endofstep-1,impacts,endofstep-2,checkyouranswers,ccusupport,postcode,thirdpartysupport,demographic-consent,demographic,outputscreen,feedback-radio,feedback-text,thankyou"

export const API_KEY_HEADER = {
    'Ocp-Apim-Subscription-Key': process.env.REACT_APP_APIM_KEY,
}

export const SOURCE_ORIGIN={
    'url':''
}

export const SAS_TOKEN = {
    'token':''
}

export const USER_ID_GENERAL = {
    userId: '',
}

export const getDefaultHeadersForAPIOne = () => (
    {
        'Access-Control-Allow-Credentials': true,
        'Ocp-Apim-Subscription-Key': API_KEY_HEADER["Ocp-Apim-Subscription-Key"],
        'postcode': '',
        'user_id': USER_ID_GENERAL["userId"],
        'column': '',
    }
)

export const getDefaultHeadersForAPITwo = () => (
    {
        'Access-Control-Allow-Credentials': true,
        'user_id': USER_ID_GENERAL["userId"],
        'Ocp-Apim-Subscription-Key': API_KEY_HEADER["Ocp-Apim-Subscription-Key"],

    }
)

export const REQUEST_HEADER_TOKEN = {
    'Ocp-Apim-Subscription-Key': API_KEY_HEADER["Ocp-Apim-Subscription-Key"],
    'source_origin':SOURCE_ORIGIN['url'],
    'sas-token':SAS_TOKEN['token']
};
