import React, { useState, useEffect } from "react";
import literals from "../Literals/Literals";
import { getUserFeedback } from "../../services/getSavedData";
import { Save_Feedback } from "../../services/saveColumnService";
import "./FeedBackNew.scss";
import '../../themes/SopraTheme/sopra.scss';
import CustomRadioButton from "../CustomRadioButton/CustomRadioButton";
import { sanitizeInput } from "../../utils/helper.methods";
import { useSelector } from "react-redux";

export const FeedBackNew = ({ isOpen, isOpenfromID, onClose, screenName }) => {
    const [feedback, setFeedback] = useState("");
    const [feedbackComment, setFeedbackComment] = useState(undefined);
    const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
    const [loading, setLoading] = useState(true);

    const sanitizeData = (data) => {
        setFeedbackComment(sanitizeInput(data));
    }

    useEffect(() => {
        if (isOpen || isOpenfromID) {
            fetchData();
        }
        //eslint-disable-next-line
    }, [isOpen]);

    const fetchData = async () => {
        await getdata();
        await getCommentdata();
        setLoading(false);
    };

    const getdata = async () => {
        try {
            const responseRJ = await getUserFeedback();
            setFeedback(responseRJ?.data.user_feedback_experience);
            if (responseRJ.data.user_feedback_experience !== null) {
                setFeedbackSubmitted(true);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const getCommentdata = async () => {
        try {
            const responseRJ = await getUserFeedback();
            setFeedbackComment(responseRJ.data.user_feedback_comments);
            if (responseRJ.data.user_feedback_comments !== null) {
                setFeedbackSubmitted(true);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleRadioButtonChange = (value) => {
        setFeedback(value);
    };

    const GoToApplication = () => {
        onClose();
    };

    const SaveFeedback = async () => {
        await makePostRequest();
        setFeedbackSubmitted(true);
        console.log("Feedback submitted", feedbackSubmitted)
    };

    const makePostRequest = async () => {
        try {
            const data = {
                user_feedback_experience: feedback,
                user_feedback_comments: feedbackComment,
                user_feedback_screen_name: screenName,
            };
            const response = await Save_Feedback(data);
            console.log(response.data);
            setFeedbackSubmitted(true);
            console.log("Feedback submitted", feedbackSubmitted)
        } catch (error) {
            console.error(error);
        }
    };

    if (!isOpen || loading) {
        return null;
    }

    return (
        <>
            {feedbackSubmitted && isOpenfromID ? null : (
                <div className="modal-overlay">
                    <div className="modalbackground">
                        <div className="header">
                            <span className="headingModal title hColor ms-5 mt-2">
                                <div className="mainheading" tabIndex={0}>{literals.FeedBack.mainHeading}</div>
                            </span>
                            <span>
                                <button className="close-button" onClick={onClose} tabIndex={0}>
                                    <div role="img" aria-label="close" className="closeModalImg"></div>
                                </button>
                            </span>
                        </div>

                        <div className="feedbackContent">
                            <div className="Sheading">
                                <div className="heaDing" tabIndex={0}>{literals.FeedBack.heading}</div>
                                <p className="mainSubheading" tabIndex={0}>
                                    {literals.FeedBack.subheading}
                                </p>
                            </div>
                            <div className="options-container">
                                {literals.FeedBack.options.map((option) => (
                                    <CustomRadioButton
                                        tabIndex={0}
                                        key={option.id}
                                        value={option.value}
                                        isChecked={feedback === option.value}
                                        onChange={() => handleRadioButtonChange(option.value)}
                                        disabled={feedbackSubmitted}
                                        color="#176E96"
                                        ariaChecked={feedback === option.value}
                                    />
                                ))}
                            </div>

                            <div className="heaDing mt-4" tabIndex={0}>{literals.FeedBack.subheadingComment}</div>
                            <div className="comment-container mt-2">
                                <textarea
                                    tabIndex={0}
                                    id="inputField"
                                    rows="4"
                                    value={feedbackComment || ''}
                                    onChange={(e) => sanitizeData(e.target.value)}
                                    placeholder={literals.FeedBack.commentPlaceHolder}
                                    className="optionsFont setTextBox"
                                    readOnly={feedbackSubmitted}
                                    aria-label="Enter your feedback here"
                                />
                            </div>
                            {feedbackSubmitted && (
                                <div className="">
                                    <div className="thankYoutext" tabIndex={0}>{literals.FeedBack.thankYou}</div>
                                    <p className="mainSubheading" tabIndex={0}>{literals.FeedBack.thankyouText}</p>
                                </div>

                            )} </div>
                        <div className="modal-footer">
                            <button tabIndex={0} aria-label={!feedbackSubmitted ? "Share feedback" : "Proceed to app"} className="startButton" onClick={feedbackSubmitted ? GoToApplication : SaveFeedback}>
                                {feedbackSubmitted ? literals.FeedBack.buttonTextProceed : literals.FeedBack.buttonTextShare}
                                <span className="ImgSet proceed imgD" />
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
