import React from 'react';
import './Footer.scss';
import literals from '../Literals/Literals';

function FooterOne(){
  return (
      <div className='footer_layer'>
        <div className='sopra_logo' tabIndex={0}><span className="visual">Sopra Steria Logo</span></div>
          <div className='footer_content'>
             <p tabIndex={0}>{literals.home.footerText}</p>
          </div>
      </div>
  );
};

export default FooterOne;
