import React,{useEffect, useState,useRef} from 'react';
import {loadJourney} from "../../../services/journeyService";
import './FirstComponent.scss';
import literals from '../../Literals/Literals';

const FirstComponent = (props) => {
    const [optionList,setOptionList] = useState([]);
    const [optionSelected,setOptionSelected] = useState([]);
    const data = useRef(null);
    const [isLoadPage,setIsLoadPage] = useState(false);
    const [loading,setLoading] = useState(true);
    const showHelp = props.showHelp;
    const loadJourneyApi = () => {
        return loadJourney();
      };

      const CheckAllTrue = (circumstances) => {
        console.log(JSON.parse(circumstances));
         const allTrue = JSON.parse(circumstances).every(item => item.selected === true);
         console.log("All true",allTrue)
         return allTrue;
      }

      useEffect(() => {
        console.log(props.showHelp)
      },[props.showHelp])

      useEffect(() => {
        setTimeout(() => {
        (async()=> {
            const response = await loadJourneyApi();
            setTimeout(() => {
              setLoading(false);
            },3000)
           
            console.log(response.data);
            setOptionList(response.data);   
            console.log("Load journey options initially", optionList);
            localStorage.setItem("journeyOptions", JSON.stringify(response.data));
            const journeyOptions = localStorage.getItem("journeyOptions");
          const checktrue = CheckAllTrue(journeyOptions)
          if(checktrue){
          props.setContinue(false)
          }
          else{
            props.setContinue(true)
          }
        })();
      },2000)
            // eslint-disable-next-line react-hooks/exhaustive-deps
      },[])
      useEffect(() => {
        console.log(optionSelected,isLoadPage);
      },[optionSelected,isLoadPage])

      const handleTileKey = (item,index) => (e) => {
        if(e.key === 'Enter'){
          callSecondComponent(item,index);
        }
      }
      
      const callSecondComponent = (item,index) => {
        setOptionSelected(item);
        setIsLoadPage(false);
        data.current = {
          optionSelected:item,
          index:index,
          loadPage:isLoadPage
        }
        console.log("Sending data........",data.current)
        props.sendData(data.current);
      }

      const handleCloseIcon = () => {
        props.setHelp(false);
      }
  return (
    <div>
{showHelp && 
<div className='help'>
<div className='closeIcon' onClick={handleCloseIcon}> Close</div> 

<div className='progressbarHelp'>    
<div className='helpHeader'> Lorem ipsum dolor sit amet,</div> consectetur adipiscing elit. 
Duis varius, nisi vel dignissim pellentesque, ante sem pretium nibh
 <div className='upArrow_S'>   </div>  </div>

 
<div className='radioHelpcontent'>    
<div className='helpHeader'> Lorem ipsum dolor sit amet,</div> consectetur adipiscing elit. 
Duis varius, nisi vel dignissim pellentesque, ante sem pretium nibh, 

 <div className='upArrow_S'>   </div>  </div>
 
<div className='bannerPhone'>    
<div className='helpHeader'> Lorem ipsum</div> consectetur adipiscing elit. 
Duis varius, nisi vel dignissim 

 <div className='leftArrow'>   </div>  </div>
 

</div> 
}

    {loading ? (
      <div className="loader-container">
        <div className="spinner"></div>
      </div>
    ) : (<div>
          <div className='headContainer'>
            <div className='firstImage myCir myCir' tabIndex={0}><span className="visual">My circumstances logo</span></div>
            <div>
                <h1 className='headingCircumstance' tabIndex={0}>My circumstances</h1>
                <p className='descCircumstance' tabIndex={0}>{literals.Circumstances.description1}</p>
                
            </div>
        </div>
    <div className='row m-4'>
                {
                optionList.map((item,index) => (
                  <div tabIndex={0} className='col-sm-4 highlight' onKeyDown={handleTileKey(item,index)}>
                <div className={`cardDisplay-${index} card needHelp helpText-${index}`} onClick={() => callSecondComponent(item,index)  }>
                {item.selected && <div className={`tickOverlay-${index}`}><span className='visual'>Ticked {`${item.name}`}</span></div>}
                    <div className='cardContentnh'>
                        <h3 className='card-title'>{item.name}</h3>
                        <p className='card-text'>{item.description}</p>
                    </div>
                    <div>
                    </div>
                </div>
                </div>
                ))
                }
        </div>
        </div>
    )}
        </div>
  )
}

export default FirstComponent