const  SET_SAFE_TOGGLE = 'SET_SAFE_TOGGLE';

// Initial State
const initialState = {
  safeToggle: null,
};
const safeToggleReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_SAFE_TOGGLE:
        return {
          ...state,
          safeToggle: action.payload,
        };
      default:
        return state;
    }
  };

export default safeToggleReducer;