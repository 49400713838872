import React from "react";
import { Route, Routes } from "react-router-dom";
import Consent from "../components/ConsentPageComponents/ConsentPageComponents";
import LocationSpecific from "../components/LocationSpecific/LocationSpecific";
import DemographicConsent from "../components/DemographiComponents/DemographicConsentComponents";
import ThankYou from "../components/EndofStepOneComponents/EndofStepOneComponents";
import ThankYouStepTwo from "../components/EndofStepTwoComponents/EndofStepTwoComponents";
import CheckYourAnswers from "../components/CheckYourAnswers/CheckYourAnswersComponent";
import { CheckYourAnswerProvider } from "../components/CheckYourAnswers/Context/CheckYourAnswerContext";
import AboutService from "../components/AboutThisService/AboutService";
import PrivacyNotice from "../components/PrivacyNotice/PrivacyNotice";
import CCUSupportOne from "../components/CCUSupportOne/CCUSupportOne";
import LoadingSpinner from '../components/LoadingSpinner/LoadingSpinner';
import ImpactPageOne from "../components/ImpactPage/ImpactPageOne";
import ErrorPage from "../components/ErrorMessage";
import HomeComponent from "../components/HomeComponent/HomeComponent";
import WelcomeComponent from "../components/WelcomeComponent/WelcomeComponent";
import NeedHelpComponent from "../components/NeedHelp/NeedHelpComponent";
import OptionComponent from "../components/NeedHelp/OptionComponent/OptionComponent";
import ThankYouComponent from "../components/NeedHelp/ThankYouComponent/ThankYouComponent";
import InteractiveDashboardComponent from "../components/InteractiveDashboardComponent/Home/Home";
import ReviewComponents from "../components/ReviewComponents/ReviewComponents";
import TermsAndConditions from "../components/TermsAndConditions/TermsAndConditions";
import CookiePolicy from "../components/Cookiepolicy/cookiepolicy";

const ProvideCheckYourAnswer = ({ theme }) => (
  <CheckYourAnswerProvider>
    <CheckYourAnswers theme={theme} />
  </CheckYourAnswerProvider>
);

const Router = (props) => (

<Routes>
    <Route path='' element={<LoadingSpinner/>}/>
    <Route path='/home' element={<HomeComponent clientNotifService={props.clientNotifService} theme={props.theme} userId={props.userId} privacyUrl={props.privacyUrl} tncUrl={props.tncUrl} contactNumber={props.contactNumber}/>}/>
    {/* <Route path='/home' element={<Home />}/> */}
    <Route path='/welcome' element={<WelcomeComponent theme={props.theme} deviceType={props.deviceType} contactNumber={props.contactNumber}/>}/>
    <Route path='/consent' element={<Consent theme={props.theme}/>}/>
    <Route path='/needhelp' element={<NeedHelpComponent theme={props.theme} screenData={props.screenData} screenName={props.screenName}/>}/>
    <Route path='/postcode' element={<LocationSpecific theme={props.theme} />}/>
    <Route path='/demographic-consent' element={<DemographicConsent theme={props.theme} screenData={props.screenData} screenName={props.screenName}/>}/>
    <Route path='/endofstep-1' element={<ThankYou theme={props.theme} />}/>
    <Route path='/endofstep-2' element={<ThankYouStepTwo theme={props.theme} />}/>
    <Route path='/impacts' element={<ImpactPageOne theme={props.theme} screenData={props.screenData}/>}/>
    <Route path='privacynotice' element={<PrivacyNotice theme={props.theme}/>}/>
    <Route path='CookiePolicy' element={<CookiePolicy theme={props.theme}/>}/>
    <Route path='termsandconditions' element={<TermsAndConditions theme={props.theme}/>}/>
    <Route path='/outputscreen' element={<InteractiveDashboardComponent theme={props.theme} userId={props.userId} />} />
    <Route path="/ccusupport" element={<CCUSupportOne theme={props.theme} />} />
    <Route path="/aboutservice" element={<AboutService theme={props.theme} />} />
    <Route path='/checkyouranswers' element={<ProvideCheckYourAnswer theme={props.theme}/>}>
    </Route>
    <Route path="/reviews" element={<ReviewComponents theme={props.theme} journey_id={props.journey_id} support_option_id={props.support_option_id} averageueserID={props.averageueserID}/>} />
    <Route path="/errorpage" element={<ErrorPage/>}/>
    <Route path="/options" element={<OptionComponent theme={props.theme} />}/>
    <Route path="/exitpage" element={<ThankYouComponent theme={props.theme} />}/>
  </Routes>
);

export default Router;
