import React, { useState } from 'react';
import './CustomDropDown.scss';

const CustomDropDown = ({ options, selectedOption, setSelectedOption }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => setIsOpen(!isOpen);
    const toggleDropdownKeyboard = (e) => (e.key === 'Enter' || e.key === ' ') && setIsOpen(!isOpen);

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setIsOpen(false);
    };

    const dropdownClasses = `dropdown ${isOpen ? 'open' : ''}`;

    return (
        <div className={dropdownClasses}>
            <div  tabIndex={0} className="dropdown-header" onClick={toggleDropdown} onKeyDown={toggleDropdownKeyboard}>
                <p tabIndex={0} className='selected-Droptext'>{selectedOption.label}</p>
                <span className={`dropdown-arrow ${isOpen ? 'open' : ''}`}></span>
            </div>
            {isOpen && (
                <ul className="dropdown-list">
                    {options
                        .filter((opt) => opt.label !== selectedOption.label)
                        .map((option) => (
                            <li
                                tabIndex={0}
                                key={option.value}
                                className="dropdown-list-item selected-Droptext"
                                onClick={() => handleOptionClick(option)}
                                onKeyDown={(e) => (e.key === 'Enter' || e.key === ' ') && handleOptionClick(option)}
                                role='button'
                                aria-label={option.label}
                                aria-pressed={option.label === selectedOption.label}
                            >
                                {option.label}
                            </li>
                        ))}
                </ul>
            )}
        </div>
    );
};

export default CustomDropDown;
