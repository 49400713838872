import React,{useState} from "react";
import ContinueButton from "../ContinueButton/ContinueButton";
import { useNavigate } from "react-router";
import BackButton from "../BackButton/BackButton";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import PageTimeTracking from "../PageTracking/PageTracker";
import { ProgressBar } from '../ProgressBar/ProgressBar';
import "./EndofStepTwoComponents.scss";
import literals from "../Literals/Literals";
import { useResumeJourney } from "../../customHooks/useResumeJourney";
import { storageKeys } from "../../utils/storageKeys";
import Joyride from "react-joyride";

function EndofStepTwoComponents(props) {
  console.log("hbhbhb", props.theme)
  const getItemFromHelper = useResumeJourney();
  const prevPage = getItemFromHelper.prevPage;
  const nextPage = getItemFromHelper.nextPage;
  const navigation = useNavigate();
  const buttonText = "Back";
 const buttonTextContinue = "Continue";
  const themeselected = props.theme;
  const supportProgress = localStorage.getItem("SupportProgress");
  const [run,setRun] = useState(false)
  const [showHelp,setShowHelp] = useState(false);

  function saveItem(key, result) {
    localStorage.setItem(key, result);
  }

  const steps = [
    {   target:".progresshelp",
        content:<h2>{literals.help.endofstep2.endofstep1_Tracker}</h2>,
        placement: 'bottom',
        disableBeacon: true,
    },
    {   target:".continuehelp",
        content:<h2>{literals.help.endofstep2.endofstep1_Continue}</h2>,
        disableBeacon: true,
  },
]

   const handleShowHelp = () => {
      setRun(true);
  }

  const handleJoyrideCallback = (data) => {
    const { status, index, type } = data;
    const finishedStatuses = ['finished', 'skipped'];

    if (
      finishedStatuses.includes(status) ||
      (type === 'step:after' && index === steps.length - 1)
    ) {
      setRun(false);
    }
  };

  const handleBack = () => {
    saveItem(storageKeys.isComingBackFromThankYouTwo,true);
            navigation(`/${prevPage}`);
  }

  function handleContinue () {
    localStorage.removeItem(storageKeys.isComingBackFromThankYouTwo)
    navigation(`/${nextPage}`);
  }
  
  return (
    <div className="TYparent-container-2">
     <Joyride
    steps={steps}
    run={run}
    continuous
    callback={handleJoyrideCallback}
    spotlightClicks={false}
    scrollToFirstStep
    hideBackButton={true}
        locale={{last: 'Close'}}
    styles={{
      options: {
        arrowColor: '#fff',
        backgroundColor: '#e3ffeb',
        overlayColor: 'transparent',
        primaryColor: '#000',
        textColor: '#004a14',
        zIndex: 1000,
        spotlightPadding: 0,
      },
    }}
    />
        <Header tabIndex={0} homepage={false} />
        <div className="progresshelp">
        <ProgressBar tabIndex={0} pageName="impact"  progressValueC={100} progressValueI={100}  progressValueS={supportProgress ?? 0} />
        </div>
        <div className="help_nh" alt="sp_logo" tabIndex={0} onClick={handleShowHelp}><span className="visual">Help Icon</span></div>
        <div className='headContainer'>
            <div className='secondImage myCir' tabIndex={0} ><span className="visual">Impacts Image</span></div>
            <div className="w-9">
                <h1 className='headingCircumstance' tabIndex={0} >{literals.Impacts.description4}</h1>
                <p className="title-para" tabIndex={0}>
            {literals.Impacts.subheading}
          </p>
            </div>
        </div>


        <div className="greyContainer TYgrey-area">
      {/* First Div */}
      <div className='card cardDisplay-3 col-sm-3' tabIndex={0}>
        <span className="visual">A man raises his hand and then nods in thanks</span>
      </div>

      {/* Second Div */}
      <div className="m-5">
        <h2 className='TYcard-title' tabIndex={0} >{literals.ThankYouTwo.heading}</h2>
        <div className='card-text TY-fixPadding' tabIndex={0} >{literals.Impacts.description2}</div>
        <div className='card-text TY-fixPadding' tabIndex={0} >{literals.Impacts.description3}</div>
        <div className='card-text TY-fixPadding' tabIndex={0} >{literals.Impacts.description5}</div>
      </div>
    </div>

        <div className="bottomBtn">
            <BackButton label={buttonText} onClick={handleBack} className="float-start"/>
              <ContinueButton   classname = 'ContinueButton continuehelp' label={buttonTextContinue} onClick={handleContinue} float-end/>
                </div>
                <div className='third  footer_container'>
                <Footer screenName="EndOfStep-2" theme={themeselected} homepage={false} />
                </div>
                <PageTimeTracking pageName="EndOfStep-2" />
    </div>
  );
}

export default EndofStepTwoComponents;
