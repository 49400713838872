import React, { useEffect, useRef, useState } from 'react';
import "./MarkComplete.scss";
import literals from '../../Literals/Literals';
import { AddRemoveSupportOptions,saveRatingReview } from '../../../services/journeyService';
import { sanitizeInput } from '../../../utils/helper.methods';

function MarkComplete({ onClose, option, category, onOptionStart, progress, celebrateFunc }) {
  const [markComplete, setMarkComplete] = useState(false)
  const [comment, setComment] = useState("");
  const [hoveredStar, setHoveredStar] = useState(null);
  const [selectedStar, setSelectedStar] = useState(null);
  const [disableButton, setButtonDisabled] = useState(true);
  const popupRef = useRef(null);

  useEffect(() => {
    if(popupRef.current) popupRef.current.focus();
    setSelectedStar(option.rating - 1);
    setComment(option.comments);
    if((option.comments === null || option.comments.toString().length === 0) && option.rating === null) {
      setButtonDisabled(true);
    }
    else{
      setButtonDisabled(false);
    }
    //eslint-disable-next-line
  }, [])
  const handleMouseEnter = (index) => {
    setHoveredStar(index);
  };

  const handleComplete = () => {
    console.log("Progess", progress)
    Object.entries(progress).map(([cat, prg]) => {
      console.log("prg", prg, cat);
      if (cat === category) {
        if (prg === 100) {
          celebrateFunc();
        }
      }
      return null
    })
  }

  const handleMouseLeave = () => {
    setHoveredStar(null); // Reset on mouse leave
  };

  const handleComment = (e) => {
  if(e.target.value === null || e.target.value.toString().length === 0) {
    setButtonDisabled(true);
  }
  else{
    setButtonDisabled(false);
  }
    
    setComment(sanitizeInput(e.target.value));
  }

  const handleShareComment = async () => {
    const body = [{
      support_option_id: option.support_option_id,
      rating: selectedStar + 1,
      comments: comment
    }];

    await saveRatingReview(body);
    onClose();
    handleComplete();
    await onOptionStart(option.support_option_id, "completed");
  }
  
  const handleStarClick = (index) => {
    setSelectedStar(index);
    if(comment === null || comment.toString().length === 0) {
    setButtonDisabled(true);
    }
    else
    {
    setButtonDisabled(false);
    }
    console.log("Star rating", index)
  };

  // Determine the class for each star based on hover or click
  const getStarClass = (index) => {
    if ((hoveredStar !== null && index <= hoveredStar) || index <= selectedStar) {
      return 'star filled';
    }
    return 'star';
  };
  useEffect(() => {
    if (option.status === "completed") {
      setMarkComplete(true);
    }
    //eslint-disable-next-line
  }, [])

  const handleMarkEnter = (event) => async() => {
    if(event.key === "Enter"){
      setMarkComplete(!markComplete)
      try {
        const rqstBody = [{ support_option_id: option.support_option_id, status: "completed" }];
        await AddRemoveSupportOptions(rqstBody);
  
      } catch (error) {
        console.error("Error occurred while calling the API:", error);
      }
      await onOptionStart(option.support_option_id, "completed");
    }
  }
  const handleMarkComplete = async () => {
    setMarkComplete(!markComplete)
    try {
      const rqstBody = [{ support_option_id: option.support_option_id, status: "completed" }];
      await AddRemoveSupportOptions(rqstBody);

    } catch (error) {
      console.error("Error occurred while calling the API:", error);
    }
    await onOptionStart(option.support_option_id, "completed");
  }

  const handleKeyDown = () => {handleShareComment()}

  return (
    <div className='modal-overlay' tabIndex={0}  ref={popupRef}>
        {/* <div className='modalbackground reviewRatingpopup'>
               <div className='header IDModalHeader'>
                    <span  className='headingModal titleStar hColor mt-2'>
                        <div className='mainheading' tabIndex={0}>{literals.InteractiveDashboardComponent.MarkComplete.ratingheading}</div>
                    </span>
                    <span>
                        <button tabIndex={0} className="close-button" onClick={onClose}>
                            <div   className='closeModalImg imgD'></div>
                        </button>
                    </span>
                </div>
                <div className='subheader' tabIndex={0} >
                    <p className='categoryStyle' > {`${category} > ${option.organization}`} </p>
                </div>
                <div className="modal-content">

                {!markComplete ?
        <div>
         
            <button tabIndex={0} className='markButton' onClick={handleMarkComplete} onKeyDown={handleMarkEnter}>
              <span className='markText'>{literals.InteractiveDashboardComponent.MarkComplete.completebutton}</span>
            </button>
            </div>
            :
            <div>
            <button className='congratsStyle'>
              <span tabIndex={0} className='congratsText'>{literals.InteractiveDashboardComponent.MarkComplete.congratsmessage}</span>
            </button>
         
        </div>
}
             
                <div  className='ratingContainer'>
                    <div tabIndex={0} className='leftContainer'>
                        <h5  className='averageStyle'>{literals.InteractiveDashboardComponent.MarkComplete.averagerating}</h5>
                        <div className='ratingbox'>
                            {option.average_rating > 0 ? option.average_rating:0.0.toFixed(1)}
                        </div>
                    </div>
                    <div  className='rightContainer'>
                        <div tabIndex={0} className='myRatingStyle'>
                            <h5  className="averageStyle">{literals.InteractiveDashboardComponent.MarkComplete.myrating}</h5>
                            <div  className='starStyle'>
                            {[...Array(5)].map((_, index) => (
        <span  tabIndex={0}
          key={index}
          className={getStarClass(index)}
          onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={handleMouseLeave}
          onClick={() => handleStarClick(index)}
        >
        </span>
      ))}
                            </div>
                        </div>
                        <div className='reviewContainer' >
                            <h5 tabIndex={0} className="goodStyle">{literals.InteractiveDashboardComponent.MarkComplete.whatgood}</h5>
                            <textarea tabIndex={0} className="textContainer " placeholder="Enter your comments here" value={comment} onChange={handleComment}></textarea>
                        </div>
                    </div>
                </div>   </div>
                <div className='modal-footer'>
                {!markComplete ? <button className='startButton'>
                        <span className='shareText' onClick={handleShareComment}>{literals.InteractiveDashboardComponent.MarkComplete.sharebutton}</span>
                    </button> : <button className='startButton'>
                        <span   className='shareText' onClick={handleShareComment}>{literals.InteractiveDashboardComponent.MarkComplete.sharebutton}</span>
                    </button>}
                </div>
        </div> */}
    <div tabIndex={0}  ref={popupRef} className='modal-overlay'>
      <div className='modalbackground reviewRatingpopup'>
        <div className='header IDModalHeader'>
          <span className='headingModal titleStar hColor mt-2'>
            <div className='mainheading' tabIndex={0}>{literals.InteractiveDashboardComponent.MarkComplete.ratingheading}</div>
          </span>
          <span>
            <button tabIndex={0} className="closeStyle" onClick={onClose}>
              <div className='closeModalImg imgD'><span className='visual'>Close Icon</span></div>
            </button>
          </span>
        </div>
  
        <div className="modal-content">      <div className='subheader' tabIndex={0} >
          <p className='categoryStyle' > {`${category} > ${option.organization}`} </p>
        </div>
        {!markComplete ?
        <div>
         
            <button tabIndex={0} className='markButton' onClick={handleMarkComplete} onKeyDown={handleMarkEnter}>
              <span className='markText'>{literals.InteractiveDashboardComponent.MarkComplete.completebutton}</span>
            </button>
            </div>
            :
            <div>
            <button className='congratsStyle'>
              <span tabIndex={0} className='congratsText'>{literals.InteractiveDashboardComponent.MarkComplete.congratsmessage}</span>
            </button>
          
        </div>
}
        <div className='ratingContainer'>
          <div tabIndex={0} className='leftContainer'>
            <h5 className='averageStyle'>{literals.InteractiveDashboardComponent.MarkComplete.averagerating}</h5>
            <div className='ratingbox'>
              {option.average_rating > 0 ? option.average_rating : 0.0.toFixed(1)}
            </div>
          </div>
          <div className='rightContainer'>
            <div tabIndex={0} className='myRatingStyle'>
              <h5 className="averageStyle" aria-label={`My rating ${selectedStar+1}`}>{literals.InteractiveDashboardComponent.MarkComplete.myrating}</h5>
              <div className='starStyle'>
                {[...Array(5)].map((_, index) => (
                  <span
                    key={index}
                    className={getStarClass(index)}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                    onClick={() => handleStarClick(index)}
                  >
                  </span>
                ))}
              </div>
            </div>
            <div className='reviewContainer' >
              <h5 tabIndex={0} className="goodStyle">{literals.InteractiveDashboardComponent.MarkComplete.whatgood}</h5>
              <textarea tabIndex={0} className="textContainer " placeholder="Enter your comments here" value={comment} onChange={handleComment}></textarea>
            </div>
          </div>
        </div> </div>
        <div className='modal-footer'>
            {disableButton && <button
              tabIndex={0}
              className='shareButtonDisabled'
              role="button"
              disabled={disableButton}
            >
              <span className='shareText'>{literals.InteractiveDashboardComponent.MarkComplete.sharebutton}</span>
            </button>}
            {!disableButton && <button
              tabIndex={0}
              className='shareButton'
              onClick={handleShareComment}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  e.preventDefault();
                  handleKeyDown();
                }
              }}
              role="button"
            >
              <span className='shareText'>{literals.InteractiveDashboardComponent.MarkComplete.sharebutton}</span>
            </button>}
        </div>
      </div>
    </div>
    </div>
  );
};

export default MarkComplete