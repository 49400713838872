import Footer from "../Footer/Footer";
import Joyride from "react-joyride";
import "../global.scss";
import literals from "../Literals/Literals";
import { useState, useEffect, useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./ConsentPageComponents.scss";
import PageTimeTracking from "../PageTracking/PageTracker";
import BackButton from "../BackButton/BackButton";
import ContinueButton from "../ContinueButton/ContinueButton";
import ExitButton from "../ExitButton/ExitButton";
import { Save_Column } from "../../services/saveColumnService";
import { useResumeJourney } from "../../customHooks/useResumeJourney";
import { useSelector } from "react-redux";
import { getColumndata } from "../../services/getSavedData";
import { ExitContext } from '../../contexts/exit.context';

export default function Consent(props) {
  const navigation = useNavigate();
  const buttonText = "Back";
  const buttonTextContinue = "Continue";
  const getItemFromHelper = useResumeJourney();
  const prevPage = getItemFromHelper.prevPage;
  const nextPage = getItemFromHelper.nextPage;
  const privacy_url = useSelector((state) => state.privacyUrl.privacyUrl);
  const tncURLFinal = useSelector((state) => state.tncUrl.tncUrl);
  console.log("Privacy URL", privacy_url, "TNC URL", tncURLFinal);
  const { subheading4, subheading5, linkText, linkText2 } = literals.ConsentPage;
  const index = subheading4.indexOf(linkText);
  const beforeSubstring = subheading4.substring(0, index);
  const afterSubstring = subheading4.substring(index + linkText.length);
  const [consent, setConsent] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const themeSelected = props.theme;
  const [run,setRun] = useState(false)
  const { privacyNewTab, serviceNewTab } = useContext(ExitContext);

  const steps = [
    {   target:".privacy-help",
        content:<h2>{literals.help.consent.consent_Privacy}</h2>,
        placement: 'bottom',
        disableBeacon: true,
    },
    {   target:".checkboxBtn",
        content:<h2>{literals.help.consent.consent_CheckBox}</h2>,
        disableBeacon: true,
  },
  {
    target:".cotinueHelp",
    content:<h2>{literals.help.consent.consent_Continue}</h2>,
    disableBeacon: true,
  }
]

   const handleShowHelp = () => {
      setRun(true);
  }

  const handleJoyrideCallback = (data) => {
    const { status, index, type } = data;
    const finishedStatuses = ['finished', 'skipped'];

    if (
      finishedStatuses.includes(status) ||
      (type === 'step:after' && index === steps.length - 1)
    ) {
      setRun(false);
    }
  };


  useEffect(() => {
    const getdata = async () => {
      try {
        const responseRJ = await getColumndata("user_journey_is_consented");
        console.log("Response RJ", responseRJ);
        if (responseRJ.data.user_journey_is_consented) {
          setConsent(responseRJ.data.user_journey_is_consented);
          setIsDisabled(false);
        }
      } catch (error) {
        console.error(error);
      }
    };
    getdata();
  }, []);

  const privacyLinkCreation = useCallback(() => {
    return (
      
      <div className="privacy-help">
        {beforeSubstring}
        <a
          href={privacy_url}
          target="_blank"
          rel="noopener noreferrer"
          className="privacy-notice-style"
          onClick={(e) => {
            e.preventDefault();
            privacyNewTab.current = window.open(`${privacy_url}`, '_blank');
          }}
        >
          {linkText}
        </a>
        {afterSubstring}
        <a
          href={tncURLFinal}
          target="_blank"
          rel="noopener noreferrer"
          className="privacy-notice-style"
          onClick={(e) => {
            e.preventDefault();
            serviceNewTab.current = window.open(`${tncURLFinal}`, '_blank');
          }}
        >
          {linkText2}
        </a>
        {subheading5}
      </div>
    );
  }, [beforeSubstring, afterSubstring, linkText, linkText2, subheading5, privacy_url]);

  const createCheckboxUrl = () => {
    const link1 = privacy_url;
    const text1 = literals.ConsentPage.checkBoxString1;
    const link2 = tncURLFinal;
    const text2 = literals.ConsentPage.checkBoxString2;
    return (
      <div>
        {text1}{" "}
        <a
          href={link1}
          target="_blank"
          rel="noopener noreferrer"
          className="privacy-notice-style"
          onClick={(e) => {
            e.preventDefault();
            privacyNewTab.current = window.open(`${privacy_url}`, '_blank');
          }}
        >
          {literals.ConsentPage.linkText}
        </a>{" "}
        {text2}{" "}
        <a
          href={link2}
          target="_blank"
          rel="noopener noreferrer"
          className="privacy-notice-style"
          onClick={(e) => {
            e.preventDefault();
            serviceNewTab.current = window.open(`${tncURLFinal}`, '_blank');
          }}
        >
          {literals.ConsentPage.linkText2}
        </a>
      </div>
    );
  };


  const handleCheckboxChange = () => {
    setConsent(!consent);
    if (consent) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  };

  const makePostRequest = async (val2) => {
    try {
      const data = {
        value: val2,
        column: "user_journey_is_consented",
      };
      await Save_Column(data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleContinue = () => {
    navigation(`/${nextPage}`);
    makePostRequest(consent);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      const newConsentValue = !consent;
      setConsent(newConsentValue);
      setIsDisabled(!newConsentValue);
    }
  };

  return (
    <div className="parent-container-2">
      <Joyride
    steps={steps}
    run={run}
    continuous
    callback={handleJoyrideCallback}
    spotlightClicks={false}
    scrollToFirstStep
    hideBackButton={true}
        locale={{last: 'Close'}}
    styles={{
      options: {
        arrowColor: '#fff',
        backgroundColor: '#e3ffeb',
        overlayColor: 'transparent',
        primaryColor: '#000',
        textColor: '#004a14',
        zIndex: 1000,
        spotlightPadding: 0,
      },
    }}
    />
      <div className="headerConsent">
        <div tabIndex={0} className="headerLogo">
          <span className="visual">Support Point Logo</span>
        </div>
      </div>
      <div className="exitLaptop">
        <div className="help_b" alt="sp_logo" tabIndex={0} onClick={handleShowHelp}><span className="visual">Help Icon</span></div><ExitButton />
      </div>
      <div className="divSpacer">
        <div tabIndex={0} className="rightImage"><span className="visual">Man pointing towards the consent agreement</span></div>
        <h1 tabIndex={0} className="headerName">Using this Tool</h1>
      </div>

      <div className="internal-container-constent">
        <div className="consentContent">
          <p tabIndex={0} className="customFontBlack">{literals.ConsentPage.subheading2}</p>
          <p tabIndex={0} className="customFontBlack">{literals.ConsentPage.subheading3}</p>
          <p tabIndex={0} className="customFontBlack">{privacyLinkCreation()}</p>
          <div className="checkboxParentCont">
            <section className="checkboxcont">
              <div className="optionsCheckbox_consent">
                <input
                  type="checkbox"
                  id="consentOption"
                  name="consent"
                  onChange={handleCheckboxChange}
                  onKeyDown={handleKeyDown}
                  checked={consent}
                  className="checkboxBtn"
                />
              </div>
              <div className="c-fixLabelconsent">
                <label tabIndex={0} htmlFor="consentOption" className="fixLabelCheckbox">
                  {createCheckboxUrl()}
                </label>
              </div>
            </section>

          </div>
        </div>

        <div className="btnContainer">
          <BackButton
            label={buttonText}
            onClick={() => {
              navigation(`/${prevPage}`);
            }}
          />
          <div className="cotinueHelp">
          <ContinueButton
            classname={isDisabled ? "ContinueButtonDisabled" : "ContinueButton"}
            label={buttonTextContinue}
            disabled={isDisabled}
            tabIndex={isDisabled ? -1 : 0}
            onClick={handleContinue}
          />
          </div>
        </div>


        <div>
          <Footer screenName="Consent" theme={themeSelected} homepage={false} />
          <PageTimeTracking pageName="Consent" />
        </div>
      </div>
    </div>
  );
}
