import "./LocationSpecific.scss";
import React, { useEffect, useState } from "react";
import ContinueButton from "../ContinueButton/ContinueButton";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { useNavigate } from "react-router-dom";
import PageTimeTracking from "../PageTracking/PageTracker";
import { ProgressBar } from "../ProgressBar/ProgressBar";
import BackButton from "../BackButton/BackButton";
import { Save_Column } from "../../services/saveColumnService";
import { useResumeJourney } from '../../customHooks/useResumeJourney';
import { getColumndata } from "../../services/getSavedData";
import "../global.scss";
import { getOutcodeStatusService } from "../../services/getOutcodeStatusService";
import literals from "../Literals/Literals";
import Joyride from "react-joyride";
import {
  getNationalSupport,
  getLocalSupport,
  AddRemoveSupportOptions,
} from "../../services/journeyService";
import '../../themes/SopraTheme/sopra.scss';
import OptionCategory from "./CustomComponents/OptionCategory";

export default function LocationSpecific(props) {
  const navigation = useNavigate();
  const getItemFromHelper = useResumeJourney();
  const prevPage = getItemFromHelper.prevPage;
  const [postcode, setPostCode] = useState("");
  const themeselected = props.theme;
  const [run,setRun] = useState(false)
  const [optionListLocal, setOptionListLocal] = useState([]);
  const [optionListNational, setOptionListNational] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [nationalSupport, setNationalSupport] = useState({});
  const [localSupport, setLocalSupport] = useState({});
  const [combinedOptions, setCombinedOptions] = useState({});
  const [showAllStates, setShowAllStates] = useState({});
  const [triggerFetchData, setTriggerFetchData] = useState(true);
  const supportProgress = localStorage.getItem("SupportProgress");
  const [invalidPostcode, setInvalidPostcode] = useState(false);
  const [showListClicked, setShowListClicked] = useState(false);

  const toggleShowAllCategory = (category) => {
    setShowAllStates((prevShowAllStates) => ({
      ...prevShowAllStates,
      [category]: !prevShowAllStates[category],
    }));
  };

  const steps = [
    {   target:".inputhelp",
        content:<h2>{literals.help.support_postcode.postcode_Local}</h2>,
        placement: 'bottom',
        disableBeacon: true,
    },
   
    {   target:".optionsContPostcode",
      content:<h2>{literals.help.support_postcode.postcode_Local_OptionList}</h2>,
      placement: 'top',
      disableBeacon: true,
  },
  
  {   target:".buttonShowMore",
    content:<h2>{literals.help.support_postcode.postcode_Show_More}</h2>,
    placement: 'bottom',
    disableBeacon: true,
},
]

   const handleShowHelp = () => {
      setRun(true);
  }

  const handleJoyrideCallback = (data) => {
    const { status, index, type } = data;
    const finishedStatuses = ['finished', 'skipped'];

    if (
      finishedStatuses.includes(status) ||
      (type === 'step:after' && index === steps.length - 1)
    ) {
      setRun(false);
    }
  };


  useEffect(() => {
    const getdata = async () => {
      const responseRJ = await getColumndata("user_journey_postcode");
      const savedPostcode = responseRJ.data.user_journey_postcode;
      setPostCode(savedPostcode);
      console.log("response postcode", savedPostcode);
      if (savedPostcode) {
        setTriggerFetchData(true);
        setShowListClicked(true);
      }
    };
    getdata();
    console.log("postcode after calling get data", postcode);
    //eslint-disable-next-line
  }, []);

  const requestBodyNational = {
    postCode: postcode,
    responses: optionListNational,
  };
  const requestBodyLocal = {
    postCode: postcode,
    responses: optionListLocal,
  };

  const fetchAndCombineData = async (invalidCode = false) => {
    try {
      setIsLoading(true);
      let localSupportData = {};
      let nationalSupportData = {};

      if (postcode && invalidCode === false) {
        const responseLocal = await getLocalSupport(postcode);
        if (responseLocal && typeof responseLocal === "object" && Object.keys(responseLocal).length > 0) {
          localSupportData = responseLocal.data;
          console.log("local support data", localSupportData);
        }
      }

      const responseNational = await getNationalSupport(postcode);
      if (responseNational && typeof responseNational === "object" && Object.keys(responseNational).length > 0) {
        nationalSupportData = responseNational.data;
        console.log("national support data", nationalSupportData);
      }

      if(invalidCode){
       nationalSupportData = nationalSupport || {};
       localSupportData = {}; 
      }

      CombineLists(nationalSupportData, localSupportData);
      setLocalSupport(localSupportData || {});
      setNationalSupport(nationalSupportData || {});
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };
  

  useEffect(() => {
    console.log("postcode before calling fetchAndCombineData", postcode);
    if (triggerFetchData) {
      fetchAndCombineData();
      setTriggerFetchData(false);
    }
    //eslint-disable-next-line
  }, [triggerFetchData, postcode]);

  const CombineLists = (nationalSupports, localSupports) => {
    const combinedOptionsData = {};

    if (nationalSupports) {
      Object.entries(nationalSupports).forEach(([category, options]) => {
        options = options.map((option) => ({
          ...option,
          source: "national",
        }));
        combinedOptionsData[category] = options;
      });
    }

    if (localSupports && Object.keys(localSupports).length > 0) {
      Object.entries(localSupports).forEach(([category, options]) => {
        options = options.map((option) => ({
          ...option,
          source: "local",
        }));

        if (combinedOptionsData.hasOwnProperty(category)) {
          combinedOptionsData[category] = combinedOptionsData[category].concat(options);
        } else {
          combinedOptionsData[category] = options;
        }
      });
    }
    setCombinedOptions(combinedOptionsData);
    console.log("this is inside combine", combinedOptionsData)
  }

  const handleButtonClick = (optionId, checked, source) => {
    console.log("inside handleCheckbox");
    if (source === "national") {
      handleCheckboxChangeNational(optionId, checked);
    } else if (source === "local") {
      handleCheckboxChangeLocal(optionId, checked);
    }
    const updatedOptions = { ...combinedOptions };
    for (const category in updatedOptions) {
      if (Array.isArray(updatedOptions[category])) {
        updatedOptions[category] = updatedOptions[category].map((option) => {
          if (option.support_option_id === optionId) {
            option.selected = checked;
          }
          return option;
        });
      }
    }
    setCombinedOptions(updatedOptions);
  };

  const handleCheckboxChangeNational = (optionId, checked) => {
    const updatedOptions = { ...nationalSupport };
    for (const category in updatedOptions) {
      if (Array.isArray(updatedOptions[category])) {
        updatedOptions[category] = updatedOptions[category].map((option) => {
          if (option.support_option_id === optionId) {
            option.selected = checked;
          }
          return option;
        });
      }
    }
    setNationalSupport(updatedOptions);
    const selectedOptions = [];
    for (const category in updatedOptions) {
      if (Array.isArray(updatedOptions[category])) {
        updatedOptions[category].forEach((option) => {
          selectedOptions.push({ support_option_id: option.support_option_id, selected: option.selected });
        });
      }
    }
    setOptionListNational(selectedOptions);
    console.log("National", optionListNational);
  };

  const handleCheckboxChangeLocal = (optionId, checked) => {
    const updatedOptions = { ...localSupport };
    for (const category in updatedOptions) {
      if (Array.isArray(updatedOptions[category])) {
        updatedOptions[category] = updatedOptions[category].map((option) => {
          if (option.support_option_id === optionId) {
            option.selected = checked;
          }
          return option;
        });
      }
    }
    setLocalSupport(updatedOptions);
    const selectedOptions = [];
    for (const category in updatedOptions) {
      if (Array.isArray(updatedOptions[category])) {
        updatedOptions[category].forEach((option) => {
          selectedOptions.push({ support_option_id: option.support_option_id, selected: option.selected });
        });
      }
    }
    setOptionListLocal(selectedOptions);
    console.log("Local", optionListLocal);
  };

  const handleContinue = async () => {
    localStorage.setItem("SupportProgress", 100);
    try {
      setIsLoading(true);
      if (requestBodyNational.responses.length === 0) {
        const finalOptions = { ...nationalSupport };
        let selectedOptionsNational = [];
        for (let categoryN in finalOptions) {
          if (Array.isArray(finalOptions[categoryN])) {
            finalOptions[categoryN].forEach((option) => {
              selectedOptionsNational.push({
                support_option_id: option.support_option_id,
                selected: option.selected,
              });
            });
          }
        }
        setOptionListNational(selectedOptionsNational);
        requestBodyNational.responses = selectedOptionsNational;
      }
      if (requestBodyLocal.responses.length === 0) {
        const finalOptionsLocal = { ...localSupport };
        let selectedOptionsLocal = [];
        for (let categoryL in finalOptionsLocal) {
          if (Array.isArray(finalOptionsLocal[categoryL])) {
            finalOptionsLocal[categoryL].forEach((option) => {
              selectedOptionsLocal.push({
                support_option_id: option.support_option_id,
                selected: option.selected,
              });
            });
          }
        }
        setOptionListLocal(selectedOptionsLocal);
        requestBodyLocal.responses = selectedOptionsLocal;
      }
      const mergedResponses = [...requestBodyNational.responses, ...requestBodyLocal.responses];
      await AddRemoveSupportOptions(mergedResponses);
      navigation(`/demographic-consent`);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const makePostRequest = async (val) => {
    try {
      const data = {
        value: val,
        column: "user_journey_postcode",
      };
      const response = await Save_Column(data);
      console.log("Save postcode ", response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const addSpaceBeforeLastChar = (str) => {
      return str.slice(0, str.length-1) + ' ' + str.charAt(str.length-1);
  }
  
  const SectorCodeValidation = (resultArr, postcodeValue) => {
    const { incode, outcode } = resultArr[0];
    const sectorCode = incode[0];
    
    const isValid =
      postcodeValue[postcodeValue.length - 1] === sectorCode &&
      postcodeValue.length === outcode.length + 2 &&
      postcodeValue.substring(0, outcode.length) === outcode;
    
    if (isValid) {
      console.log("postcode valid", isValid);
      setPostCode(postcodeValue);
      setInvalidPostcode(false);
      makePostRequest(postcodeValue);
      setTriggerFetchData(true);
    }
    else{
      setInvalidPostcode(true);
    }
  }
  
  async function handlePostCode() {
    setShowListClicked(true);
  
    let trimmedPostcode = postcode?.trim().toUpperCase();
    let postcodeValue = null;
  
    // Handle case where postcode has a space or needs a space
    if (trimmedPostcode && trimmedPostcode.length > 2 && trimmedPostcode.length <= 6) {
      postcodeValue =
        trimmedPostcode.indexOf(" ") === trimmedPostcode.length - 2
          ? trimmedPostcode
          : addSpaceBeforeLastChar(trimmedPostcode);
    }
    else{
      postcodeValue = null;
      setInvalidPostcode(true);
    }
  
    // If valid postcodeValue exists, proceed with service call
    if (postcodeValue) {
      try {
      console.log("postcodeValue", postcodeValue);
        const response = await getOutcodeStatusService(postcodeValue);
        const resultData = response.data.result;
  
        if (resultData && resultData.length) {
          setInvalidPostcode(false);
          SectorCodeValidation(resultData, postcodeValue);
        } 
        else if (resultData === null) {
          setInvalidPostcode(true);
          setLocalSupport({});
          makePostRequest("");
        }
      } catch (error) {
        console.error(error);
        const errorStatus = error?.response?.status;
  
        if (errorStatus === 404 || errorStatus === 400) {
          setInvalidPostcode(true);
          setLocalSupport({});
          makePostRequest("");
          fetchAndCombineData(true);
          setTriggerFetchData(true);
        }
      }
    } else if (postcode === "") {
      await makePostRequest("");
      await fetchAndCombineData();
    }
  }
  

  function handleCode(e) {
    const value = e.target.value.toUpperCase();
    setPostCode(value);
  }

  useEffect(() => {
    if (postcode === "") {
      setInvalidPostcode(false);
    }
  }, [postcode]);

  return (
    <div>
      <Joyride
    steps={steps}
    run={run}
    continuous
    callback={handleJoyrideCallback}
    spotlightClicks={false}
    scrollToFirstStep
    hideBackButton={true}
        locale={{last: 'Close'}}
    styles={{
      options: {
        arrowColor: '#fff',
        backgroundColor: '#e3ffeb',
        overlayColor: 'transparent',
        primaryColor: '#000',
        textColor: '#004a14',
        zIndex: 1000,
        spotlightPadding: 0,
      },
    }}
    />

      <div>
        <Header tabIndex={0} className="header" homepage={false} />
        <ProgressBar tabIndex={0} pageName="needhelp" progressValueC={100} progressValueI={100} progressValueS={supportProgress ?? 50} />
        <div className="help_nh" alt="sp_logo" tabIndex={0} onClick={handleShowHelp}><span className="visual">Help Icon</span></div>
      </div>

      <div className='headContainer'>
        <div tabIndex={0} className='thirdImage myCir'><span className="visual">Choose support image</span></div>
        <div>
          <h1 tabIndex={0} className='headingCircumstance'>{literals.welcome.title3}</h1>
        </div>
      </div>

      <div className="greyContainer">
        <div>
          <div className="postcodeContainer">
            <h2 tabIndex={0} className="headingPostcode">
              {literals.PostCode.heading}
            </h2>
            <div>
              {/* Main content text here >>> */}
              <p tabIndex={0} className='descPostcode'>{literals.PostCode.subheading}</p>
              <p tabIndex={0} className='descPostcode'>{literals.PostCode.subheading2}</p>
            </div>

            <div className="PostcodeCont">
              <label htmlFor="postcode" tabIndex={0} className="postcodelabel">{literals.PostCode.label}</label>
              <div className={invalidPostcode ? "inputhelp" : "inputhelp"}>
                <input
                  tabIndex={0}
                  maxLength={6}
                  id="postcode"
                  placeholder={literals.PostCode.Placeholder}
                  value={postcode || ""}
                  onChange={handleCode}
                  className={`postcodeInput ${invalidPostcode ? "postcodeInvalid" : ""}`}
                  aria-label="Postcode TextBox"
                />
                {invalidPostcode ? <h6 className="invalidMessage">Invalid Sector code</h6> : null}
              </div>
              <button className="showSLButton" tabIndex={0} onClick={handlePostCode}>
                {literals.PostCode.ButtonText}
              </button>
            </div>
            {showListClicked && <p className='descPostcode' tabIndex={0}>
              {literals.NationalAndLocal.title}
            </p>}
          </div>

          {isLoading ? (
            <div className="loader-container">
              <div className="spinner"></div>
            </div>
          ) : (
            <>
              {showListClicked && <div className="postcodeContainer">
                <div>
                  {Object.entries(combinedOptions ?? nationalSupport).map(([category, options], index) => (
                    <OptionCategory
                      key={category}
                      category={category}
                      options={options}
                      handleButtonClick={handleButtonClick}
                      showAll={showAllStates[category]}
                      toggleShowAll={() => toggleShowAllCategory(category)}
                      index={index}
                    />
                  ))}
                </div>
              </div>}
            </>
          )}
        </div>
      </div>

      <div className="bottomBtn">
        <BackButton
          onClick={() => {
            navigation(`/${prevPage}`);
          }}
          tabIndex={0}
          label="Back"
          theme={themeselected}
          className="float-start"
        />

        <ContinueButton
          tabIndex={0}
          label="Continue"
          classname='ContinueButton'
          onClick={() => handleContinue()}
          float-end />
      </div>
      <div className='footerCont'>
        <Footer
          screenName="PostCode"
          tabIndex={0}
          className="d-footer"
          theme={props.theme} />
      </div>
      <PageTimeTracking pageName="PostCode" />
    </div>
  );
}
