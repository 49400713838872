import React, { useContext, useState } from 'react';
import './Footer.scss';
import { Link } from 'react-router-dom';
import literals from '../Literals/Literals';
import { NEW_TABS } from '../../utils/constant';
import { ExitContext } from '../../contexts/exit.context';
import { FeedBackNew } from '../Feedbacknew/FeedBackNew';


function FooterTwo(props) {
  const tabIndex = props.tabIndex;
  const { privacyNewTab, serviceNewTab } = useContext(ExitContext);
  const privacyUrl = props.privacyUrl;
  const [isModalOpen, setIsModalOpen] = useState(false);
  console.log("FooterTwo", props);

  const openNewWindow = (newTab) => {
    switch (newTab) {
      case NEW_TABS.privacyNotice:
        localStorage.setItem('theme', props.theme);
        privacyNewTab.current = window.open(`${privacyUrl}`, '_blank');
        break;
      case NEW_TABS.cookiePolicy:
          localStorage.setItem('theme', props.theme);
          serviceNewTab.current = window.open("/CookiePolicy", '_blank');
          break;
      default:
        break;
    }
  };


  const openModal = () => {
    setIsModalOpen(true);
  };


  const closeModal = () => {
    setIsModalOpen(false);
  };

  const FeedbackOpen = () => {
    openModal();
  }

  return (
    <>
    <div className="second_footer_layer">
      <div className='footer-content'>
        <div className='linkContainer'>
          <Link tabIndex={tabIndex} className="linkStyle" onClick={() => openNewWindow(NEW_TABS.privacyNotice)}>{literals.footer.link1}</Link>
          {/* <Link tabIndex={tabIndex} className="linkStyle" onClick={() => openNewWindow(NEW_TABS.aboutService)}>{literals.footer.link2}</Link> */}
          <Link tabIndex={tabIndex} className="linkStyle" onClick={FeedbackOpen}>{literals.footer.link3}</Link>
          <Link tabIndex={tabIndex} className="linkStyleLast" onClick={() => openNewWindow(NEW_TABS.cookiePolicy)}>{literals.footer.link4}</Link>
        </div>
        <div tabIndex={0} className='footerRight'>
          <div className='powerStyle'>Brought to you by</div>
          <div className='firstImg' alt="sopra steria" /><span className="visual">Sopra Steria</span>
        </div>
      </div>
    </div>
    <FeedBackNew screenName={props.screenName} isOpen={isModalOpen} onClose={closeModal} />
    </>
  );
};

export default FooterTwo;
