import React from "react";
import { Toolbar } from "primereact/toolbar";
import SPLogo from "../../assets/images/SPLogo.png";
import "./Header.scss";

export default function HeaderOne(props) {
  const header = (
    <div  tabIndex={0} className="header-banner">  <span className="visual">Support Point Logo</span>
      <img  className="header-logo" src={SPLogo} alt="Logo" />
    </div>
  );

  return (
    <div className="headerHome"> <Toolbar center={header} /> </div>
  );
}
