import axios from "axios";
import { getDefaultHeadersForAPITwo, DEFAULT_PARAMS_ONE } from './default.network';
import { publish } from "../utils/events/events";
import { EVENT_EXIT } from "../utils/events/events.name";
import store from "../redux/store";

// Set config defaults when creating the instance
const azureClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL_SP
});

export default azureClient;

const getUserID = () => {
  const state = store.getState();
  return state.user?.userId;
};

// Add a request interceptor
azureClient.interceptors.request.use(function (config) {
  const userID = getUserID(); 
  console.log("userID azure", userID)
  config.headers = { ...getDefaultHeadersForAPITwo(), ...config.headers };
  config.headers['journeyId'] = localStorage.getItem("journeyId");
  config.headers['user_id'] = userID;
  config.params = DEFAULT_PARAMS_ONE;
  return config;
}, function (error) {
  console.log(error);
  return Promise.reject(error);
});

// Add a response interceptor
azureClient.interceptors.response.use(function (response) {
  console.log(response.data);
  return response;
}, function (error) {
  console.log(error);
  if (error.response) {
    //Unauthorized
    if (error.response.status === 401) {
      publish(EVENT_EXIT);
    }
  }
  return Promise.reject(error);
});
