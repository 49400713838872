import React from 'react';
import "../../global.scss";
import '../../../themes/SopraTheme/sopra.scss';
import "../LocationSpecific.scss";
import ShowMoreLess from './ShowMoreLess';
import OptionButtonPostcode from './OptionButtonPostcode'; 

const OptionCategory = ({ category, options, handleButtonClick, showAll, toggleShowAll, index }) => {
    const getColorClass = (index) => {
        switch (index % 3) {
            case 0:
                return "colorClass0";
            case 1:
                return "colorClass1";
            case 2:
                return "colorClass2";
            default:
                return "";
        }
    };

    const colorClass = getColorClass(index);
    
    if (!options || !Array.isArray(options)) {
        return null;
    }

    return (
        <div>
            <div className="OptionHeadContainer">
                <h2 tabIndex={0}  className="headingPostcode">{category}</h2>
                <ShowMoreLess showAll={showAll} toggleShowAll={toggleShowAll} />
            </div>
            <div className="newDivider"></div>
            <div className="optionsContPostcode">
                {showAll ? (
                    options.map((option) => (
                        <OptionButtonPostcode colorClass={colorClass} key={option.id} option={option} handleButtonClick={handleButtonClick} />
                    ))
                ) : (
                    options.slice(0, 3).map((option) => (
                        <OptionButtonPostcode colorClass={colorClass} key={option.id} option={option} handleButtonClick={handleButtonClick} />
                    ))
                )}
            </div>
        </div>
    );
};

export default OptionCategory;
