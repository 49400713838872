import React from 'react';
import './Footer.scss';
import FooterOne from './FooterOne';
import FooterTwo from './FooterTwo';
import { useSelector } from 'react-redux';

function Footer(props) {
  const classname = props.classname
  const userIdFinal = useSelector((state) => state.user.userId);
  const privacyUrlFinal = useSelector((state) => state.privacyUrl.privacyUrl);
  console.log(userIdFinal);
  const themeselected = props.theme;
  const {homepage,tabIndex} = props;
  return (
    <div className={`${classname} footerPos`}>
       {homepage ? <FooterOne theme={themeselected}/> : <FooterTwo screenName={props.screenName} tabIndex={tabIndex} theme={themeselected} userId={userIdFinal} privacyUrl={privacyUrlFinal}/>}
    </div>
  );
};

export default Footer;
