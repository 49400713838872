const  SET_CLIENT_CONTACT_NUMBER = 'SET_CLIENT_CONTACT_NUMBER';

// Initial State
const initialState = {
    contactNumber: null,
};
const clientContactNumberReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_CLIENT_CONTACT_NUMBER:
        return {
          ...state,
          contactNumber: action.payload,
        };
      default:
        return state;
    }
  };

export default clientContactNumberReducer;