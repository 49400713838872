import HeaderBannerSopra from "../assets/images/HeaderImg.PNG";
import HeaderBannerUKPN from '../assets/images/ukpn_header.png';
import footerImage from '../assets/images/soprafooter.png'
import ukpntheme from '../themes/UKPN/ukpn_pdf';
import sopratheme from '../themes/SopraTheme/sopra_pdf';
import UKPNFooter from '../assets/images/ukpn_footer.png';
function pdfThemeHelper(theme) {
    let style_obj;
    let HeaderBannerImage;
    let FooterImage;
  
    switch (theme) {
      case 'sopra':
        style_obj = sopratheme;
        HeaderBannerImage = HeaderBannerSopra;
        FooterImage = footerImage;
        break;
      case 'ccu':
        style_obj = sopratheme;
        HeaderBannerImage = HeaderBannerSopra
        FooterImage = footerImage;
        break;
      case 'ukpn':
        style_obj = ukpntheme;
        HeaderBannerImage = HeaderBannerUKPN
        FooterImage = UKPNFooter
        break;
      default:
        style_obj = sopratheme;
        break;
    }

    const pdfThemeDetail = {
        style_obj:style_obj,
        HeaderBannerImage:HeaderBannerImage,
        FooterImage:FooterImage
    } 
  return pdfThemeDetail
}

export default pdfThemeHelper