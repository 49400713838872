import React from 'react'
import './Progressbar.scss';

const ProgressBar = ({ progress }) => {
    return (
      <>
      <div tabIndex={0} className='progressBtext'>{progress}% Completed</div>
      <div className="parentDivID">
        <div className="childDivID" style={{ width: `${progress}%` }}></div>
      </div>
      </>
    );
  };

export default ProgressBar