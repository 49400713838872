export const setPrivacyUrl = (privacyUrl) => {
    return {
      type: 'SET_PRIVACY_URL',
      payload: privacyUrl,
    };
  };

export const setTnCUrl = (tncUrl) => {
  return {
    type: 'SET_TNC_URL',
    payload: tncUrl,
  };
};