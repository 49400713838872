import React from "react";
import "./Header.scss";
import HeaderOne from "./HeaderOne";
import HeaderTwo from "./HeaderTwo";

export default function Header(props) {
  const { homepage } = props;
  return (
    <div tabIndex={0} className="mobileHeader" > <span className="visual">Support Point Logo, </span>
      {homepage ? <HeaderOne /> : <HeaderTwo /> }
    </div>
  );
}