import React from 'react';
import { CgDanger } from 'react-icons/cg';
const ErrorPage = () => {
  return (
    <div className="error">
        <CgDanger size={32} color="#30accf" />
        <span>
        <h1>Oops! Something went wrong.</h1>
    </span>
      
      <p>We apologize for the inconvenience. Please try again later.</p>
    </div>
  );
};

export default ErrorPage;