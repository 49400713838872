import React from "react";

export default function LoadingSpinner() {
  return (
    <div className="loader-container">
        <div className="setposition">
            <div className="spinner">
            </div> 
        </div>
    </div>
  );
}