import React from 'react'
import "./CelebrationPopUp.scss"
import literals from '../../Literals/Literals'

function CelebrationPopUp(props) {
  return (
    <div className='modal-overlay'>
        <div className='modalbackground'>
          <div className='header IDModalHeader'>
          <div  className='mainheading' tabIndex={0}>{literals.celebration.heading}</div>   
                    <span>
                        <button  tabIndex={0} aria-label="Close" aria-pressed="false" className="close-button" onClick={props.onClose}>
                            <div className='closeModalImg imgD'></div>
                        </button>
                    </span></div>
                    <div className="modal-content">
                <div className='celebrateText'>
                    <div className='paraText'>
                  {literals.celebration.text}
                </div>
                </div> </div>
             </div>
    </div>
  )
}

export default CelebrationPopUp