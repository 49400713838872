import { createContext, useRef } from "react";

export const CheckYourAnswerContext = createContext({
    journeySummary: [],
    setJourneySummary: () => null,
})

export const CheckYourAnswerProvider = ({ children }) => {

    const journeySummary = useRef([]);

    const setJourneySummary = (data) => {

        console.log("PROVIDE CHECK YOUR ANSWER: ",data)
        
        journeySummary.current = data;
    }

    const value = {
        journeySummary,
        setJourneySummary
    };

    return (
        <CheckYourAnswerContext.Provider value={value}>{children}</CheckYourAnswerContext.Provider>
    )
}