import axios from "axios";
import { getDefaultHeadersForAPIOne } from "./default.network";
import { publish } from "../utils/events/events";
import { EVENT_EXIT } from "../utils/events/events.name";
import store from "../redux/store";

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const getUserID = () => {
  const state = store.getState();
  return state.user?.userId;
};

// Add a request interceptor
axiosClient.interceptors.request.use(
  function (config) {
    const userID = getUserID(); 
    console.log("userID axios", userID);
    config.headers = { ...getDefaultHeadersForAPIOne(), ...config.headers };
    config.headers['journey_id'] = localStorage.getItem("journeyId");
    config.headers['user_id'] = userID;
    config.headers['column']=getDefaultHeadersForAPIOne.column;
    return config;
  },
  function (error) {
    console.log(error);
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosClient.interceptors.response.use(
  function (response) {
    console.log(response.data);
    return response;
  },
  function (error) {
    console.log(error);
    if (error.response) {
      // Unauthorized
      if (error.response.status === 401) {
        publish(EVENT_EXIT);
      }
      if (
        error.response.status === 500 ||
        error.response.status === 503 ||
        error.response.status === 403 ||
        error.response.status === 504
      ) {
        console.log("redirect to error");
        return Promise.reject(error.response.status);
      }
    }
    return Promise.reject(error);
  }
);

export default axiosClient;
