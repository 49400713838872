import React, { useContext, useEffect } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import literals from "../Literals/Literals";
import { ScrollPanel } from "primereact/scrollpanel";
import ExitButton from "../ExitButton/ExitButton";
import { ExitContext } from "../../contexts/exit.context";
import "../global.scss";
import "./AboutService.scss";

function AboutService(props) {
  const themeselected = localStorage.getItem("theme");
  localStorage.removeItem("theme");
  const { setServiceExit, isChildNewTab } = useContext(ExitContext);

  useEffect(() => {
    isChildNewTab.current = true;
    setServiceExit(window);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="parent-container-2">
      <div className="top-container-2">
        <Header  tabIndex={1} className="header" homepage={false} />
        {/* <ProgressBarComponent pageName="feedback" /> */}

        <div className="divSpacer">
          {/* just to give space where 
          progressbar is not presented 
          but Exit button do 
          min-height - 50px */}

          <div className="exitATS zIndex">
            <ExitButton  tabIndex={2} />
          </div>
        </div>

        <div className="atsFix">
          <hr className="divider dividerFix" />
          <h2  tabIndex={3} className="heading headAtsFix heading-noScroll"> {literals.AboutService.heading} </h2>
          <hr className="divider dividerFix headingBottom" />
        </div>
      </div>
      <div className="bottom-container-2">
        <ScrollPanel
          pt={{ root: { style: { width: "100%", height: "100%" } } }}
        >
          <div className="internal-container">
            <div className="internal-child-1 i-child-width">
              <p  tabIndex={4} className="text-content justify-text">
                {literals.AboutService.subheading0}
              </p>
              <p  tabIndex={6} className="text-content justify-text">
                {literals.AboutService.subheading1}
              </p>
              <p  tabIndex={7} className="text-content justify-text">
                {literals.AboutService.subheading2}
              </p>
              <p  tabIndex={8} className="text-content justify-text">
                {literals.AboutService.subheading3}
              </p>
              <p  tabIndex={9} className="text-content justify-text">
                {literals.AboutService.subheading4}
              </p>
              <p  tabIndex={10} className="text-content justify-text">
                {literals.AboutService.subheading5}
              </p>
              <p  tabIndex={11} className="text-content justify-text">
                {literals.AboutService.subheading6}
              </p>
              <p  tabIndex={12} className="text-content justify-text">
                {literals.AboutService.subheading7}
              </p>
              <p  tabIndex={13} className="text-content justify-text">
                {literals.AboutService.subheading8}
              </p>
              <p  tabIndex={14} className="text-content justify-text">
                {literals.AboutService.subheading9}
              </p>
              <p  tabIndex={15} className="text-content justify-text">
                {literals.AboutService.subheading10}
              </p>
            </div>

            <div className="internal-child-3">
              <Footer  tabIndex={16}  theme={themeselected} homepage={true} />
            </div>
          </div>
        </ScrollPanel>
      </div>
    </div>
  );
}

export default AboutService;
