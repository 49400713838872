import React, { useEffect, useState, useRef, useContext } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { FeedBackNew } from '../../Feedbacknew/FeedBackNew'
import { setUserId } from '../../../redux/actions/userActions';
import { saveAs } from "file-saver";
import './Home.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import NationalSupport from '../NationalSupport/NationalSupport';
import LocalSupport from '../LocalSupport/LocalSupport';
import CUSupport from '../CUSupport/CUSupport';
import literals from '../../Literals/Literals';
import { checkBrowser } from "../../../utils/helper.methods";
import { BROWSER_TYPE } from "../../../utils/constant";
import pdfMake from "../../OutputPage/pdf/pdf.helper";
import { ExitContext } from "../../../contexts/exit.context";
import pdfThemeHelper from "../../../utils/pdfThemeHelper";
import Footer from '../../Footer/Footer';
import { getUserFeedback } from '../../../services/getSavedData';
import CustomDropDown from '../../CustomDropDown/CustomDropDown';
import EditJourneyPopUp from '../EditJourneyPopUp/EditJourneyPopUp';
import { getNationalSupportID, getLocalSupportID, getSupportOne,
  loadJourney } from '../../../services/journeyService';
import PhoneIcon from '../../../assets/images/phone.png';
import Joyride from "react-joyride";

function Home(props) {
  let pdftheme = pdfThemeHelper(props.theme);
  let style_obj = pdftheme.style_obj;
  let HeaderBannerImage = pdftheme.HeaderBannerImage;
  let FooterImage = pdftheme.FooterImage;
  const location = useLocation();
  const PostCode = useRef("");
  const [activeTab, setActiveTab] = useState('National Support');
  const [isRendered, setIsRendered] = useState(false);
  const [selectedOption, setSelectedOption] = useState(literals.InteractiveDashboardComponent.NationalOptions[0]);
  const [isReviewOpen, setisReviewOpen] = useState(true);
  const [run, setRun] = useState(false);

  const setReviewState = () => {
    console.log("Review state called")
    setisReviewOpen(false);
  }

  const navigate = useNavigate();
  const mobileScreen = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  const [isLoading, setIsLoading] = useState(false);
  const typeBrowser = checkBrowser();
  const generatedOutputPDF = useRef();
  const blobPDFData = useRef(null);
  const { outputPDFNewTab } = useContext(ExitContext);
  const [feedback, setFeedback] = useState("");
  const [editJourney, setEditJourney] = useState(false);
  const reviewdate = useSelector((state) => state.reviewDate.reviewDate);
  const fileURL = useRef(null);
  const browserType = checkBrowser();
  const NationalData = useRef({});
  const LocalData = useRef({});
  const CCUData = useRef({});
  const [reviewUDate, setReviewUDate] = useState("");
  const [disablePdf, setDisablePdf] = useState(false);

  
  const showReviewDate = (tempReviewDate = reviewdate) => {
    let dateObj = new Date(tempReviewDate);
    let options = { year: 'numeric', month: 'long' };
    let formattedDate = dateObj.toLocaleString('en-US', options);
    setReviewUDate(formattedDate);
  }

  const loadJourneyCall = async () => {
    const response = await loadJourney(
      "ccusupport-new"
    );
    if (response.data && response.data.length > 0) {
      const { id } = response.data[0];
      if (id) {
        const response = await getSupportOne(
          id
        );
        if (response && response.data.reviewDate && response.data.reviewDuration) {
          const tempReviewDate = response.data.reviewDate;
          showReviewDate(tempReviewDate);
        }
      }
    }
  }


  useEffect(() => {
    loadJourneyCall(); //for dynamic review date
  }, []);

  const fetchData = async () => {
    try {
      const [responseNational, responseLocal, journeyResponse] = await Promise.all([
        getNationalSupportID(),
        getLocalSupportID(),
        loadJourney("ccusupport-new")
      ]);

      if (responseNational && Object.keys(responseNational).length > 0) {
        const filteredSelectedData = filterSelectedOptions(responseNational.data);
        NationalData.current = filteredSelectedData;
      }

      if (responseLocal && Object.keys(responseLocal).length > 0) {
        const filteredSelectedData = filterSelectedOptions(responseLocal.data);
        LocalData.current = filteredSelectedData;
      }

      if (journeyResponse.data && journeyResponse.data.length > 0) {
        const { id } = journeyResponse.data[0];
        if (id) {
          const supportResponse = await getSupportOne(id);
          console.log("supportResponse", supportResponse);
          if (supportResponse && supportResponse.data.categories && supportResponse.data.categories.length > 0) {
            const groupedSelectedData = groupDataByCategory(supportResponse.data.categories);
            const filteredSelectedData = filterSelectedOptions(groupedSelectedData);
            console.log("filteredSelectedData", filteredSelectedData);
            CCUData.current = filteredSelectedData;
          }
        }
      }
      await generatePDF(NationalData.current, LocalData.current, CCUData.current);

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const groupDataByCategory = (categories) => {
    return categories.reduce((acc, option) => {
      if (!acc[option.category]) {
        acc[option.category] = [];
      }
      acc[option.category].push(option);
      return acc;
    }, {});
  };

  function filterSelectedOptions(data) {
    const filteredOptions = { ...data };
    Object.entries(filteredOptions).map(([category, options]) => {
      const selectedOpt = options.filter((option) => option.selected)
      if (selectedOpt?.length > 0) {
        filteredOptions[category] = selectedOpt;
      }
      else {
        delete filteredOptions[category];
      }
    });
    return filteredOptions;
  }

  useEffect(() => {
    PostCode.current = (location.state && location.state.Postcode);
    setisReviewOpen(true);
    // eslint-disable-next-line
  }, [activeTab])

  const handleDownload = async () => {
    try {
      NationalData.current = {};
      LocalData.current = {};
      CCUData.current = {};
      setDisablePdf(true);
      await fetchData();
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const getNewUrl = (blob) => {
    try {
      const url = URL.createObjectURL(blob);
      return url;
    } catch (err) {
      console.error("Error creating URL:", err);
      return null;
    }
  }

  const commonMethodPDFOpen = async () => {
    fileURL.current = null;
    let url = getNewUrl(blobPDFData.current);
    if (url === null) return;
    else {
      fileURL.current = url;

      if ((browserType === BROWSER_TYPE.SAFARIAGENT) && mobileScreen) {
        show(blobPDFData.current);
      } else {
        outputPDFNewTab.current = window.open(fileURL.current, '_blank');
        console.log("outputPDFNewTab", fileURL.current);
      }
      if (typeBrowser !== BROWSER_TYPE.SAFARIAGENT) {
        saveAs(blobPDFData.current, "Support-Pack.pdf");
      }
    }
  }

  const show = (blob) => {
    // Emptying file url if exist's, Hence its contains old pdf data
    fileURL.current = null;
    let url = getNewUrl(blobPDFData.current);
    if (url === null) return;
    else {
      fileURL.current = url;
      let a = document.createElement("a");
      a.href = fileURL.current;
      a.target = '_blank';
      a.rel = 'noopener';
      a.click();
    }

  }

  const handleEnterEvent = async (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent default link navigation
      await fetchData();
      commonMethodPDFOpen();
      // Add your custom action here, e.g., open a modal or perform an action
    }
  }

  const CCUSUPPORT = (ccuSupportData) => {
    console.log("ccuSupportData inside", ccuSupportData)
    if (Object.keys(ccuSupportData).length === 0) {
      return []
    }
    return [
      {
        text: clientname,
        style: "subHeading1Blue",
        margin: [0, 15, 0, 0],
      },
      {
        stack: Object.keys(ccuSupportData).flatMap((key) => {
          return [
            {
              text: key,
              style: "subHeading2",
              margin: [0, 8, 0, 3],
            },
            {
              ul: ccuSupportData[key].map((option) => [
                {
                  text: option.fieldType !== "checkbox" ? option.value : option.name,
                  style: "description",
                  margin: [0, 0, 0, 0]
                }
              ]),
            }
          ];
        }),
      }
    ]
  };



  const SUPPORT_OPTIONS = (supportData, heading) => {
    console.log("supportData inside", supportData)
    return [
      {
        text: heading,
        style: "subHeading1Blue",
        margin: [0, 15, 0, 0],
      },
      {
        text: literals.Output.PDF_text6,
        style: "description",
        margin: [0, 3, 0, 2],
      },
      {
        stack: Object.keys(supportData).flatMap((key) => {
          return [
            {
              text: key,
              style: "subHeading2",
              margin: [0, 8, 0, 3],
            },
            {
              ul: supportData[key].map((option) => [
                createHyperlink(
                  option.organization,
                  option.link,
                  option.contact_number
                ),
                {
                  text: option.description,
                  margin: [0, 7, 0, 2],
                }
              ]),
            },
          ];
        }),
      },
    ]
  };

  // Please dont remove the replaceAll line from the below function --VK 28/02/2023
  const createHyperlink = (text, url, contact_number) => (
    {
      columns: [
        {
          text: text.replaceAll(" ", " "),
          link: url,
          style: 'link',
          target: '_blank',
          width: 'auto'
        },
        { image: "phone", width: 13, height: 13, margin: [0, 2, 0, 0] },
        { text: `${contact_number}`, style: 'phone' }
      ],
      columnGap: 8,
      margin: [0, 10, 0, 0]
    }
  );


  async function generatePDF(national, local, ccu) {
    const header_Img = await getBase64ImageFromURL(HeaderBannerImage);
    const footer_Img = await getBase64ImageFromURL(FooterImage);
    const phoneIcon = await getBase64ImageFromURL(PhoneIcon);
    console.log("look here", [...CCUSUPPORT(ccu)],
      [...SUPPORT_OPTIONS(national, "National Support")],
      [...SUPPORT_OPTIONS(local, "Local Support")]);
    const documentDefinition = {
      //Documentation
      pageSize: "A4",
      //Important Header and Footer margin for A4 page
      pageMargins: [40, 90, 40, 94.2], //[T, R, B, L]

      //Header and Footer
      footer: function (currentPage, pageCount, pageSize) {
        return [
          {
            image: "footerImg",
            width: pageSize.width,
          },
        ];
      },

      header: function (currentPage, pageCount, pageSize) {
        // you can apply any logic and return any valid pdfmake element
        return [
          {
            image: "headerImage",
            width: pageSize.width,
          },
        ];
      },

      content: [
        {
          text: literals.Output.PDF_text2,
          style: "mainHeading",
          margin: [0, 0, 0, 0],
        },
        {
          text: literals.Output.PDF_text1,
          style: "description",
          margin: [0, 3, 0, 0],
        },
        [...CCUSUPPORT(ccu)],
        [...SUPPORT_OPTIONS(national, "National Support")],
        [...SUPPORT_OPTIONS(local, "Local Support")],
      ],

      //Images
      images: {
        headerImage: header_Img,
        footerImg: footer_Img,
        phone: phoneIcon
      },

      styles: style_obj
    };
    generatedOutputPDF.current = pdfMake.createPdf(documentDefinition);
    generatedOutputPDF.current.getBlob((blob) => {
      blobPDFData.current = blob;
      setIsLoading(false);
      if (blobPDFData.current) {
        commonMethodPDFOpen();
      }
    });
    setDisablePdf(false);
  }

  function getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.setAttribute("crossOrigin", "anonymous");

      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;

        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);

        const dataURL = canvas.toDataURL("image/png");

        resolve(dataURL);
      };

      img.onerror = (error) => {
        reject(error);
      };

      img.src = url;
    });
  }
  const userId = props.userId;
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const clientname = localStorage.getItem("clientName")


  useEffect(() => {
    dispatch(setUserId(userId));
    console.log("userId", userId);
  }, [userId, dispatch]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const getdata = async () => {
    try {
      const responseRJ = await getUserFeedback();
      setFeedback(responseRJ?.data.user_feedback_experience);
      if (responseRJ.data.user_feedback_experience == null) {
        console.log("feedback", responseRJ.data.user_feedback_experience);
        openModal();
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      getdata();
    }, 600000);

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  useEffect(() => {
    if (activeTab === literals.InteractiveDashboardComponent.tabNames[0] || activeTab === literals.InteractiveDashboardComponent.tabNames[1]) {
      setIsRendered(true);
    } else {
      setIsRendered(false);
    }
  }, [activeTab]);

  const handleEditPopUp = () => {
    console.log('Edit Journey clicked')
    setEditJourney(true)
  }

  const handleEditJourney = () => {
    navigate("/needhelp");
  }

  const handleCancel = () => {
    setEditJourney(false);
  }

  const steps = [
    {
      target: ".tabClass",
      content: <p>{literals.national_support.nationalsupport_Tittle}</p>,
      placement: 'bottom-start',
      disableBeacon: true,
    },
    {
      target: ".reviewPeriodParent",
      content: <p>{literals.organisations_support.organisations_review}</p>,
      placement: 'bottom',
      disableBeacon: true,
    },
    {
      target: ".downloadPdfBtn",
      content: <p>{literals.organisations_support.organisations_Download}</p>,
      placement: 'bottom',
      disableBeacon: true,
    },
    {
      target: ".editJourneyBtn",
      content: <p>{literals.organisations_support.organisations_Edit}</p>,
      placement: 'bottom',
      disableBeacon: true,
    },
    {
      target: ".sortingCont",
      content: <p>{literals.national_support.national_support_sort}</p>,
      placement: 'bottom',
      disableBeacon: true,
    },
    {
      target: ".scheduleList",
      content: <p>{literals.national_support.national_support_Schedule}</p>,
      placement: 'bottom',
      disableBeacon: true,
    },
    {
      target: ".editList",
      content: <p>{literals.national_support.national_support_Editlist}</p>,
      placement: 'bottom',
      disableBeacon: true,
    },
    {
      target: ".buttonShowMoreID",
      content: <p>{literals.national_support.national_support_Showmore}</p>,
      placement: 'bottom',
      disableBeacon: true,
    },
    {
      target: ".nationalHeading",
      content: <p>{literals.national_support.national_support_organisation}</p>,
      placement: 'bottom',
      disableBeacon: true,
    },
    {
      target: ".localHeading",
      content: <p>{literals.local_support.localsupport_support_organisation}</p>,
      placement: 'bottom',
      disableBeacon: true,
    },
    {
      target: ".progressButton",
      content: <p>{literals.national_support.national_support_start}</p>,
      placement: 'bottom',
      disableBeacon: true,
    },
    {
      target: ".ratingButton",
      content: <p>{literals.national_support.national_support_rating}</p>,
      placement: 'bottom',
      disableBeacon: true,
    },
  ]

  const handleShowHelp = () => {
    setRun(true);
  }

  const handleJoyrideCallback = (data) => {
    const { status, index, type, action } = data;
    const finishedStatuses = ['finished', 'skipped'];

    if (
      finishedStatuses.includes(status) ||
      (type === 'step:after' && index === steps.length - 1)
      || (action === 'close')

    ) {
      setRun(false);
    }
  };

  return (
    <div>
      <Joyride
        steps={steps}
        run={run}
        continuous
        callback={handleJoyrideCallback}
        spotlightClicks={false}
        scrollToFirstStep
        hideBackButton={true}
        locale={{ last: 'Close' }}
        styles={{
          options: {
            arrowColor: '#fff',
            backgroundColor: '#e3ffeb',
            overlayColor: 'transparent',
            primaryColor: '#000',
            textColor: '#004a14',
            zIndex: 1000,
            spotlightPadding: 0,
            textAlign: 'left!important',

          },
        }}
      />
      <div className='HeaderStyle'>
        <div className="headerimage">
          <div className='splogo' tabIndex={0}><span className='visual'>Support Point Logo</span></div>
          <div className='tabClass'>
            <span tabIndex={0} role='button'
              className={`tabsCommon nationalTab ${activeTab === literals.InteractiveDashboardComponent.tabNames[0] ? 'tabActive' : 'tabInactive'}`}
              onClick={() => setActiveTab(literals.InteractiveDashboardComponent.tabNames[0])}
              onKeyDown={(e) => (e.key === 'Enter' || e.key === " ") && setActiveTab(literals.InteractiveDashboardComponent.tabNames[0])}
            >
              <div className='tabHeading'>{literals.InteractiveDashboardComponent.tabNames[0]}</div>
            </span>
            <span tabIndex={0} role='button'
              className={`tabsCommon localTab ${activeTab === literals.InteractiveDashboardComponent.tabNames[1] ? 'tabActive' : 'tabInactive'}`}
              onClick={() => setActiveTab(literals.InteractiveDashboardComponent.tabNames[1])}
              onKeyDown={(e) => (e.key === 'Enter' || e.key === " ") && setActiveTab(literals.InteractiveDashboardComponent.tabNames[1])}
            >
              <div className='tabHeading'>{literals.InteractiveDashboardComponent.tabNames[1]}</div>
            </span>
            <span tabIndex={0} role='button'
              className={`tabsCommon clientTab  ${activeTab === clientname ? 'tabActive' : 'tabInactive'}`}
              onClick={() => setActiveTab(clientname)}
              onKeyDown={(e) => (e.key === 'Enter' || e.key === " ") && setActiveTab(clientname)}
            >
              <div className='tabHeading'>{clientname}</div>
            </span>
          </div>
        </div>
      </div>

      {isReviewOpen && <div className='middleLayerCont'>
        <span className='reviewPeriodParent'>
          <span className='reviewPeriodIcon' tabIndex={0}></span>
          <span className='reviewPeriodText' tabIndex={0}>{`
           ${literals.InteractiveDashboardComponent.reviewtext} ${reviewUDate}`}
          </span>
        </span>
        <span className='alignLeft'>
          <div className="help_nh fixHelpIcon" alt="sp_logo" tabIndex={0} onClick={handleShowHelp}><span className="visual">Help Icon</span></div>
          <button tabIndex={0} aria-label={literals.InteractiveDashboardComponent.Pdftext} aria-pressed="false" className='downloadPdfBtn' disabled={disablePdf} onClick={handleDownload}
            onKeyDown={handleEnterEvent}>
            <span className='downloadPdfIcon'></span>
            <div className='downloadPdfText'>{literals.InteractiveDashboardComponent.Pdftext}</div>
          </button>
          <button tabIndex={0} aria-label={literals.InteractiveDashboardComponent.Edittext} aria-pressed="false" className='editJourneyBtn' onClick={handleEditPopUp} onKeyDown={(e) => (e.key === 'Enter' || e.key === " ") && handleEditPopUp}>
            <span className='editJourneyIcon'></span>
            <div className='editJourneyText'>{literals.InteractiveDashboardComponent.Edittext}</div>
          </button>
          {isRendered && (
            <span className={`sortingCont ${isRendered ? 'show' : ''}`}>
              <h5 tabIndex={0} className='sortingText'>{literals.InteractiveDashboardComponent.sortText}</h5>
              <span className='sortingIcon'></span>
              <CustomDropDown
                options={activeTab === literals.InteractiveDashboardComponent.tabNames[0] ? literals.InteractiveDashboardComponent.NationalOptions : literals.InteractiveDashboardComponent.LocalOptions}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
              />
            </span>
          )}
        </span>
      </div>}

      <div className='contentClass'>
        {activeTab === literals.InteractiveDashboardComponent.tabNames[0] && <NationalSupport sortBy={selectedOption.value} setReviewState={setReviewState} />}
        {activeTab === literals.InteractiveDashboardComponent.tabNames[1] && <LocalSupport sortBy={selectedOption.value} setReviewState={setReviewState} />}
        {activeTab === clientname && <CUSupport />}
      </div>
      {editJourney ? <EditJourneyPopUp cancelEdit={handleCancel} editJourney={handleEditJourney} /> : null}
      <div className='third footer_container mt-5'>
        <Footer screenName="CCUSupport" theme={props.theme} homepage={false} /> {/*change screenname*/}
      </div>
      <FeedBackNew isOpen={isModalOpen} onClose={closeModal} screenName="outputscreen" />

    </div>
  );
}

export default Home;