import React, { useState, useEffect } from 'react';
import './ReviewComponents.scss'; 
import literals from '../Literals/Literals'; 
import { getReviewandRating } from '../../services/journeyService';
import CustomDropDown from '../CustomDropDown/CustomDropDown';

const ReviewComponents = (props) => {
  const [filterStars, setFilterStars] = useState(null); // Default value set to null
  const [localRatingData, setLocalRatingData] = useState([]);
  const [displayRange, setDisplayRange] = useState({ start: 1, end: 10 });
  const [pageNumber, setPageNumber] = useState(1);
  const [sortByRating, setSortByRating] = useState(null);
  const [sortByDate, setSortByDate] = useState(null);
  const options = [
    { value: 'desc', label: 'High to Low' },
    { value: 'asc', label: 'Low to High' },
    { value: 'date', label: 'Date' }
  ];
  const [selectedOption, setSelectedOption] = useState(options[0]);

  useEffect(() => {
    if (props.option.support_option_id) {
      fetchData();
    }
  }, [props.option.support_option_id, pageNumber, sortByRating, sortByDate, filterStars]);

  const handleOptionChange = (option) => {
    setSelectedOption(option); 
    switch (option.value) {
        case 'desc':
            setSortByRating("desc");
            setSortByDate(null);
            break;
        case 'asc':
            setSortByRating("asc");
            setSortByDate(null);
            break;
        case 'date':
            setSortByDate("desc");
            setSortByRating(null);
            break;
        default:
            setSortByRating(null);
            setSortByDate(null);
            break;
    }
};
  const fetchData = async () => {
    const reviewAndRating = await getReviewandRating(
      props.option.support_option_id,
      pageNumber,
      sortByRating,
      filterStars,
      sortByDate
    );
    if (reviewAndRating && Object.keys(reviewAndRating).length > 0) {
      const filteredData = reviewAndRating.data.filter(
        item => item.rating !== null || item.comments !== null
      );
      setLocalRatingData(filteredData);
      console.log("props of review component", filteredData);
    }
  };

  const toggleStar = (star) => {
    setFilterStars(prev => (prev === star ? null : star)); 
    setSortByRating(null);
    setSortByDate(null);
  };

  const incrementDisplayRange = () => {
    if (filteredReviews.length >= 10) {
    setDisplayRange(prevRange => {
      const newStart = Math.min(prevRange.start + 10, 91);
      const newEnd = Math.min(prevRange.end + 10, 100);
      setPageNumber(Math.ceil(newStart / 10));
      return { start: newStart, end: newEnd };
    });
  }
  };

  const decrementDisplayRange = () => {
    setDisplayRange(prevRange => {
      const newStart = Math.max(1, prevRange.start - 10);
      const newEnd = Math.max(10, prevRange.end - 10);
      setPageNumber(Math.ceil(newStart / 10));
      return { start: newStart, end: newEnd };
    });
  };

  const renderStars = (rating) => {
    const filledStars = rating;
    const unfilledStars = 5 - rating;
    const stars = [];

    for (let i = 0; i < filledStars; i++) {
      stars.push(<div key={`star-filled-${i}`} className="star-image star-filled"></div>);
    }

    for (let i = 0; i < unfilledStars; i++) {
      stars.push(<div key={`star-unfilled-${i}`} className="star-image star-unfilled"></div>);
    }

    return stars;
  };

  const filteredReviews = filterStars !== null
    ? localRatingData.filter(item => item.rating >= filterStars)
    : localRatingData;

  return (
    <div className='reviewratingspage'>
      <div  className="headerViewRating">
        <div tabIndex={0} className="rating-box">
          <span>{props.option.average_rating > 0 ? props.option.average_rating : 0.0.toFixed(1)}<span className='visual'>Average user rating</span></span>
        </div>
        
        <div  className="header-content">
          <h1 tabIndex={0} >{literals.ReviewComponents.ViewReview}</h1>
          <p className="subheading-content" tabIndex={0}>{`${props.category} > ${props.option.organization}`}</p>
        </div>
      </div>
      <div className="averageuserrating">
      <p className= "smallText">
        {literals.ReviewComponents.Averageuserrating} </p>
      </div>
      <div  className="filters">
        
        <div  className="header-sub-content">
          <div className='headerPage' tabIndex={0}>{literals.ReviewComponents.RatingsfromOtherUsers}</div>
          
        <div className='starrating'>    <div tabIndex={0} className="filterBy">{literals.ReviewComponents.Filterby}</div>
          
          <div tabIndex={0} className="star-filter"><span className="visual">Filter star image</span>

          </div>

            <div tabIndex={0} className="star-selection">
              {[1, 2, 3, 4, 5].map(stars => (
                <div
                  key={`star-filter-${stars}`}
                  onClick={() => toggleStar(stars)}
                >
                  <div
                    className={`star-image-filter ${filterStars !== null && stars <= filterStars ? 'star-filled-filter' : 'star-unfilled'}`}
                  />
                </div>
              ))}
            </div>
          </div>
        
     

          <div  className="sortByContainer">    
             {/* <span tabIndex={0} className="orText">OR</span> */}
          <span tabIndex={0} className="sortBy">
        {literals.ReviewComponents.Sortby}
      </span>
      <div className="sortByImage"> </div>
    
          <CustomDropDown tabIndex={0}
            options={options}
            selectedOption={selectedOption}
            setSelectedOption={handleOptionChange}
          /></div>
          <div  className="range-buttons">
            <button tabIndex={0} onClick={decrementDisplayRange} disabled={displayRange.start === 1} className="decrement-button" aria-label='decrement button'></button>
            <span tabIndex={0}>{displayRange.start}-{displayRange.end} of 100</span>
            <button tabIndex={0}  onClick={incrementDisplayRange} disabled={filteredReviews.length < 10} className="increment-button" aria-label='increment button'></button>
          </div>       
         </div>
      </div>
      <hr class="divider-line"></hr>
      <ul className="reviews">
        {filteredReviews.map((item, index) => (
          <li key={index} className="review">
        <div tabIndex={0}  className="stars" role="button" aria-label={`Rating: ${item.rating} `}>
              {  renderStars(item.rating)}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ReviewComponents;
