import React, { useState } from 'react';
import MarkComplete from '../../MarkComplete/MarkComplete';
import './OptionButtonShared.scss';
import { AddRemoveSupportOptions } from '../../../../services/journeyService';

const OptionButtonShared = ({isNational, option, getStatusClass, local, category, progress, celebrateFunc, onOptionStart, callReviewState, handleReview }) => {
  const [complete, setComplete] = useState(false);

  const handleComplete = () => {
    setComplete(!complete);
    handleCelebrate();
  }

  const openReviewpage = () => {
    console.log("Review button clicked")
    handleReview(true, option, category);
    callReviewState();
  }

  const handleCelebrate = () => {
    console.log("Progess", progress)
    Object.entries(progress).map(([cat, prg]) => {
      console.log("prg", prg, cat);
      if (cat === category) {
        if (prg === 100) {
          celebrateFunc();
        }
      }
      return null
    })
  }
  const handleStart = async () => {
    if (option.status === "start") {
      try {
        const rqstBody = [{ support_option_id: option.support_option_id, status: "in_progress" }];
        await AddRemoveSupportOptions(rqstBody);
        onOptionStart(option.support_option_id, "in_progress");
      } catch (error) {
        console.error("Error occurred while calling the API:", error);
      }
    }
    else if (option.status === "in_progress" || option.status === "completed") {
      setComplete(!complete);
    }
    else if (option.status === "start_later") {
      try {
        const rqstBody = [{ support_option_id: option.support_option_id, status: "start" }];
        await AddRemoveSupportOptions(rqstBody);
        onOptionStart(option.support_option_id, "start");
      } catch (error) {
        console.error("Error occurred while calling the API:", error);
      }
    }
  }

  const status = {
    start: "Start",
    start_later: "Start later",
    completed: "Completed",
    in_progress: "In Progress"
  }

  return (
    <div className='parentElement'>
      <span className='headContainerId'>
      <div>  <i tabIndex={0} role="img" aria-label={`${option?.type === 'video' ? 'video Icon' : 'link Icon'}`} className={`Video-Link ${option?.type === 'video' ? 'videoIcon' : 'linkIcon'}`}></i> </div>
        <div className={`headingTextCont ${isNational ? 'nationalHeading' : 'localHeading'}`}>
          <h4 className='optionHeadingId' tabIndex={0} onClick={() => window.open(option.link, '_blank')} role='link'>{option.organization}</h4>
          <p tabIndex={0} className='descriptionID'>{option.description}</p>
        </div>
        
      </span>
      <span className='IconContainerId'>
        {local && <span className='fixButtonID'>
          <button tabIndex={0} aria-label={`${Math.round(option.distance)} miles away`} aria-pressed="false" id={option.support_option_id + "d"} className='commonButtonId distanceButton'>
            <i className='distanceIcon iconCommons'></i>
          </button>
          <label htmlFor={option.support_option_id + "d"} className="distanceText">{Math.round(option.distance)} miles away</label>
        </span>}

        <span className='fixButtonID progressFixWidth progressButton'>
          <button tabIndex={0} aria-label={`${status[option.status]}`} aria-pressed="false" id={option.support_option_id + "s"} className={`${option?.status === "in_progress" || option?.status === "completed" ? "backcolour" : ""} ${option?.status === "start" ? "hoverStart" : ""}  commonButtonId progressButton`} onClick={handleStart}>
            <i className={`${getStatusClass[option.status]} iconCommons`}></i>
          </button>
          <label htmlFor={option.support_option_id + "s"} className="progressText">{status[option.status]}</label>
        </span>

        <span className='fixButtonID ratingButton'>
          <button tabIndex={0} aria-label={`${option.average_rating > 0 ? option.average_rating : 0.0.toFixed(1)}`} aria-pressed="false" id={option.support_option_id + "a"} className='commonButtonId reviewButton' onClick={openReviewpage} >
            {option.average_rating > 0 ? option.average_rating : 0.0.toFixed(1)}
          </button>
          <label htmlFor={option.support_option_id + "a"} className="progressText">Ratings</label>
        </span>

      </span>
      {complete && <MarkComplete onClose={handleComplete} option={option} category={category} onOptionStart={onOptionStart} progress={progress} celebrateFunc={celebrateFunc} />}
    </div>
  );
};

export default OptionButtonShared;
