import React,{useState} from "react";
import ContinueButton from "../ContinueButton/ContinueButton";
import { useNavigate } from "react-router";
import BackButton from "../BackButton/BackButton";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import PageTimeTracking from "../PageTracking/PageTracker";
import { ProgressBar } from '../ProgressBar/ProgressBar';
import "./EndofStepOneComponents.scss";
import literals from "../Literals/Literals";
import { useResumeJourney } from "../../customHooks/useResumeJourney";

function EndofStepOneComponents(props) {
  const getItemFromHelper = useResumeJourney();
  const prevPage = getItemFromHelper.prevPage;
  const nextPage = getItemFromHelper.nextPage;
  const navigation = useNavigate();
  const buttonText = "Back";
 const buttonTextContinue = "Step 2";
  const Tyheading = "Thank you !";
 const themeselected = props.theme;
 const [showHelp,setShowHelp] = useState(false);

  const handleBack = () => {
            navigation(`/${prevPage}`);
  }

  function handleContinue () {
    navigation(`/${nextPage}`);
  }
  const handleShowHelp = () => {
    setShowHelp(!showHelp);
  }
  return (
    
    <div className="TYparent-container-2">

        <Header tabIndex={0} homepage={false} />
        <ProgressBar tabIndex={0} pageName="needhelp"  progressValueC={100} progressValueI={0}  progressValueS={0} />
        {/* <div className="help_nh" alt="sp_logo" tabIndex={0} onClick={handleShowHelp}><span className="visual">Help Icon</span></div> */}
    
        <div className='headContainer'>
            <div className='firstImage' tabIndex={0} ><span className="visual">My circumstances Image</span></div>
            <div className="w-9">
                <h1 className='headingImpact' tabIndex={0} >My circumstances</h1>
                <p className='descCircumstance' tabIndex={0} >{literals.Circumstances.description1}</p>
            </div>
        </div>


       
        <div className="greyContainer TYgrey-area">
        <div className='card cardDisplay-3 col-sm-3' tabIndex={0}> <span className="visual">A man raises his hand and then nods in thanks</span> </div>
        <div className="m-5" > 
        <h2 className='TYcard-title' tabIndex={0} >{Tyheading}</h2>
            <div className='card-text' tabIndex={0} >{literals.ThankYou.subheading1}</div>
        </div> </div>

        <div className="bottomBtn">
            <BackButton className="backButton" label={buttonText} onClick={handleBack}/>
              <ContinueButton   classname = 'ContinueButton' label={buttonTextContinue} onClick={handleContinue} float-end/>
                </div> 
                <div className='third footer_container'>
                <Footer screenName="endofstep-1" theme={themeselected} homepage={false} />
                <PageTimeTracking pageName="endofstep-1" />
 </div> </div>
                
   );
}

export default EndofStepOneComponents;
