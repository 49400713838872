import React, { useEffect, useState } from 'react'
import literals from '../../Literals/Literals';
import ProgressBar from '../SharedComponents/Progressbar/Progressbar';
import ShowMoreLess from '../../LocationSpecific/CustomComponents/ShowMoreLess';
import OptionButtonShared from '../SharedComponents/OptionButtonShared/OptionButtonShared';
import '../sharedStyles.scss';
import EditOptions from '../EditOptions/EditOptions';
import { getNationalSupportID } from '../../../services/journeyService';
import ScheduleForLater from '../ScheduleForLater/ScheduleForLater';
import ReviewComponents from '../../ReviewComponents/ReviewComponents';
import CelebrationPopUp from '../CelebrationPopUp/CelebrationPopUp';


function NationalSupport(props) {
  const [data, setData] = useState({});
  const [sortby, setSortBy] = useState(""); {/* for sorting */ }
  const [progress, setProgress] = useState({});
  const [showAllStates, setShowAllStates] = useState({});
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isOpenSchedule, setIsOpenSchedule] = useState(false);
  const [selectedCategory, setSelectedcategory] = useState(""); {/* for category from which edit and schedule is rendered */ }
  const [optionSelected, setOptionSelected] = useState();
  const [categorySelected, setCategorySelected] = useState();
  const [reviewTab, setReviewTab] = useState(false);
  const [celebrationPopUp, setCelebrationPopUp] = useState(false);
  const SortingArr = ["start", "in_progress", "completed", "start_later"];
  const statusObj = {
    start: "progressIcon-start",
    in_progress: "progressIcon-inProgress",
    completed: "progressIcon-completed",
    start_later: "progressIcon-Paused"
  };
  const [ariaPressedEdit, setAriaPressedEdit] = useState(false);
  const [ariaPressedSchedule, setAriaPressedSchedule] = useState(false);

  useEffect(() => {
    if (props.sortBy) {
      setSortBy(props.sortBy);
      SortData(data, props.sortBy);
    }
  }, [props.sortBy]); // Ensure this only runs when props.sortBy changes

  useEffect(() => {
    fetchData();
  }, []); // Initial fetch on component mount

  const handleReviewTab = (isReview, optionSel, categorySel) => {
    console.log("Review tab", isReview);
    setReviewTab(isReview);
    setOptionSelected(optionSel);
    setCategorySelected(categorySel);
  }

  const toggleShowAllCategory = (category) => {
    setShowAllStates((prevShowAllStates) => ({
      ...prevShowAllStates,
      [category]: !prevShowAllStates[category],
    }));
  };

  const fetchData = async () => {
    try {
      const responseNational = await getNationalSupportID();
      if (responseNational && Object.keys(responseNational).length > 0) {
        setData(responseNational.data);
        calculateProgress(responseNational.data);
        SortData(responseNational.data, sortby); // Ensure sorting happens after data fetch
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const celebrateFunc = () => {
    setCelebrationPopUp(true);
  }

  const closePopUp = () => {
    setCelebrationPopUp(false);
  }

  const openEditPage = (category) => {
    setAriaPressedEdit(true);
    setSelectedcategory(category);
    setIsEditOpen(true);
  };

  const handleOptionStart = async (optionId, newStatus) => {
    setData((prevData) => {
      const updatedData = { ...prevData };
      for (const category in updatedData) {
        const options = updatedData[category];
        const optionIndex = options.findIndex(opt => opt.support_option_id === optionId);
        if (optionIndex !== -1) {
          updatedData[category][optionIndex].status = newStatus;
        }
      }
      return updatedData;
    });
    await fetchData();
  };

  const closeEditPage = () => {
    setAriaPressedEdit(false);
    fetchData();
    setIsEditOpen(false);
    setSelectedcategory(null);
  };

  const callReviewState = () => {
    props.setReviewState();
  }

  const openSchedulePage = (category) => {
    setAriaPressedSchedule(true);
    setSelectedcategory(category);
    setIsOpenSchedule(true);
  };

  const onCloseSchedule = () => {
    setAriaPressedSchedule(false);
    fetchData();
    calculateProgress(data || {});
    setIsOpenSchedule(false);
    setSelectedcategory(null);
  };

  const SortData = (data, sortBy) => {
    const sortedData = { ...data }; // Make a copy of the data
    Object.entries(sortedData).forEach(([category, options]) => {
      options.sort((a, b) => {
        if (sortBy === "R") {
          return b.average_rating - a.average_rating;
        }
        else if (sortBy === "D") {
          return a.distance - b.distance;
        }
        else if (sortBy === "P") {
          return SortingArr.indexOf(a.status) - SortingArr.indexOf(b.status);
        }
        return 0;
      });
    });
    setData(sortedData);
  };

  const calculateProgress = (data) => {
    const newProgress = {};
    Object.entries(data).forEach(([category, options]) => {
      let totalOptions = options.filter((opt) => opt.selected && opt.status !== "start_later").length;
      let completedOptions = options.filter((opt) => opt.status === "completed" && opt.selected).length;
      let progress = Math.round((completedOptions / totalOptions) * 100);


      if (progress) {
        newProgress[category] = progress;
      }
      else if (isNaN(progress)) {
        newProgress[category] = 0;
      }
      else {
        newProgress[category] = 0;
      }
    });
    setProgress(newProgress);
  };

  return (
    <>
      {reviewTab && <ReviewComponents option={optionSelected} category={categorySelected} />}
      {!reviewTab &&
        <div className='ActionClass'>
          <div>
            <p tabIndex={0} className='HeadingClass'>{literals.InteractiveDashboardComponent.headingNational}</p>
            <p tabIndex={0} className='ContentClass'>{literals.InteractiveDashboardComponent.subheadingNational}</p>
          </div>
          <div className='idContent'>
            <div>
              {Object.entries(data).map(([category, options], index) => (
                <React.Fragment key={index}>
                  <div className='secondHeading'>
                    <p tabIndex={0} className='secondHeadingClass'>{category}</p>
                    <div className='EditLessClass'>
                      <div className='scheduleList scheduleList'>
                        <div tabIndex={0} className='Buttonlabel'>{literals.InteractiveDashboardComponent.schedule}</div>
                        <button tabIndex={0} aria-label="Schedule List" aria-pressed={ariaPressedSchedule ?? "false"} className='scheduleButton' onClick={() => openSchedulePage(category)}>
                          <span className='ScheduleImg'></span>
                        </button>
                      </div>
                      <div className='EditClass editList'>
                        <div tabIndex={0} className='Buttonlabel'>{literals.InteractiveDashboardComponent.edit}</div>
                        <button tabIndex={0} aria-label="Edit List" aria-pressed={ariaPressedEdit ?? "false"} onClick={() => openEditPage(category)} className='editButton'>
                          <span className='editImg'></span>
                        </button>
                      </div>
                      <ShowMoreLess ID={true} showAll={showAllStates[category]} toggleShowAll={() => toggleShowAllCategory(category)} />
                    </div>
                  </div>
                  <ProgressBar progress={progress[category]} />
                  <div className='optionsRender'>
                    {showAllStates[category] ? (
                      options.filter((opt) => opt.selected).map((option, idx) => (
                        <React.Fragment key={idx}>
                          <OptionButtonShared isNational={true} option={option} onOptionStart={handleOptionStart} getStatusClass={statusObj} local={false} category={category} progress={progress} celebrateFunc={celebrateFunc} callReviewState={callReviewState} handleReview={handleReviewTab} />
                          {(idx + 1) % 2 === 0 && (idx + 1 !== options.length) && <div className='dividerID'></div>}
                        </React.Fragment>
                      ))
                    ) : (
                      options.filter((opt) => opt.selected).slice(0, 4).map((option, idx) => (
                        <React.Fragment key={idx}>
                          <OptionButtonShared isNational={true} option={option} onOptionStart={handleOptionStart} getStatusClass={statusObj} local={false} category={category} progress={progress} celebrateFunc={celebrateFunc} callReviewState={callReviewState} handleReview={handleReviewTab} />
                          {(idx + 1) % 2 === 0 && (idx + 1 !== options.slice(0, 4).length) && <div className='dividerID'></div>}
                        </React.Fragment>
                      ))
                    )}
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
          <EditOptions isOpen={isEditOpen} onClose={closeEditPage} initialList={data[selectedCategory] || null} />
          {data[selectedCategory] && <ScheduleForLater tabIndex={0} isOpenSchedule={isOpenSchedule} onCloseSchedule={onCloseSchedule} initialList={data[selectedCategory] || null} />}
          {celebrationPopUp && <CelebrationPopUp onClose={closePopUp} />}
        </div>
      }
    </>
  );
}

export default NationalSupport;
