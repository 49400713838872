import React, { useState, useEffect, useRef } from 'react';
import literals from '../../Literals/Literals';
import './EditOptions.scss';
import { AddRemoveSupportOptions } from '../../../services/journeyService';

const EditOptions = ({ isOpen, onClose, initialList }) => {
  const [options, setOptions] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [deselectedOptions, setDeselectedOptions] = useState([]);
  const popupRef = useRef(null);

  useEffect(() => {
    if (isOpen && popupRef.current) {
      popupRef.current.focus();
    }
  }, [isOpen]);

  useEffect(() => { 
    if (isOpen && Array.isArray(initialList)) {
      setOptions(initialList);
      setSelectedOptions(initialList.filter((opt) => opt.selected));
      setDeselectedOptions(initialList.filter((opt) => !opt.selected));
    }
  }, [isOpen, initialList]);

  const handleProceed = async () => {
    try {
      const rqstBody = options.map(option => ({
        support_option_id: option.support_option_id,
        selected: option.selected
      }));
      await AddRemoveSupportOptions(rqstBody);
      onClose();
    } catch (error) {
      console.error("Error occurred while calling the API:", error);
    }
  };

  if (!isOpen) {
    return null;
  }

  // Function to handle adding or removing an option
  const handleClick = (option, action) => {
    setIsDisabled(false);
    const updatedOptions = options.map((opt) => {
      if (opt.support_option_id === option.support_option_id) {
        return {
          ...opt,
          selected: action === "add",
        };
      }
      return opt;
    });

    setOptions(updatedOptions);
    setSelectedOptions(updatedOptions.filter((opt) => opt.selected));
    setDeselectedOptions(updatedOptions.filter((opt) => !opt.selected));
  };

  return (
    <div  tabIndex={0} ref={popupRef} className="modal-overlay">
      <div className="modalbackground">
        <div className='header IDModalHeader'>
          <span className='headingEdit'>
            <h2 tabIndex={0}>  {literals.InteractiveDashboardComponent.EditList.Heading} </h2>
          </span>
          <span>
            <button tabIndex={0} aria-label="Close" aria-pressed="false" className="close-button" onClick={onClose}>
              <div className='closeModalImg imgD'></div>
            </button>
          </span>
        </div>

        <div className="modal-content idModal">
          <span className='removeCourses'>
            <span className='headingContEdit'>
              <h4 className='headingAddRem' tabIndex={0}>{literals.InteractiveDashboardComponent.EditList.RemoveHead}</h4>
              <p className='subHeadingAddRem' tabIndex={0}>{literals.InteractiveDashboardComponent.EditList.RemoveSub}</p>
            </span>

            <span tabIndex={0} className='coursesList'>
              {selectedOptions.map((option) => (
                <div className='courseItem' key={option.support_option_id}>
                  <button tabIndex={0} aria-label={option.organization} aria-pressed="false" className='remButton' onClick={() => handleClick(option, "remove")}>
                    <p className='addRemOption'>{option.organization} </p>
                    <span aria-label='remove option icon' className='removeIcon'></span>
                  </button>
                </div>
              ))}
            </span>
          </span>

          <span className='addCourses'>
            <span className='headingContEdit'>
              <h4 className='headingAddRem'>{literals.InteractiveDashboardComponent.EditList.AddHead}</h4>
              <p className='subHeadingAddRem'>{literals.InteractiveDashboardComponent.EditList.AddSub}</p>
            </span>
            <span tabIndex={0} className='coursesList'>
              {deselectedOptions.map((option) => (
                <div className='courseItem' key={option.support_option_id}>
                  <button tabIndex={0} aria-label={option.organization} aria-pressed="false" className='addButton' onClick={() => handleClick(option, "add")}>
                    <p className='addRemOption'>{option.organization} </p>
                    <span aria-label='add option icon' className='addIcon'></span>
                  </button>
                </div>
              ))}
            </span>
          </span>
        </div>

        <div className="modal-footer">
          <button tabIndex={0} aria-label={literals.InteractiveDashboardComponent.EditList.submit} aria-pressed="false" className={`${isDisabled ? 'saveEditListDisabled' : 'saveEditList'}`} onClick={handleProceed} disabled={isDisabled}>
            <span className={`${isDisabled ? 'disabletext' : 'submitText'}`}>
              {literals.InteractiveDashboardComponent.EditList.submit}
            </span>
            <i className={`${isDisabled ? 'greyIcon' : 'whiteIcon'}`}></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditOptions;
