const obj = {
    mainHeading: {
        fontSize: 26,
        bold: true,
        color: "#1dafd8",
        //margin: [7, 7, 0, 7], //[right, top, left, bottom]
      },
      subHeading1Blue: {
        fontSize: 18,
        bold: true,
        color: "#1dafd8",
      },
      subHeading2: {
        fontSize: 12,
        bold: true,
      },
      //Description and Link font size remain same
      description: {
        fontSize: 12,
        bold: false,
        alignment: "justify",
      },
      link: {
        fontSize: 12,
        color: "blue",
        bold: false,
        decoration: "underline",
      },

      defaultStyle: {
        link: "link",
        font: "OpenSansRegular",
      },
    }

    export default obj;