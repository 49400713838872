import { useNavigate, useLocation } from "react-router";
import { useState, useEffect, useRef, useContext } from "react";
import "./CheckYourAnswersStyles.scss";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import BackButton from "../BackButton/BackButton";
import ContinueButton from "../ContinueButton/ContinueButton";
import PageTimeTracking from "../PageTracking/PageTracker";
import { ProgressBar } from '../ProgressBar/ProgressBar';
import { getSummaryService } from "../../services/getSummaryService";
import { CheckYourAnswerContext } from "./Context/CheckYourAnswerContext";
import { useResumeJourney } from "../../customHooks/useResumeJourney";
import '../NeedHelp/FirstComponent/FirstComponent.scss';
import AnswerSubPage from "./AnswerSubPages/AnswerSubPagesComponent";
import Joyride from "react-joyride";
import literals from "../Literals/Literals";

const CheckYourAnswers = (props) => {
  const navigation = useNavigate();
  const location = useLocation();
  const getItemFromHelper = useResumeJourney();
  const prevPage = getItemFromHelper.prevPage;
  const nextPage = getItemFromHelper.nextPage;
  const [run,setRun] = useState(false)
  const supportProgress = localStorage.getItem("SupportProgress");
  const [showHelp,setShowHelp] = useState(false);

  //Data Models
  const topicIds = useRef([]);
  const currentTopicIdIndex = useRef(0);

  //UI reference
  const scrollPanelRef = useRef();

  //UI Updaters
  const [loading, setLoading] = useState(true);

  const { setJourneySummary } = useContext(CheckYourAnswerContext);

  console.log("location: ", location);

  useEffect(() => {
    fetchSummary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const steps = [
    {   target:".answerhelp",
        content:<h2>{literals.help.checkyouranswers.help_checkyouranswers}</h2>,
        placement: 'bottom',
        disableBeacon: true,
    }
]

   const handleShowHelp = () => {
      setRun(true);
  }

  const handleJoyrideCallback = (data) => {
    const { status, index, type } = data;
    const finishedStatuses = ['finished', 'skipped'];

    if (
      finishedStatuses.includes(status) ||
      (type === 'step:after' && index === steps.length - 1)
    ) {
      setRun(false);
    }
  };


  const fetchSummary = async () => {
    const response = await getSummaryService();

    if (!response.data && response.data.length > 0) {
      console.log("Error in Fetching the Journey Summary");
      setLoading(false);
      return;
    }
    setJourneySummary(response.data);
    topicIds.current = response.data.map((item) => item.topicId);
    console.log("Topic ids found from response",topicIds.current)

    const editedImpact = ((location.state && location.state.editedImpact) ? location.state.editedImpact : '');
    const topicId = findTopicId(response.data, editedImpact);
    const topicIdIndex = topicId ? topicIds.current.findIndex((tId) => (tId === topicId)) : -1;

    currentTopicIdIndex.current = topicIdIndex > -1 ? topicIdIndex :
      (location.state && location.state.isLoadLastCategory ? topicIds.current.length - 1 : 0);

    console.log("BACK CHECK: ", editedImpact, topicId , topicIdIndex , currentTopicIdIndex.current);


    updateSubPage();

    setLoading(false);

  };

  const findTopicId = (data, editedImpact) => {

    let isFound = false;
    let topicId = 0;

    data.map((parentObj) => {
      const level1 = parentObj.topics.find((item) => (item.level === 'LVL1'));
      if (level1) {
        const actualObj = level1.nodes.find((node) => (node.id === editedImpact));
        if (actualObj && !isFound) {
          isFound = true;
          topicId = parentObj.topicId;
        }
      }
      return parentObj;
    })
    return topicId;
  }

  const updateSubPage = () => {
    navigation(`/checkyouranswers`);
  };

  const handleContinue = () => {
      navigation(`/${nextPage}`);
  };

  const handleBack = () => {
    if (currentTopicIdIndex.current === 0) {
      console.log(
        "No more prev Main Journey Topics",
        currentTopicIdIndex.current,
        topicIds.current[currentTopicIdIndex.current]
      );
      navigation(`/${prevPage}`);
      return;
    }
    currentTopicIdIndex.current -= 1;
    updateSubPage();
  };

  return (
    <div>
 <Joyride
    steps={steps}
    run={run}
    continuous
    callback={handleJoyrideCallback}
    spotlightClicks={false}
    scrollToFirstStep
    hideBackButton={true}
        locale={{last: 'Close'}}
    styles={{
      options: {
        arrowColor: '#fff',
        backgroundColor: '#e3ffeb',
        overlayColor: 'transparent',
        primaryColor: '#000',
        textColor: '#004a14',
        zIndex: 1000,
        spotlightPadding: 0,
      },
    }}
    />


        <Header  tabIndex={0} className="header" homepage={false} />
        <ProgressBar pageName="needhelp"  progressValueC={100} progressValueI={100}  progressValueS={0} tabIndex={0}/>
        <div className="help_nh" alt="sp_logo" tabIndex={0} onClick={handleShowHelp}><span className="visual">Help Icon</span></div>

      <div className='headContainer'>
            <div className='checkAnswerImage myCir' tabIndex={0} ><span className="visual">Check you answers image</span></div>
            <div>
                <h1 className='headingImpact' tabIndex={0} >Check your answers</h1>
                <p className='descCircumstance' tabIndex={0} >Before we show you some support options, we'd like to check whether you're happy with the impacts you've selected. Click 'edit' if you'd like to make any changes.</p>
            </div>
        </div>

      <div>
        <div
          ref={scrollPanelRef}
        >
          <div className="internal-container">
            <div className="internal-child-1 i-child-width"></div>

            {/* Main content text here >>> */}
            <div className="answerhelp">
            <div className=" greyContainer greyContainer-ca answersContainer">
              <div className="temp-child-1">
                {loading ? (
                  <div className="loader-container">
                    <div className="spinner"></div>
                  </div>
                ) : (
                  topicIds.current.map((topicId,index) => (
                  
                  <AnswerSubPage key={index} topicId={topicId} tabIndex={0}/>
                  
                  ))
                )}
              </div>
            </div>
            </div>

            {/* Bottom content here */}
            <div className="internal-child-3">
              <div className="bottomBtn">
                <BackButton tabIndex={0}
                  onClick={handleBack}
                  label="Back"
                  theme={props.theme}
                />
                
                <ContinueButton tabIndex={0}
                classname="ContinueButton"
                  label="Step 3"                  
                  onClick={handleContinue}
                  disabled={false}
                />
              </div>

              {/* Footer here >>> */}
              <div className="third footer_container">
              <Footer
                screenName="CheckYourAnswers"
                homepage={false}
              />
              </div>
              <PageTimeTracking pageName="CheckYourAnswers" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckYourAnswers;
