import React from "react";
import "./Header.scss";
import { useSelector } from 'react-redux';


export default function HeaderTwo(props) {
  const contactNumber = useSelector((state) => state.clientContactNumber.contactNumber);
  return (
    console.log("Privacy url in consent",),

    <div className="header-Container">
        <div className="headerNew">
          <div className="logoNew">
            <div className="headerNumber"> <span className="visual"> Need Help? Call us at </span>{contactNumber}</div>
             </div>
          <div className="logoSP"></div>
        </div>
    </div>
  );
}