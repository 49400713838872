import React, { useState, useEffect } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import "./CCUSupportOne.scss";
import { useNavigate } from "react-router";
import PageTimeTracking from "../PageTracking/PageTracker";
import BackButton from "../BackButton/BackButton";
import ContinueButton from "../ContinueButton/ContinueButton";
import "../global.scss";
import literals from "../Literals/Literals";
import { useResumeJourney } from "../../customHooks/useResumeJourney";
import Joyride from "react-joyride";
import {
  getSupportOne,
  saveSupportOne,
  loadJourney,
} from "../../services/journeyService";
import { ProgressBar } from "../ProgressBar/ProgressBar";
import '../../themes/SopraTheme/sopra.scss'
import CustomRadioButton from "../CustomRadioButton/CustomRadioButton";
import { useDispatch } from "react-redux";
import { setReviewDate } from "../../redux/actions/reviewDateAction";
import { sanitizeInput } from "../../utils/helper.methods";

export default function CCUSupportOne(props) {
  const navigation = useNavigate();
  const getItemFromHelper = useResumeJourney();
  const prevPage = getItemFromHelper.prevPage;
  const nextPage = getItemFromHelper.nextPage;
  const [isLoading, setIsLoading] = useState(true);
  const [run,setRun] = useState(false)
  const [dataByCategory, setDataByCategory] = useState({});
  const [topicId, setTopicId] = useState("");
  const [selectedText, setSelectedText] = useState("");
  const [reviewTimePeriod, setReviewTimePeriod] = useState("12 months");
  const requestBody = {
    topicId: topicId,
    reviewDuration: "",
  };
  const supportProgress = localStorage.getItem("SupportProgress");
  const dispatch = useDispatch();

  useEffect(() => {
    const loadJourneyCall = async () => {
      const response = await loadJourney(
        "ccusupport-new"
      );
      if (response.data && response.data.length > 0) {
        const { id } = response.data[0];
        if (id) {
          setTopicId(id);
          fetchAllByCategory(id);
        }
      }
    };

    loadJourneyCall();

    const groupDataByCategory = (categories) => {
      return categories.reduce((acc, option) => {
        if (!acc[option.category]) {
          acc[option.category] = [];
        }
        acc[option.category].push(option);
        return acc;
      }, {});
    };

    const fetchAllByCategory = async (id) => {
      try {
        setIsLoading(true);
        const response = await getSupportOne(
          id
        );
        if (response && response.data.categories && response.data.categories.length > 0) {
          const tempReviewDate = response.data.reviewDate;
          console.log("tempReviewDate", tempReviewDate);
          dispatch(setReviewDate(tempReviewDate));
          const groupedData = groupDataByCategory(response.data.categories);
          setDataByCategory(groupedData);
          const otherTextBoxField = groupedData["Suggest how we can help you"]?.find(
            (field) => field.fieldType === "checkboxandtext"
          );
          if (otherTextBoxField && otherTextBoxField.selected) {
            setSelectedText(otherTextBoxField.value);
          }
          if (response.data.reviewDuration) {
            const durationObj = literals.ChoosingSupport.tempReviewPeriod.find((obj) => obj.id === +response.data.reviewDuration);
            if (durationObj) {
              setReviewTimePeriod(durationObj.value);
            }
          }
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };
    // eslint-disable-next-line
  }, []);

  const updateTheReviewdate = async () => {
    try {
      setIsLoading(true);
      const response = await getSupportOne(
        topicId
      );
      if (response && response.data.categories && response.data.categories.length > 0) {
        const tempReviewDate = response.data.reviewDate;
        console.log("tempReviewDate updated", tempReviewDate);
        dispatch(setReviewDate(tempReviewDate));
      }
  }
  catch (error) {
    console.log(error);
  }
};

const steps = [
  {   target:".options-container",
      content:<h2>{literals.help.support.support_Listing}</h2>,
      placement: 'bottom',
      disableBeacon: true,
  },
  {
    target:".otherhelp",
    content:<h2>{literals.help.support.support_Suggestion}</h2>,
      placement: 'bottom',
      disableBeacon: true,
  },
  {
    target:".reviewhelp",
    content:<h2>{literals.help.support.support_Review}</h2>,
      placement: 'bottom',
      disableBeacon: true,
  },
]

 const handleShowHelp = () => {
    setRun(true);
}

const handleJoyrideCallback = (data) => {
  const { status, index, type } = data;
  const finishedStatuses = ['finished', 'skipped'];

  if (
    finishedStatuses.includes(status) ||
    (type === 'step:after' && index === steps.length - 1)
  ) {
    setRun(false);
  }
};


  const handleButtonChange = (category, optionId, $event) => {
    const pressed = $event.target.getAttribute("aria-pressed") === "true";
    $event.target.setAttribute("aria-pressed", !pressed);

    setDataByCategory((prevDataByCategory) => {
      const updatedDataByCategory = { ...prevDataByCategory };
      const options = updatedDataByCategory[category];

      if (options) {
        const optionIndex = options.findIndex(
          (option) => option.id === optionId
        );
        if (optionIndex !== -1) {
          options[optionIndex].selected = !options[optionIndex].selected;
        }
      }
      return updatedDataByCategory;
    });
  };
  const handleContinue = async () => {
    try {
      const updatedOptionList = [];
      for (const categoryKey in dataByCategory) {
        const options = dataByCategory[categoryKey];
        for (const option of options) {
          if (option.selected) {
            if (option.fieldType === "checkboxandtext" && option.selected) {
              updatedOptionList.push({
                id: option.id,
                selected: option.selected,
                value: option.selected ? selectedText : "",
              });
            } else if (option.fieldType !== "checkboxandtext") {
              updatedOptionList.push({
                id: option.id,
                selected: option.selected,
              });
            }
          }
        }
      }
      if (reviewTimePeriod) {
        const tempreviewTimePeriod = literals.ChoosingSupport.tempReviewPeriod.find((obj) => obj.value === reviewTimePeriod)?.id;
        requestBody.reviewDuration = tempreviewTimePeriod;
      }
      setIsLoading(true);
      await saveSupportOne({ ...requestBody, responses: updatedOptionList });
      updateTheReviewdate();
      navigation(`/${nextPage}`);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const handleTextboxChange = (value, category) => {
    value = sanitizeInput(value); 
    const words = value.trim().split(/\s+/);
    if (words.length <= 50) {
      setSelectedText(value);
    } else {
      const truncatedValue = words.slice(0, 50).join(' ');
      setSelectedText(truncatedValue);
    }
    console.log("CHACK IT", value.trim().split(/\s+/).length)
    setDataByCategory((prevDataByCategory) => {
      const updatedDataByCategory = { ...prevDataByCategory };
      const options = updatedDataByCategory[category];
      const optionIndex = options.findIndex(
        (option) => option.name === "Other"
      );
      if (optionIndex !== -1) {
        options[optionIndex].value = value;
        options[optionIndex].selected = value.length > 0;
      }
      console.log("updatedDataByCategory", updatedDataByCategory);
      return updatedDataByCategory;

    });
  }

  const handleRadioButtonChange = (value) => {
    setReviewTimePeriod(value);
  };

  return (
    <div>
      <Joyride
    steps={steps}
    run={run}
    continuous
    callback={handleJoyrideCallback}
    spotlightClicks={false}
    scrollToFirstStep
    hideBackButton={true}
        locale={{last: 'Close'}}
    styles={{
      options: {
        arrowColor: '#fff',
        backgroundColor: '#e3ffeb',
        overlayColor: 'transparent',
        primaryColor: '#000',
        textColor: '#004a14',
        zIndex: 1000,
        spotlightPadding: 0,
      },
    }}
    />

      <div className="head_container">
        <Header tabIndex={0} homepage={false} />
        <ProgressBar tabIndex={0} pageName="ccusupport" progressValueC={100} progressValueI={100} progressValueS={supportProgress ?? 0}/>
        <div className="help_nh" alt="sp_logo" tabIndex={0} onClick={handleShowHelp}><span className="visual">Help Icon</span></div>
      </div>

      <div className='headContainer'>
        <div tabIndex={0} className='thirdImage myCir'>  <span className="visual">Choose support image</span> </div>
        <div>
          <h1 tabIndex={0} className='headingImpact'>{literals.welcome.title3}</h1>
          <p tabIndex={0} className='descCircumstance'>{literals.ChoosingSupport.title}</p>
        </div>
      </div>
      {/* 
      <div className="heading-holder">
        <span className="title-icon imgD" aria-label="Choose Support Image" tabIndex={0} />
        <span className="title-text">
          <h1 className="title-CU" tabIndex={0}>{literals.welcome.title3}</h1>
          <p className="title-para" tabIndex={0}>
            {literals.ChoosingSupport.title}
          </p>
        </span>
      </div> */}

      <div className=" greyContainer ccuContainer">
        {!isLoading ? (
          Object.entries(dataByCategory).length < 1 ? (
            <p tabIndex={0} className="text-content">
              {literals.ChoosingSupport.noData}
            </p>
          ) : (
            <p tabIndex={0} className="main-title">
              {literals.ChoosingSupport.title2}
            </p>
          )
        ) : null}
        {Object.entries(dataByCategory).map(
          ([category, options], index) => (
            <>
              <div key={category}>
                <h2
                  tabIndex={0}
                  className="category-heading"
                >
                  {category}
                </h2>
                <div className="options-container">
                  {Array.isArray(options)
                    ? options.map((option) => (
                      <div
                        key={option.id}
                        className="optionsCheckbox"
                      >
                        {option.fieldType === "checkbox" && (
                          <button
                            key={option.id}
                            className={`optionsFont ${`defaultOption ${option.selected ? 'selectedOption tSelected' : 'tNselected'}`}  `}
                            data-option={option.name}
                            onClick={($event) => handleButtonChange(
                              category,
                              option.id,
                              $event
                            )}
                            aria-pressed="false"
                          >
                            <span className={`button-text`}>{option.name}</span>
                            {option.selected && <span className="selected-icon imgD" />}
                          </button>
                        )}

                        {option.fieldType === "checkboxandtext" && (
                          <input
                            key={option.name}
                            type="text"
                            id="inputField"
                            placeholder="Other (please specify)"
                            className={`textboxOption optionsFont otherhelp`}
                            value={selectedText?.length > 0 ? selectedText : ""}
                            onInput={(e) => handleTextboxChange(e.target.value, category)}
                            maxLength={literals.characterLength}
                          />
                        )}

                      </div>
                    ))
                    : null}
                </div>
              </div>
              {index !== Object.entries(dataByCategory).length - 1 && <div class="newDivider"></div>}
            </>
          )
        )}

        <div class="newDivider"></div>
        <div>
          <h3 tabIndex={0} className="category-heading">
            {literals.ChoosingSupport.tempHeading}
          </h3>
          <p tabIndex={0} className="category-subheading"> {literals.ChoosingSupport.tempPara} </p>
          <div className="options-container">
            {literals.ChoosingSupport.tempReviewPeriod.map((option) => (
              <div className="reviewhelp">
              <CustomRadioButton
                tabIndex={0}
                key={option.id}
                value={option.value}
                isChecked={reviewTimePeriod === option.value}
                onChange={() => handleRadioButtonChange(option.value)}
                color="#176E96"
                ariaChecked={reviewTimePeriod === option.value ? "true" : "false"}
              />
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="bottomBtn">
        <BackButton
          tabIndex={0}
          onClick={() => {
            navigation(`/${prevPage}`, {
              state: {
                isLoadLastCategory: true,
              },
            });
          }}
          label="Back"
          theme={props.theme}
        />
        <ContinueButton
          tabIndex={0}
          label="Continue"
          onClick={handleContinue}
          classname='ContinueButton'
        />
      </div>

      <div className="third footer_container">
        <Footer
          screenName="CCUSupport"
          tabIndex={0}
          className="d-footer"
          theme={props.theme}
          homepage={false}
        />
        <PageTimeTracking pageName="CCUSupport" />
      </div>
    </div>
  );
}
