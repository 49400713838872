import { useEffect } from "react";
import { storageKeys } from "./storageKeys";
//Uncomment below line for local development
//import { APP_WORKFLOW } from "../client/default.network";

function ResumeHelper(screenName){
    useEffect(() => {
        console.log(storageKeys.applicationWorkflow)
    },[])
    //Uncomment this for local development
    // const workflow = APP_WORKFLOW
    // const indexOfCurrentScreen = workflow.split(",").indexOf(screenName);
    // const prevPage = workflow.split(",")[indexOfCurrentScreen-1];
    // const nextPage = workflow.split(",")[indexOfCurrentScreen+1];
    //Comment below three lines for local development
    const indexOfCurrentScreen = localStorage.getItem(storageKeys.applicationWorkflow).split(",").indexOf(screenName);
    const prevPage = localStorage.getItem(storageKeys.applicationWorkflow).split(",")[indexOfCurrentScreen-1];
    const nextPage = localStorage.getItem(storageKeys.applicationWorkflow).split(",")[indexOfCurrentScreen+1];
    console.log(screenName,prevPage,nextPage);
    const prevNextDetails = {
        prevPage:prevPage,
        nextPage:nextPage
    } 
    return prevNextDetails;
}

export default ResumeHelper;