import React, { useState, useEffect } from "react";
import "../../CCUSupportOne/CCUSupportOne.scss";
import "../../global.scss";
import literals from "../../Literals/Literals";
import "./CUSupport.scss";
import {
  getSupportOne,
  loadJourney,
} from "../../../services/journeyService";
import '../../../themes/SopraTheme/sopra.scss'

export default function CUSupport() {
  const [dataByCategory, setDataByCategory] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [topicId, setTopicId] = useState("");
  const [selectedText, setSelectedText] = useState("");
  const clientname = localStorage.getItem("clientName")

  useEffect(() => {
    const loadJourneyCall = async () => {
      const response = await loadJourney(
        "ccusupport-new"
      );
      if (response.data && response.data.length > 0) {
        const { id } = response.data[0];
        if (id) {
          setTopicId(id);
          fetchAllByCategory(id);
        }
      }
    };

    loadJourneyCall();

    const groupDataByCategory = (categories) => {
      return categories.reduce((acc, option) => {
        if (!acc[option.category]) {
          acc[option.category] = [];
        }
        acc[option.category].push(option);
        return acc;
      }, {});
    };

    function filterSelectedOptions(data) {
      const filteredData = {};
   
      for (const category in data) {
        const selectedOptions = data[category].filter(option => option.selected);
        if (selectedOptions.length > 0) {
          filteredData[category] = selectedOptions;
        }
      }
   
      return filteredData;
    }
    
    const fetchAllByCategory = async (id) => {
      try {
        setIsLoading(true);
        const response = await getSupportOne(
          id
        );
        console.log(response.data.categories);
        if (response && response.data.categories && response.data.categories.length > 0) {
          const groupedSelectedData = groupDataByCategory(response.data.categories);
          const filteredSelectedData = filterSelectedOptions(groupedSelectedData);
          console.log(filteredSelectedData)
          console.log("look here", filteredSelectedData)
          setDataByCategory(filteredSelectedData);
          const otherTextBoxField = filteredSelectedData["Suggest how we can help you"]?.find(
            (field) => field.fieldType === "checkboxandtext"
          );
          if (otherTextBoxField && otherTextBoxField.selected) {
            setSelectedText(otherTextBoxField.value);
          }
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };
    // eslint-disable-next-line
  }, []);


  return (
    <div className='ActionClass'>
      <div>
        <p tabIndex={0} className='HeadingClass'>{literals.InteractiveDashboardComponent.headingSS}</p>
         <p tabIndex={0} className='ContentClass'>{literals.InteractiveDashboardComponent.subheadingSS}</p> 
      </div>

      <div >
        {Object.entries(dataByCategory).map(
          ([category, options], index) => (
            <div className="ccuheadopt">
              <div tabIndex={0} key={category}>
                <h2
                  tabIndex={0}
                  className="ccu-support-heading"
                >
                  {options.length > 0 ? category : null}
                </h2>
                <hr className="hrclass"></hr>
                <div className="ccu-options-container">
                  <div className="cuoptionsRender">
                    {Array.isArray(options) ?
                      (options.map((option, idx) => (
                        <React.Fragment key={option.id}>
                          <div className="ccuoptionwhole">
                            <div className="ccuoptionclass">
                              <div tabIndex={0} className="squareclass"></div><p
                                key={option.id}
                                className="ccusupportoption"
                                data-option={option.name}
                              >
                                {option.fieldType === "checkboxandtext" ? option.value : option.name}
                              </p>
                            </div>
                          </div>
                          {(idx + 1) % 4 === 0 && (idx + 1 !== options.length) && <div className='dividerID'></div>}
                        </React.Fragment>
                      )))
                      : null}
                  </div>
                </div>
              </div>
              {/* {index !== Object.entries(dataByCategory).length - 1 && <div class="newDivider"></div>} */}
            </div>
          )
        )}
      </div>
    </div>
  );
}
