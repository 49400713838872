export const NEW_TABS = {
    privacyNotice:'privacyNotice',
    aboutService:'aboutService',
    giveFeedback:'giveFeedback',
    cookiePolicy:'cookiePolicy'
}

export const BROWSER_TYPE = {
    CHROMEAGENT:'chrome',
    SAFARIAGENT:'safari',
    IEXPLORERAGENT:'iexplorer',
    OPERAAGENT:'opera',
    FIREFOXAGENT:'firefox'
}