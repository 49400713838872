import React from 'react';
import "../../global.scss";
import '../../../themes/SopraTheme/sopra.scss';
import "../LocationSpecific.scss";

const OptionButtonPostcode = ({ colorClass, option, handleButtonClick }) => {
  return (
    <div className="buttonCont" key={option.id}>
      <button
        tabIndex={0}
        aria-selected={option.selected}
        className={`button ${option.selected ? `buttonSelected ${colorClass}` : 'buttonUnselected'}`}
        onClick={() => handleButtonClick(option.support_option_id, !option.selected, option.source)}
      >
        <div className="closeBtnCont">
          <div className="orgHead">{option.organization}</div>
                    {/* {option.selected && <span className="selected-icon imgD" />} */}
        </div>
        <p className="supportDesc">{option.description}</p>
      </button>
    </div>
  );
};

export default OptionButtonPostcode;
