import React, { useRef } from 'react';
import ExitButton from '../ExitButton/ExitButton';
import './ProgressBar.scss';
import { useNavigate } from 'react-router';
import { storageKeys } from '../../utils/storageKeys';

export const ProgressBar = (props) => {
  console.log("this is it", props);
  const PBar = ({ progress, isFirstBar }) => {
    return (
      <div className="parentDiv">
        <div className="childDiv" style={{ width: `${progress}%` }}></div>
        {(progress !== 0) &&<span className="progressText">{`${progress}%`}</span>}
      </div>
    );
  };

  const tabIndex = props.tabIndex;
  const navigate = useNavigate();
  const enableImpact = useRef(0);
  const enableSupport = useRef(0);
  const progressValueC = Math.ceil(props.progressValueC);
  const progressValueI = Math.ceil(props.progressValueI);
  const progressValueS = Math.ceil(props.progressValueS);

  function saveItem(key, result) {
    localStorage.setItem(key, result)
  }

  function getBooleanItem(key) {
    return (localStorage.getItem(key) || "false").toLocaleLowerCase() === "true"
  }

  const handleCircumstances = () => {
    saveItem(storageKeys.comingBackFromThankYou, false)
    navigate('/needhelp')
    console.log("Circumstances");
  }

  const handleImpacts = () => {
    enableImpact.current = getBooleanItem(storageKeys.reachedStepTwo);
    if (enableImpact.current) {
      navigate('/impacts')
      console.log("Impacts");
    }
  }

  const handleSupport = () => {
    enableSupport.current = getBooleanItem(storageKeys.reachedSupport);
    if (enableSupport.current) {
      navigate('/ccusupport')
      console.log("Support");
    }
  }

  const considerKeyEvent = (e) => {
    return (e.code === "Enter" || e.code === "Space");
  }


  return (
    <div className="tableStyle">

<div> </div>

      <div className="progresssBar">



{/* ------------------------------------------------------------ */}

      <div className='divStat'>    
<div tabIndex={tabIndex} onClick={handleCircumstances} onKeyDown={(e) => { if (considerKeyEvent(e)) { handleCircumstances() } }} className="pCursor">Circumstances</div> 
  <div className="barSet pCursor" onClick={handleCircumstances} onKeyDown={(e) => { if (considerKeyEvent(e)) { handleCircumstances() } }}>
                <PBar progress={progressValueC} isFirstBar={true}/>
              </div>

</div>




{/* ------------------------------------------------------------ */}

<div className='divStat'>  
 <div tabIndex={tabIndex} onClick={handleImpacts} onKeyDown={(e) => { if (considerKeyEvent(e)) { handleImpacts() } }} className={`pCursor ${(progressValueI === 0)? 'greytext': ''}`}>Impacts</div> 
<div className="barSet pCursor" onClick={handleImpacts} onKeyDown={(e) => { if (considerKeyEvent(e)) { handleImpacts() } }}>
                <PBar progress={progressValueI} isFirstBar={false} />
              </div>
</div>

{/* ------------------------------------------------------------ */}

<div className='divStat'>  <div tabIndex={tabIndex} onClick={handleSupport} onKeyDown={(e) => { if (considerKeyEvent(e)) { handleSupport() } }} className={`pCursor ${(progressValueS === 0)? 'greytext': ''}`}>Support</div>

 <div className="barSet pCursor" onClick={handleSupport} onKeyDown={(e) => { if (considerKeyEvent(e)) { handleSupport() } }}>
                <PBar progress={progressValueS} isFirstBar={false}/>
              </div> 

 </div>




{/* ------------------------------------------------------------ */}

      </div>
      <div className='exitLaptopPB'>
        {/* <ToggleSwitch /> */}
        <ExitButton />
      </div>
    </div>
  );
};
