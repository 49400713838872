export const storageKeys = Object.freeze({
    tokenGenerated:"TOKEN_GENERATED",
    loadJourney: "LOAD_JOURNEY",
    forwardJourney: "FORWARD_JOURNEY",
    isComingFromImpact:'isComingFromImpact',
    impactJourney : "IMPACT_JOURNEY",
    currentPageCircumstances: "CURRENT_PAGE_CIRCUMSTANCE",
    comingBackFromThankYou: "IS_COMING_BACK_FROM_THANK_YOU",
    comingBackFromImpact: "IS_COMING_BACK_FROM_IMPACT",
    signpostSelections: "SIGNPOST_SELECTIONS",
    reachedStepTwo:"REACHED_STEP_TWO",
    reachedSupport:"REACHED_SUPPORT",
    applicationWorkflow:"APP_WORKFLOW",
    isComingBackFromThankYouTwo:"COMING_FROM_THANKYOU_TWO",
    allSelectedByCheck : "ALL_SELECTED_BY_CHECK",
    resumedJourney:"RESUMED_JOURNEY",
    selectedCircumstances:"SELECTED_CIRCUMSTANCES"
})