import React, { useEffect, useRef, useState } from 'react';
import Joyride from "react-joyride";
import { ProgressBar } from '../ProgressBar/ProgressBar';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import literals from '../Literals/Literals';
import BackButton from '../BackButton/BackButton';
import ContinueButton from '../ContinueButton/ContinueButton';
import { useResumeJourney } from "../../customHooks/useResumeJourney";
import { useNavigate } from "react-router";
import FirstComponent from './FirstComponent/FirstComponent';
import { NeedHelpSecond } from './SecondComponent/SecondComponent';
import { forwardJourney, loadJourney, moveForwardJourney } from '../../services/journeyService';
import { saveCategoryService } from '../../services/saveCategoryService';
import { storageKeys } from '../../utils/storageKeys';
import PageTimeTracking from "../PageTracking/PageTracker";

const NeedHelpComponent = () => {
  const [progressBarValue, setProgressBarValue] = useState(0);
  const [run, setRun] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [totalNumPages, setTotalNumPages] = useState(0);
    // eslint-disable-next-line no-unused-vars
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoadPage, setIsLoadPage] = useState(true);
  const [optIndex, setOptIndex] = useState(0);
  const navigation = useNavigate();
  const getItemFromHelper = useResumeJourney();
  const prevPage = getItemFromHelper.prevPage;
  const nextPage = getItemFromHelper.nextPage;
  const buttonText = "Back";
  const buttonTextContinue = "Continue";
  const [myMap, setMyMap] = useState(new Map());
  const prevMapRef = useRef(new Map());
  const naValue = useRef(false);
  const [secondHelp, setSecondHelp] = useState(false);
  const [firstHelp, setFirstHelp] = useState(true);
  const [data, setData] = useState({
    optionSelected: '?',
    loadPage: '?',
  })
  const [classNameContinue, setClassNameCont] = useState(null);

  const loadJourneyApi = () => {
    return loadJourney();
  };

  const progressBarCalculation = (pageIndex, totalPages) => {
    console.log("Progress bar", totalPages, pageIndex)
    if (pageIndex === -1) {
      console.log("First case")
      setProgressBarValue(localStorage.getItem("PBN"))
    } else {

      if (totalPages !== undefined) {
        console.log("Second case")
        const val = Math.round((pageIndex / totalPages) * 100)
        setProgressBarValue(val)
        if (val !== 0) localStorage.setItem("PBN", val)
      }
      else {
        console.log("Third case")
        const val1 = Math.round((pageIndex / totalNumPages) * 100)
        setProgressBarValue(val1)
        if (val1 !== 0) localStorage.setItem("PBN", val1)
      }
    }
  }

  const calculateProgressBarOnFirstRender = async () => {
    const response = await loadJourneyApi();
      console.log("Journey options", response.data);
          const JOLength = response.data.length;
          const selectedCount = response.data.filter(item => item.selected).length;
          console.log("Total options:", JOLength, "Selected options:", selectedCount);
          if (selectedCount <= JOLength) {
            progressBarCalculation(selectedCount, JOLength);
          }
          if(selectedCount === JOLength){
            localStorage.setItem("ReachedOptionComponent", "false");
          }
  }

  useEffect(() => {
    if (localStorage.getItem("PBN")) {
      progressBarCalculation(-1, 100);
    }
    calculateProgressBarOnFirstRender();
    
    // eslint-disable-next-line
  }, [])

  // Update the previous Map whenever myMap changes
  useEffect(() => {
    prevMapRef.current = myMap;
    console.log("Previous state", prevMapRef.current);
  }, [myMap]);
    
    useEffect(() => {
      console.log("USE EFFECT FOR LOCAL STORAGE RAN")
      if(isLoadPage){
        setSecondHelp(false);
        setFirstHelp(true);
        const keys = Object.keys(localStorage);
        console.log("Local storage keys",keys);
        const updatedMap = new Map(myMap);
        keys.forEach((key) => {
          const storedValue = localStorage.getItem(key);
          updatedMap.set(key, storedValue);
        });
        setMyMap(updatedMap)
      }
      console.log("Local storage data",myMap)
      // eslint-disable-next-line
    },[isLoadPage])

  //--------------Calling move forward circumstances----------------//
  const moveForward = async (result, naVal) => {
    console.log("Data", data, "NA VALUE", naVal)
    const forwardParam = {
      "relationPath": data.optionSelected.id,
      "parentRelationPath": "",
      "response": result,
      "screenName": "NeedHelp-Vulnerabilities",
      "endOfJourney": true,
      "notapplicable": naVal
    }

    const response = await moveForwardJourney(forwardParam);
    console.log(response);
  }
  //----------------Calling forward API-------------------//
  const moveForwardApi = async (result) => {
    console.log("Data", data)
    const forwardParamFA = {
      "relationPath": data.optionSelected.id,
      "parentRelationPath": "",
      "response": result,
      "screenName": "NeedHelp-Vulnerabilities",
      "endOfJourney": true
    }

    const response = await forwardJourney(forwardParamFA);
    console.log(response);
  }

  const saveCategoryFunction = async () => {
    const getJourneyOptions = localStorage.getItem("journeyOptions");
    console.log("Journey options from local storage in save category function:", getJourneyOptions);
    const updatedOptions = JSON.parse(getJourneyOptions).map((opt) => {
      if (opt.id === data.optionSelected.id && !data.optionSelected.selected) {
        return { ...opt, selected: !opt.selected };
      }
      return opt;
    });
    const responseCreation = updatedOptions.map(item => ({
      id: item.id,
      selected: item.selected
    }));
    console.log("Response creation", responseCreation);
    const request = {
      responses: responseCreation
    };
    const responseSave = await saveCategoryService(request);
    localStorage.setItem("journeyOptions", JSON.stringify(updatedOptions));
    console.log(responseSave.status === 200)
    if (responseSave.status === 200) {
      const response = await loadJourneyApi();
      console.log("Journey options", response.data);
          const JOLength = response.data.length;
          const selectedCount = response.data.filter(item => item.selected).length;
          console.log("Total options:", JOLength, "Selected options:", selectedCount);
          if (selectedCount <= JOLength) {
            progressBarCalculation(selectedCount, JOLength);
          }
    }
  };

  const handleContinue = async () => {
    prevMapRef.current = myMap;
    console.log(prevMapRef.current);
  
    const keysWithSelectedId = Object.keys(localStorage).filter(key => key.startsWith(`${data.optionSelected.id}_V`));
    const keysWithNA = Object.keys(localStorage).filter(key => key.includes("NA"));
    console.log("KEYS WITH NA", keysWithNA);
  
    if (isLoadPage) {
      const keyForOther = Object.keys(localStorage).filter(key => key.includes("Other"));
      const keyForNAOther = keysWithNA.length + keyForOther.length;
      const lengthOfJO = JSON.parse(localStorage.getItem("journeyOptions")).length;
      console.log("Keys with NA count OTHERRRRRR", keysWithNA.length, keysWithNA, lengthOfJO, keyForOther.length, keyForOther, keyForOther.length === lengthOfJO);
  
      if (keysWithNA.length < lengthOfJO && keyForOther.length < lengthOfJO && keyForNAOther !== lengthOfJO && localStorage.getItem("ReachedOptionComponent") === "false") {
        navigation(`/${nextPage}`);
      } else if ((keyForOther.length > 0 && keyForOther.length === lengthOfJO) || (keysWithNA.length < lengthOfJO && keyForNAOther === lengthOfJO)) {
        const bool = localStorage.getItem("AnyOptionSelected") === "true";
        console.log("ANY OPTION SELECTED", bool);
        navigation(!bool ? "/exitpage" : `/${nextPage}`, { replace: !bool });
      } else {
        console.log("Show NAFM page");
        localStorage.setItem("ReachedOptionComponent", "true");
        Object.keys(localStorage).filter(key => !key.includes(storageKeys.applicationWorkflow) && !key.includes("journeyId") && !key.includes("ReachedOptionComponent") && !key.includes("journeyOptions")).forEach(key => localStorage.removeItem(key));
        setIsLoadPage(true);
        navigation("/options");
      }
    } else {
      const keyForForwardApi = Object.keys(localStorage).filter(key => key === (`${data.optionSelected.id}`));
      const keyForOther = Object.keys(localStorage).filter(key => key.includes(`${data.optionSelected.id}_Other`));
      console.log("Keys from local storage", keysWithSelectedId, keyForForwardApi);
      console.log("Keys for other", keyForOther, keyForOther.length);
  
      const selectedIds = keysWithSelectedId.flatMap(key => {
        const listData = JSON.parse(localStorage.getItem(key));
        console.log("LIST DATA", listData);
        return listData.filter(item => item.selected).map(item => ({ id: item.id, value: item.fieldValue }));
      });
  
      const selectedIdsForForwardApi = keyForForwardApi.flatMap(key => {
        const listDataFA = JSON.parse(localStorage.getItem(key));
        console.log(listDataFA);
        return listDataFA.filter(item => item.selected).map(item => item.id);
      });
      console.log(selectedIdsForForwardApi, selectedIds, keysWithNA);
      console.log("Filtered keys for NA", (keysWithNA.filter(key => key.includes(`${data.optionSelected.id}`))).length);
      const lengthOfNA = keysWithNA.filter(key => key.includes(`${data.optionSelected.id}`)).length;
      const result = lengthOfNA > 0 ? [] : selectedIds;
      console.log("LENGTH OF NAAAAAAA", lengthOfNA);
      naValue.current = lengthOfNA > 0;
  
      const resultForForwardApi = selectedIdsForForwardApi.map(item => {
        return {
          "id": item,
          "value": (item === "VZOther" && keyForOther.length > 0) ? localStorage.getItem(keyForOther).replace(/"/g, '') : ""
        };
      });
  
      console.log("Result for forward API", resultForForwardApi);
  
      await moveForwardApi(resultForForwardApi); // Ensure this completes first
      await moveForward(result, naValue.current); // Then call this
      console.log("Keys with selected id", keysWithSelectedId);
      localStorage.setItem("AnyOptionSelected", keysWithSelectedId.length > 0);
      localStorage.setItem("ReachedOptionComponent", "false");
      keysWithSelectedId.forEach(key => localStorage.removeItem(key));
      keyForForwardApi.forEach(key => localStorage.removeItem(key));
      await saveCategoryFunction();
    }
  
    setIsLoadPage(true);
  }
  


  const handleBack = () => {
    //-----------------------------------------------------------If clicked on back then save previous selections of 2a layer-----------------------------------------//
    if (isLoadPage) {
      navigation(`/${prevPage}`);
    }
    else {
      // Object.keys(localStorage).filter(key => !key.includes(storageKeys.applicationWorkflow) && !key.includes("journeyId") && !key.includes("ReachedOptionComponent") && !key.includes("journeyOptions") && !key.includes(`${data.optionSelected.id}_NA`)).forEach(key => localStorage.removeItem(key))
      localStorage.clear();
      [...prevMapRef.current].forEach(([key, value]) => {
        localStorage.setItem(key, value);
      });
      console.log("Previous Map", ([...prevMapRef.current]));
      setIsLoadPage(true);
    }
  }

  useEffect(() => {
    console.log(isLoadPage, isDisabled);
  }, [isLoadPage, isDisabled])

  const sendData = (data) => {
    setData(data)
    console.log("Data received", data);
    setIsLoadPage(data.loadPage);
    setOptIndex(data.index);

  }

  const setContinue = (isDisabledcheck) => {
    setIsDisabled(isDisabledcheck);
  }

  const setHelp = (value) => {
    setSecondHelp(value);
    setFirstHelp(!value);
  }
  const steps = [
    {
      target: ".headerphonehelp",
      content: <p>{literals.help.needhelp.needhelp_Number}</p>,
      placement: 'left',
      disableBeacon: true,
    },
    {
      target: ".progresshelp",
      content: <p>{literals.help.needhelp.needhelp_Progress}</p>,
      placement: 'bottom',
      disableBeacon: true,
    },
    {
      target: ".helpText-0",
      content: <p>{literals.help.needhelp.needhelp_category}</p>,
      placement: 'bottom',
      disableBeacon: true,
    },
    {
      target: ".buttonhelp",
      content: <p>{literals.help.needhelp.needhelp_Circumstances}</p>,
      placement: 'bottom',
      disableBeacon: true,
    },
    {
      target: ".helpContinue",
      content: <p>{literals.help.needhelp.needhelp_Continue}</p>,
      placement: 'top',
      disableBeacon: true,
    }
  ]

  const handleShowHelp = () => {
    setRun(true);
  }

  const handleJoyrideCallback = (data) => {
    const { status, index, type } = data;
    const finishedStatuses = ['finished', 'skipped'];

    if (
      finishedStatuses.includes(status) ||
      (type === 'step:after' && index === steps.length - 1)
    ) {
      setRun(false);
    }
  };

  const setClassNameContinue = (classNameHere) => {
    setClassNameCont(classNameHere);
  }

  return (

    <div>
      <Joyride
        steps={steps}
        run={run}
        continuous
        callback={handleJoyrideCallback}
        spotlightClicks={false}
        scrollToFirstStep
        hideBackButton={true}
        locale={{last: 'Close'}}
 
        styles={{
          options: {
            arrowColor: '#fff',
            backgroundColor: '#e3ffeb',
            overlayColor: 'transparent',
            primaryColor: '#000',
            textColor: '#004a14',
            zIndex: 1000,
            spotlightPadding: 0,
          },
        }}
      />
      <div className={secondHelp === true ? "" : 'headerphonehelp'}>
        <Header tabIndex={1} homepage={false} />
      </div>
      <div className={secondHelp === true ? "" : "progresshelp"}>
        <ProgressBar pageName="needhelp" progressValueC={progressBarValue} progressValueI={0} progressValueS={0} tabIndex={0} />
      </div>
      <div className="help_nh" alt="sp_logo" tabIndex={0} onClick={handleShowHelp}><span className="visual">Help Icon</span></div>

      {
        isLoadPage
          ?
          <div className={secondHelp ? "" : 'needhelp'}>
            <FirstComponent sendData={sendData} setContinue={setContinue} />
          </div>
          :
          <NeedHelpSecond setClassName={setClassNameContinue} optionSelected={data.optionSelected} optIndex={optIndex} setContinue={setContinue} showHelp={run} setHelp={setHelp} />
      }
      <div className="bottomBtn">
        <BackButton label={buttonText} onClick={handleBack} className="float-start" />
        <div>
          <ContinueButton classname={`${classNameContinue} ${isDisabled ? "ContinueButtonDisabled" : "ContinueButton"}`} label={buttonTextContinue} disabled={isDisabled} onClick={handleContinue} float-end />
        </div>
      </div>
      <div className='third footer_container'>
        <Footer homepage={false} />
        <PageTimeTracking pageName="needhelp" />
      </div> </div>)
}

export default NeedHelpComponent