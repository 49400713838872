
import React, {
  useState,
  useEffect,
  useContext,
  useRef
} from 'react';
import './App.scss';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import Router from './routes/Router';
import {
  ExitContext
} from './contexts/exit.context';
import { storageKeys } from './utils/storageKeys';
import { userAuthService } from './services/userAuthService';
import { resumeJourney, startJourney } from './services/journeyService';
import { subscribe, unsubscribe } from './utils/events/events';
import { EVENT_EXIT } from './utils/events/events.name';
import { checkBrowser, redirectGoogleOrClose } from './utils/helper.methods';
import { BROWSER_TYPE } from './utils/constant';
import store from './redux/store';
import { Provider } from 'react-redux';
import { getPrivacyUrlService } from './services/getPrivacyUrlService';
import { getUserIdService } from './services/getUserIdService';
import { useDispatch } from "react-redux";
import { setUserId } from './redux/actions/userActions';
import { setRealTimeUserJourney } from './redux/actions/realTimeUserJourneyAction';

const App = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [theme, setTheme] = useState('ccu');
  const [clientNotifService, setClientNotifService] = useState(false);
  const sastoken = useRef(null);
  const screenName = useRef(null);
  const screenData = useRef(null);
  const navigation = useNavigate();
  const userIdFromUrl = useRef(null);
  const privacyUrlFromResponse = useRef(null);
  const tncUrlfromResponse = useRef(null);
  const deviceType = useRef(null);
  const userContactNumber = useRef(null);
  const screenForNeedHelp = useRef("");
  const mobileScreen = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

  const {
    closeAllTabs,
    isExitClicked
  } = useContext(ExitContext);

  const handleMessage = (event) => {
    let message = event.data;
    console.log('Received message:', event);

    // Check if the message contains the expected SAS token prefix
    if (typeof message === 'string' && message.startsWith("sv")) {
      try {
        const decodedSpUId = decodeURIComponent(message);
        console.log("Decoded SpUId: ", decodedSpUId);
        sastoken.current = decodedSpUId;
        proceedApp(decodedSpUId);
        window.removeEventListener("message", handleMessage);
      } catch (e) {
        console.error("Failed to decode message:", e);
      }
    }
    else{
      console.error("SAS token not received. Performing fallback.");
      navigation("/errorpage");
    }
  };

  useEffect(() => {
    const handleAllTabsClosing = () => {
      closeAllTabs();
    };
    
    const onExitEvent = () => {
      console.log('EVENT EXIT FIRE');
      localStorage.clear();
      isExitClicked.current = true;
      handleAllTabsClosing();
    };
  
    // Add event listeners
    window.addEventListener('unload', handleAllTabsClosing);
  
    // Subscribe to global exit events
    subscribe(EVENT_EXIT, onExitEvent);
  
    // Logging information on component mount
    const currentURL = new URL(window.location.href);
    console.log('APP MOUNT CURRENT URL: ', currentURL);
    console.log("Document Referrer: ", document.referrer);
    console.log("Device Pixel Ratio: ", window.devicePixelRatio);
    console.log("Window Width: ", window.innerWidth);
    console.log("Window Height: ", window.innerHeight);
  
    // Detect if device is mobile
    const isMobile = () => {
      const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
      return regex.test(navigator.userAgent);
    };
  
    deviceType.current = isMobile() ? "Mobile" : "Desktop";
    console.log(`${deviceType.current} device detected`);
  
    // Cleanup listeners on unmount
    return () => {
      window.removeEventListener('unload', handleAllTabsClosing);
      window.removeEventListener("message", handleMessage);
      unsubscribe(EVENT_EXIT, onExitEvent);
    };
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.opener?.postMessage("Ready to listen", window.location.href);
    setTimeout(() => { window.addEventListener("message", handleMessage); }, 250);
  }, [])
  

  useEffect(() => {
    console.log(screenName.current)
  }, [screenName,privacyUrlFromResponse, tncUrlfromResponse]);

 

  const proceedApp = (decodedSpUId) => {
    const pathName = location.pathname;
    if (pathName !== '/privacynotice' && pathName !== '/CookiePolicy' && pathName !== '/feedback-text/footer' && pathName !== '/termsandconditions') {
      localStorage.clear();
    }
    if(decodedSpUId !== null && decodedSpUId !== ''){
      fetchData(pathName);
    }
    else{
      navigation("/errorpage");
      console.log("Sas token is empty");
    }
  }

  const fetchData = async (pathName) => {
    if (pathName !== '/') return;
  
    try {
      // Fetch user ID
      const userIdResponse = await getUserIdService(sastoken.current);
      console.log("User ID Response:", userIdResponse);
      const userId = userIdResponse.data.user_id;
      userIdFromUrl.current = userId;
      dispatch(setUserId(userId));
  
      // Fetch privacy URLs
      const responsePrivacy = await getPrivacyUrlService();
      const { client_privacy_notice_url, client_tnc_url } = responsePrivacy.data;
  
      // Set privacy and TNC URLs
      if (!client_privacy_notice_url && !client_tnc_url) {
        privacyUrlFromResponse.current = "/privacynotice";
        tncUrlfromResponse.current = "/termsandconditions";
      } else {
        privacyUrlFromResponse.current = client_privacy_notice_url;
        tncUrlfromResponse.current = client_tnc_url;
      }
  
      console.log("Privacy URL:", privacyUrlFromResponse.current);
      console.log("TNC URL:", tncUrlfromResponse.current);
  
      // Fetch user auth data
      const responseAuth = await userAuthService();
      const { client_data_notification_service_required, client_name, client_contact_number, client_theme_name } = responseAuth.data;
  
      // Update state and local storage
      setClientNotifService(client_data_notification_service_required);
      dispatch(setRealTimeUserJourney(client_data_notification_service_required));
      localStorage.setItem("clientName", client_name);
      userContactNumber.current = client_contact_number;
      setTheme(client_theme_name);
  
      // Fetch journey data
      await fetchJourney();
  
      console.log("Contact Number in response:", userContactNumber.current);
      console.log("Theme:", client_theme_name);
  
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  async function fetchJourney() {
    try {
      const response = await startJourney({});
      localStorage.setItem("journeyId", response.data.userJourneyId);
      try {
        const responseRJ = await resumeJourney();
        console.log(responseRJ.data);
        console.log("Application workflow", responseRJ.data.applicationWorkflow)
        const applicationWorkflow = responseRJ.data.applicationWorkflow.map(item => item.toLocaleLowerCase());
        localStorage.setItem(storageKeys.applicationWorkflow, applicationWorkflow);
        const screenNameFromRJ = (responseRJ.data.lastScreenName).toLocaleLowerCase();
        screenName.current = screenNameFromRJ.toString();
        screenForNeedHelp.current = screenName.current;
        screenData.current = responseRJ.data.lastScreenNameData;
        console.log(screenData.current);
        console.log(responseRJ.data.lastScreenNameData);
        console.log("Screenname", screenName.current);
        if (screenName.current !== "home") {
          localStorage.setItem(storageKeys.resumedJourney, true);
        }
        if (screenName.current === "needhelp-vulnerabilities" || screenName.current === "needhelp-categories") {
          screenName.current = "needhelp";
        }
        if (screenName.current === "demographic-consent") {
          screenName.current = "outputscreen";
        }
        navigation(`/${screenName.current}`);
      } catch (error) {
        console.log(error);
      }
    }
    catch (error) {
      console.log(error);
    }
  };

  window.setParentExitTool = (exit) => {
    if (exit) {
      redirectGoogleOrClose(window, "APP PARENT");
      isExitClicked.current = true;
    }
  };

  const browserType = checkBrowser();
  const privacyUrlToPass = privacyUrlFromResponse.current;
  const tncUrlToPass = tncUrlfromResponse.current;
  const contactNumber = userContactNumber.current;

  console.log("Passed url",privacyUrlToPass);
  useEffect(() => {
    {localStorage.getItem('theme') && setTheme(localStorage.getItem('theme'))};
  },[]);
  console.log("Passed theme",theme);

  return (
    <Provider store={store}>
      <div className={`${theme} ${((browserType === BROWSER_TYPE.SAFARIAGENT) && mobileScreen) ? 'mobileSafariContainer' : 'h-screen'}`}>
        <Router clientNotifService={clientNotifService} theme={theme} screenData={screenData} screenName={screenForNeedHelp} userId={userIdFromUrl.current} privacyUrl={privacyUrlToPass} tncUrl={tncUrlToPass} deviceType={deviceType} contactNumber={contactNumber}/>
      </div>
    </Provider>
  );
};

export default App;
