import React, { useContext, useEffect } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import literals from "../Literals/Literals";
import { ScrollPanel } from "primereact/scrollpanel";
import ExitButton from "../ExitButton/ExitButton";
import { ExitContext } from "../../contexts/exit.context";
import "../global.scss";
import "./PrivacyNotice.scss";

function PrivacyNotice() {
  const themeselected = localStorage.getItem("theme");
  const { setPrivacyNoticeExit, isChildNewTab } = useContext(ExitContext);

  useEffect(() => {
    isChildNewTab.current = true;
    setPrivacyNoticeExit(window);
    console.log("value of childtab ref", isChildNewTab.current)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="PN-parent-container-2">
      {/*  Header */}
      <div className="top-container-2">
        <Header tabIndex={0} homepage={false} />
        {/* <ProgressBarComponent pageName="feedback" /> */}
   <div className="buttonContainerTop"> 
   <div className="fixExitButton">
      <ExitButton tabIndex={0} />
    </div>
   </div>
        <div className="headContPC">
          <hr className="divider dividerFix privacyFix" />
          <h2 tabIndex={0} className="heading heading-noScroll headContPC">
            {" "}
            {literals.PrivacyNotice.heading}
          </h2>
          <h2 className="heading heading-noScroll headContPC" tabIndex={0}>{literals.PrivacyNotice.PrivacyNotice}</h2>
          <hr className="divider dividerFix headingBottom" />
        </div>
      </div>

      <div className="bottom-container-2">
        <ScrollPanel
          pt={{ root: { style: { width: "100%", height: "100%" } } }}
        >
          <div className="internal-container centerIt">
            <div className="PN-internal-child-1  i-child-width">
              {/* <div className="div-content"> */}
              <p tabIndex={0} className="text-content justify-text pn-hsmall"> {literals.PrivacyNotice.para1} </p>
              <p tabIndex={0} className="text-content justify-text pn-hsmall"> {literals.PrivacyNotice.para2} </p>
              <h3 tabIndex={0} className="heading subheadingPrivacy">{literals.PrivacyNotice.sub1}</h3>
              <p tabIndex={0} className="text-content justify-text pn-hsmall"> {literals.PrivacyNotice.subpara1} </p>
              <StaticTable tabIndex={0}></StaticTable>
              <p tabIndex={0} className="text-content justify-text pn-hsmall"> {literals.PrivacyNotice.subpara2} </p>
              <p tabIndex={0} className="text-content justify-text pn-hsmall"> {literals.PrivacyNotice.subpara3} </p>
              <h3 tabIndex={0} className="heading subheadingPrivacy">{literals.PrivacyNotice.sub2}</h3>
              <p tabIndex={0} className="text-content justify-text pn-hsmall"> {literals.PrivacyNotice.sub2para1}</p>
              <p tabIndex={0} className="text-content justify-text pn-hsmall"> {literals.PrivacyNotice.sub2para2} </p>
              <h3 tabIndex={0} className="heading subheadingPrivacy">{literals.PrivacyNotice.sub3}</h3>
              <p tabIndex={0} className="text-content justify-text pn-hsmall"> {literals.PrivacyNotice.sub3para1} </p>
              <p tabIndex={0} className="text-content justify-text pn-hsmall"> {literals.PrivacyNotice.sub3para2} </p>
              <p tabIndex={0} className="text-content justify-text pn-hsmall"> {literals.PrivacyNotice.sub3para3} </p>
              {literals.PrivacyNotice.sections.map((section, index) => (
                <div key={index}>
                  <h3 tabIndex={0} className="heading subheadingPrivacy">
                    {section.title}
                  </h3>
                  <div className="aligntext">
                    {section.content}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </ScrollPanel>
        <div className="internal-child-3 fixFooter">
                <Footer tabIndex={0} theme={themeselected} homepage={true} />
              </div>
      </div>
    </div>
  );
}

function StaticTable() {
  return (

    
    
    <table >

      <tr>

        <td>

              
              
<div className="divFlex">   
<div tabIndex={0} className="tr-heading w20"> {literals.PrivacyNotice.subtableHeadings[0]} </div>
<div tabIndex={0} className="tr-row"> {literals.PrivacyNotice.subtablecontent[0]} </div>
</div>

<div className="divFlex">   
<div tabIndex={0} className="tr-heading w20"> {literals.PrivacyNotice.subtableHeadings[1]} </div>
<div tabIndex={0} className="tr-row"> {literals.PrivacyNotice.subtablecontent[1]}  </div>
</div>

<div className="divFlex">   
<div tabIndex={0} className="tr-heading w20"> {literals.PrivacyNotice.subtableHeadings[2]} </div>
<div tabIndex={0} className="tr-row">   {literals.PrivacyNotice.subtablecontent[2]} </div>
</div>

<div className="divFlex">   
<div tabIndex={0}  className="tr-heading w20">{literals.PrivacyNotice.subtableHeadings[3]} </div>
<div tabIndex={0}  className="tr-row"> {literals.PrivacyNotice.subtablecontent[3][0]}  <br/>  {literals.PrivacyNotice.subtablecontent[3][1]}  <br/>   {literals.PrivacyNotice.subtablecontent[3][2]}   {literals.PrivacyNotice.subtablecontent[3][3]}
          {literals.PrivacyNotice.subtablecontent[3][4]}  <br/>  {literals.PrivacyNotice.subtablecontent[3][5]} </div>
</div>

<div  className="divFlex">   
<div  tabIndex={0} className="tr-heading w20"> {literals.PrivacyNotice.subtableHeadings[4]} </div>
<div tabIndex={0} className="tr-row"> {literals.PrivacyNotice.subtablecontent[4]}</div>
</div>



        </td>
      </tr>
      {/* <tr className="tr-heading">
        <td tabIndex={0} className="td-text"> {literals.PrivacyNotice.subtableHeadings[0]}</td>
        <td tabIndex={0} className="td-text"> {literals.PrivacyNotice.subtableHeadings[1]}</td>
        <td tabIndex={0} className="td-text">{literals.PrivacyNotice.subtableHeadings[2]}</td>
        <td tabIndex={0} className="td-text">{literals.PrivacyNotice.subtableHeadings[3]}</td>
        <td tabIndex={0} className="td-text">{literals.PrivacyNotice.subtableHeadings[4]}</td>
      </tr>
      <tr className="tr-row">
        <td tabIndex={0} className="td-text">{literals.PrivacyNotice.subtablecontent[0]}</td>
        <td tabIndex={0} className="td-text">{literals.PrivacyNotice.subtablecontent[1]}</td>
        <td tabIndex={0} className="td-text">{literals.PrivacyNotice.subtablecontent[2]}</td>
        <td tabIndex={0} className="td-text">{literals.PrivacyNotice.subtablecontent[3][0]}
          <br></br>
          {literals.PrivacyNotice.subtablecontent[3][1]}
          <br></br>
          {literals.PrivacyNotice.subtablecontent[3][2]}
          <br></br>
          {literals.PrivacyNotice.subtablecontent[3][3]}
          <br></br> 
          {literals.PrivacyNotice.subtablecontent[3][4]}
          <br></br>
          {literals.PrivacyNotice.subtablecontent[3][5]}
        </td>
        <td tabIndex={0} className="td-text">{literals.PrivacyNotice.subtablecontent[4]}</td>
      </tr> */}
    </table>

    
  );
}

export default PrivacyNotice;