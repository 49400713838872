const  SET_DATA_NOTIF_SERVICE = 'SET_DATA_NOTIF_SERVICE';

// Initial State
const initialState = {
    notifServiceReq: null,
};
const realTimeUserJourneyReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_DATA_NOTIF_SERVICE:
        return {
          ...state,
          notifServiceReq: action.payload,
        };
      default:
        return state;
    }
  };

export default realTimeUserJourneyReducer;