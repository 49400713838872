import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

//Fonts
import { fontBold } from '../base64encoded/fonts/OpenSans-Bold';
import { fontLight } from '../base64encoded/fonts/OpenSans-Light';
import { fontRegular } from '../base64encoded/fonts/OpenSans-Regular';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

//Adding our own font into the vfs
window.pdfMake.vfs["OpenSans-Bold.ttf"] = fontBold;
window.pdfMake.vfs["OpenSans-Light.ttf"] = fontLight;
window.pdfMake.vfs["OpenSans-Regular.ttf"] = fontRegular;

//Specifying the fonts which we are going to use in our PDF
pdfMake.fonts = {
    // Default font should still be available
    Roboto: {
        normal: 'OpenSans-Regular.ttf',
        bold: 'OpenSans-Bold.ttf',
        italics: 'OpenSans-Light.ttf',        
        bolditalics: 'OpenSans-Light.ttf'
    },
    // Make sure you define all 4 components - normal, bold, italics, bolditalics - (even if they all point to the same font file)
    OpenSansRegular: {
        normal: 'OpenSans-Regular.ttf',
        bold: 'OpenSans-Bold.ttf',
        italics: 'OpenSans-Light.ttf',        
        bolditalics: 'OpenSans-Light.ttf'
    }
}

export default pdfMake;