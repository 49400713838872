import React, { useState, useEffect, useRef } from 'react';
import literals from '../../Literals/Literals';
import '../EditOptions/EditOptions.scss';
import { AddRemoveSupportOptions } from '../../../services/journeyService';

const ScheduleForLater = ({ isOpenSchedule, onCloseSchedule, initialList }) => {
  const [options, setOptions] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [startLaterOptions, setStartLaterOptions] = useState([]);
  const popupRef = useRef(null);

  useEffect(() => {
    if (isOpenSchedule && popupRef.current) {
      popupRef.current.focus();
    }
  }, [isOpenSchedule]);

  useEffect(() => {
    if (isOpenSchedule && Array.isArray(initialList)) {
      setOptions(initialList.filter((opt) => opt.status !== "start_later" && opt.status !== "completed" && opt.selected));
      setStartLaterOptions(initialList.filter((opt) => opt.status === "start_later" && opt.selected));
    }
  }, [isOpenSchedule, initialList]);

  const handleProceed = async () => {
    try {
      const rqstBody = [...options, ...startLaterOptions].map(option => ({
        support_option_id: option?.support_option_id,
        status: option?.status
      }));
      await AddRemoveSupportOptions(rqstBody);
      onCloseSchedule();
    } catch (error) {
      console.error("Error occurred while calling the API:", error);
    }
  };

  if (!isOpenSchedule) {
    return null;
  }

  const handleClick = (option, action) => {
    setIsDisabled(false);
    let updatedOptions, updatedStartLaterOptions;
   
    if(option.status !== "completed"){
      if (action === "add") {
        updatedOptions = options.filter((opt) => opt.support_option_id !== option.support_option_id);
        updatedStartLaterOptions = [
          ...startLaterOptions,
          { ...option, status: "start_later" }
        ];
      } else if (action === "remove" ) {
        updatedStartLaterOptions = startLaterOptions.filter((opt) => opt.support_option_id !== option.support_option_id);
        updatedOptions = [
          ...options,
          { ...option, status: "start" }
        ];
      }
    }
    
    setOptions(updatedOptions);
    setStartLaterOptions(updatedStartLaterOptions);
  };

  return (
    <div tabIndex={0} ref={popupRef} className="modal-overlay">
      <div className="modalbackground">
        <div className='header IDModalHeader'>
          <span className='headingEdit'>
            <h2 tabIndex={0}>{literals.InteractiveDashboardComponent.ScheduleList.Heading}</h2>
          </span>
          <span>
            <button tabIndex={0} aria-label="Close" aria-pressed="false" className="close-button" onClick={onCloseSchedule}>
              <div className='closeModalImg imgD'></div>
            </button>
          </span>
        </div>

        <div className="modal-content idModal">
          <span className='removeCourses'>
            <span className='headingContEdit'>
              <h4 tabIndex={0} className='headingAddRem'>{literals.InteractiveDashboardComponent.ScheduleList.startNow}</h4>
              <p tabIndex={0} className='subHeadingAddRem'>{literals.InteractiveDashboardComponent.ScheduleList.startNowSub}</p>
            </span>

            <span tabIndex={0} className='coursesList'>
              {options?.map((option) => (
                <div className='courseItem' key={option.support_option_id}>
                  <button tabIndex={0} aria-label={option.organization} aria-pressed="false" className='remButton' onClick={() => handleClick(option, "add")} onKeyDown={(e) => (e.key === "Enter" || e.key === " ") && handleClick(option, "add")}>
                    <p className='addRemOption'>{option.organization} </p>
                    <span className='removeIcon'></span>
                  </button>
                </div>
              ))}
            </span>
          </span>

          <span className='addCourses'>
            <span className='headingContEdit'>
              <div tabIndex={0} className='headingAddRem'>{literals.InteractiveDashboardComponent.ScheduleList.startLater}</div>
              <p tabIndex={0} className='subHeadingAddRem'>{literals.InteractiveDashboardComponent.ScheduleList.startLaterSub}</p>
            </span>
            <span tabIndex={0} className='coursesList'>
              {startLaterOptions.map((option) => (
                <div className='courseItem' key={option.support_option_id}>
                  <button tabIndex={0} aria-label={option.organization} aria-pressed="false" className='addButton' onClick={() => handleClick(option, "remove")} onKeyDown={(e) => (e.key === "Enter" || e.key === " ") && handleClick(option, "remove")}>
                    <p tabIndex={0} className='addRemOption'>{option.organization} </p>
                    <span className='addIcon'></span>
                  </button>
                </div>
              ))}
            </span>
          </span>
        </div>

        <div className="modal-footer">
          <button tabIndex={0} aria-label={literals.InteractiveDashboardComponent.ScheduleList.submit} aria-pressed="false"  className={`${isDisabled ? 'saveEditListDisabled' : 'saveEditList'}`} onClick={handleProceed} onKeyDown={(e) => (e.key === "Enter" || e.key === " ") && handleProceed} disabled={isDisabled}>
            <span className={`${isDisabled ? 'disabletext' : 'submitText'}`}>
              {literals.InteractiveDashboardComponent.ScheduleList.submit}
            </span>
            <i className={`${isDisabled ? 'greyIcon' : 'whiteIcon'}`}></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ScheduleForLater;
