import React, { useState, useEffect, useRef } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import "./ImpactPage.scss";
import { useNavigate, useLocation } from "react-router";
import PageTimeTracking from "../PageTracking/PageTracker";
import BackButton from "../BackButton/BackButton";
import ContinueButton from "../ContinueButton/ContinueButton";
import literals from "../Literals/Literals";
import { storageKeys } from "../../utils/storageKeys";
import { getSummaryService } from "../../services/getSummaryService";
import "../global.scss";
import Joyride from "react-joyride";
import {
  backwardJourney,
  forwardJourney,
  editJourney,
  //moveForwardJourney,
} from "../../services/journeyService";
import { useResumeJourney } from "../../customHooks/useResumeJourney";
import { ProgressBar } from "../ProgressBar/ProgressBar";
import '../../themes/SopraTheme/sopra.scss'
import { sanitizeInput } from "../../utils/helper.methods";

function ImpactPageOne(props) {
  const navigation = useNavigate();
  const [run,setRun] = useState(false)
  const getItemFromHelper = useResumeJourney();
  const prevPage = getItemFromHelper.prevPage;
  const nextPage = getItemFromHelper.nextPage;
  const location = useLocation();

  const [heading, changeHeading] = useState("");
  const [tagsList,setTagsList] = useState([]);
  const [title, changeTitle] = useState("");
  const otherMap = new Map();
  const [someValue, setSomeValue] = useState(otherMap);
  const [optionList, setOptionList] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedResult, setSelectedResult] = useState([]);
  const currentPage = useRef(-1);
  const mappedJourney = useRef([])
  const getDataFromLocalStorage = useRef([]);
  const totalForwardJourneys = useRef(0)
  const isEndOfTheJorueny = useRef(false)
  const [loading, setLoading] = useState(false);
  const dataForImpacts = useRef([]);
  const groupForImpacts = useRef([]);
  const isComingFromThankYouTwo = useRef(false);
  const [progressBarValue, setProgressBarValue] = useState(0);
  const [totalNumPages, setTotalNumPages] = useState(0);
  const isFromChangeImpact = useRef(false);
  const buttonText = "Back";
  const buttonTextContinue = "Continue";

  const parentDiv = useRef();
  const backClickConsume = useRef(true);
  const continueClickConsume = useRef(true);
  const isDisabled = useRef(true);

  const steps = [
    {   target:".buttons_containertwo",
        content:<h2>{literals.help.impacts.impacts_Options}</h2>,
        placement: 'bottom',
        disableBeacon: true,
    },
   
]

   const handleShowHelp = () => {
      setRun(true);
  }

  const handleJoyrideCallback = (data) => {
    const { status, index, type } = data;
    const finishedStatuses = ['finished', 'skipped'];

    if (
      finishedStatuses.includes(status) ||
      (type === 'step:after' && index === steps.length - 1)
    ) {
      setRun(false);
    }
  };


  function getLevelTwoSelection() {
    console.log(dataForImpacts.current);
    const secondLevel = dataForImpacts.current;
    const secondlevelSelection = secondLevel.flat(1);
    console.log(secondlevelSelection);
    const secondLevelFlat = secondlevelSelection.filter(
      (item) => item.nodeName.toLocaleLowerCase() !== "other"
    );
    console.log(secondLevelFlat);
    return secondLevelFlat;
  }

  function getLevelThreeSelection() {
    console.log(groupForImpacts.current);
    const thirdLevel = groupForImpacts.current;
    const thirdLevelSelection = thirdLevel.flat(1);
    console.log(thirdLevelSelection);
    return thirdLevelSelection;
  }

  function getBooleanItem(key) {
    return (
      (localStorage.getItem(key) || "false").toLocaleLowerCase() === "true"
    );
  }

  const loadFBJourneyApi = (node, parentRelationNode, isBackJoruney) => {
    const request = getForwardJourneyParam(
      node,
      parentRelationNode,
      isBackJoruney
    );
    const response = isBackJoruney
      ? backwardJourney(request)
      : forwardJourney(request);
    return response;
  };

  const fetchSummary = async () => {
    const response = await getSummaryService();
    console.log(response.data);
    dataForImpacts.current = (response.data.map((item) => item.topics[0].nodes.filter((node) => node.fieldType !== "nafmButton"))).filter(item => item.length!==0);
    groupForImpacts.current = (response.data.map((item) => item.circumstancesClassNodes));
    console.log(dataForImpacts.current,groupForImpacts.current);
    //fetch the total number of impact pages from the data
    const numOfImpactPages = dataForImpacts.current.reduce(function (acc, impacts) {
      acc += impacts.length;
      return acc;
    }, 0) // 0 is the initial value
    setTotalNumPages(numOfImpactPages);
    if (location.state && location.state.relationPath) {
      requestChangeImpact(location.state);
    }
  };

  useEffect(() => {
    console.log("Option List", optionList);
    const ifIsSelected = optionList.filter((item) => item.selected === true);
    console.log("IS SELECTED",ifIsSelected.length)
    if (ifIsSelected.length > 0) {
      isDisabled.current = false
    }
    else {
      isDisabled.current = true;
    }
    fetchSummary();
    if (location.state && location.state.relationPath) {
    } else {
      setLoading(true)
      setTimeout(() => {
        if (props.screenData !== undefined && props.screenData.current !== null && props.screenData.current.length !== 0 && props.screenData.current === "impacts") {
          console.log(props.screenData.current)
          const getSelections = getLevelTwoSelection();
          const getThirdSelections = getLevelThreeSelection();
          console.log("Third selections",getThirdSelections);
          const nodeToBeCalled = getSelections.filter(item => item.relationPath == props.screenData.current);
          console.log("Node to be called",nodeToBeCalled);
          const indexOfNode = getSelections.findIndex(x => x.relationPath === nodeToBeCalled[0].relationPath.toString());
          console.log(indexOfNode);
          loadForwardBackwardJourney(indexOfNode, false);
        } else {
          console.log("No screendata");
          isComingFromThankYouTwo.current = getBooleanItem(
            storageKeys.isComingBackFromThankYouTwo
          );
          if (isComingFromThankYouTwo.current) {
            console.log("Coming Back from Thank you two");
            const lengthOfSelectionsDone = getLevelTwoSelection().length;
            const lengthToBePassed = lengthOfSelectionsDone - 1;
            console.log(lengthToBePassed);
            currentPage.current = lengthToBePassed;
            progressBarCalculation(-1)
            setTotalNumPages(lengthOfSelectionsDone)
            console.log("Current page from thank you two", currentPage.current);
            setTimeout(() => { loadForwardBackwardJourney(lengthOfSelectionsDone - 1, true) }, 4000);
          } else {
            setTimeout(() => { loadForwardBackwardJourney(0, false) }, 2000);
          }
        }
      }, 2000)
      setSelectedResult(optionList.filter((item) => selectedOptions.includes(item.id)))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //On option selection event:: Check Change
  const handleCheckChange = (option) => {
    const itemId = option.id;
    const isChecked = !option.selected;

    optionList.forEach((opt) => {
      if (opt.id === option.id)
        opt.selected = !option.selected;
    });

    const selectedItems = selectedOptions.filter((id) => id !== itemId);
    if (isChecked) {
      selectedItems.push(itemId);
    }

    setSelectedOptions(selectedItems);
    setSelectedResult(optionList.filter((i) => selectedItems.includes(i.id)));
    const ifIsSelected = optionList.find((element) => element.selected === true);
    console.log("IS SELECTED",ifIsSelected);
    if (ifIsSelected) {
      isDisabled.current = false;
    }
    else {
      isDisabled.current = true;
    }
    console.log("look", selectedResult);
  };


  const handleTextboxChange = (value,option) => {
    value = sanitizeInput(value);
    const itemId = option.id;
    const isChecked = value.length > 0;
    console.log(value,option);
    console.log("SOMEEEE VALUEEEE",someValue);
    otherMap.set(option.id,value)
    console.log("OTHERRRRR MAPPPPPP",otherMap);
    setSomeValue(otherMap);
       
    const updatedOptions = optionList.map((opt) => {
            if (opt.id === option.id && opt.tag === option.tag) {
              console.log("Value length",value.length)
              if(value.length>0){
                return { ...opt, fieldValue: value,selected:otherMap.get(option.id).length>0 };
              }
              else{
                return { ...opt, fieldValue: null,selected:false };
              }
            }
            return opt;
        });
        console.log("Updated Options when other",updatedOptions);
        setOptionList(updatedOptions);
        console.log("Option list after adding text",optionList,"isChecked",isChecked)

        const selectedItems = selectedOptions.filter((id) => id !== itemId);
        if (isChecked) {
          selectedItems.push(itemId);
        }
        else{
          selectedItems.pop(itemId);
        }

        console.log(
          "Selected Items in textbox func",selectedItems
        )
    
        setSelectedOptions(selectedItems);
        setSelectedResult(updatedOptions.filter((i) => selectedItems.includes(i.id)));
        console.log("selected result in textbox func",selectedResult)
        const ifIsSelected = updatedOptions.find((element) => element.selected === true);
        console.log("IS SELECTED",ifIsSelected);
        if (ifIsSelected) {
          isDisabled.current = false;
        }
        else {
          isDisabled.current = true;
        }
}

  function getStorageItem(key) {
    return JSON.parse(localStorage.getItem(key));
  }

  function clearPrevSelectionFromUI() {
    setSelectedOptions([]);
    setSelectedResult([]);
    setSomeValue(otherMap);
  }

  function saveItem(key, result) {
    localStorage.setItem(key, result);
  }

  function compareData(key1, key2) {
    return JSON.stringify(key1) === JSON.stringify(key2);
  }

  function updateMapJourney(itemId) {
    console.log("OptionList in updateMAP jOURNEY", optionList);
    console.log(selectedOptions);
    const selectionItem = optionList.filter((it) =>
      selectedOptions.includes(it.id)
    );
    console.log(selectionItem);
    const mapToBeAdded = {
      id: itemId,
      value: selectionItem,
    };
    console.log(mappedJourney.current);
    const lastMapAdded = mappedJourney.current.filter(
      (item) => item.id !== itemId
    );
    lastMapAdded.push(mapToBeAdded);

    mappedJourney.current = lastMapAdded;
    getDataFromLocalStorage.current = getStorageItem(
      storageKeys.forwardJourney
    );
    if (getDataFromLocalStorage.current != null) {
      /* eslint eqeqeq: 0 */
      if (getDataFromLocalStorage.current.length != lastMapAdded.length) {
        saveItem(storageKeys.reachedSupport, false);
      } else {
        const isDataEqual = compareData(
          getDataFromLocalStorage.current,
          lastMapAdded
        );
        if (!isDataEqual) {
          saveItem(storageKeys.reachedSupport, false);
        }
      }
    }

    console.log("Latest Map : ", lastMapAdded);
    return lastMapAdded;
  }

  const onContinueButtonEvent = () => {
    saveItem(storageKeys.reachedStepTwo, false);
    continueClickConsume.current = false;
    backClickConsume.current = true;
    setSomeValue(otherMap);
    console.log("Selected Result", selectedResult);
    console.log("Current page when clicked continue", currentPage.current);
    const totalJourneys = getLevelTwoSelection().length;
    totalForwardJourneys.current = totalJourneys;
    const parentRelationNodeAt = currentPage.current
    const nextPageToLoad = currentPage.current + 1
    console.log("Next page to load",nextPageToLoad)
    const items = getLevelTwoSelection()
    console.log(items);
    console.log(parentRelationNodeAt);
    updateMapJourney(items[parentRelationNodeAt].id)

    console.log("ParentNode : " + parentRelationNodeAt + "Total = " + totalForwardJourneys.current + " CP : " + currentPage.current)

    isEndOfTheJorueny.current = totalForwardJourneys.current === nextPageToLoad;
    loadForwardBackwardJourney(nextPageToLoad, false);
    progressBarCalculation(nextPageToLoad)
  };

  const onBackButtonEvent = () => {
    continueClickConsume.current = true;
    backClickConsume.current = false;

    console.log("Current Page in Back event", currentPage.current);
    if (currentPage.current === 0 && currentPage.current === -1) {
      if (isFromChangeImpact.current) {
        navigation("/checkyouranswers", {
          state: {
            isLoadLastCategory: false,
            editedImpact: ((location.state && location.state.editedImpact) ? location.state.editedImpact : ''),
          }
        });
      } else {
        navigation(`/${prevPage}`);
      }
    } else {
      let previousPageToLoad;
      if (isComingFromThankYouTwo.current) {
        previousPageToLoad = currentPage.current
      }
      else {
        previousPageToLoad = currentPage.current - 1;
      }
      console.log("Previous Page : ", previousPageToLoad);
      if (previousPageToLoad == -1) {
        navigation(`/${prevPage}`);
      }
      else {
        loadForwardBackwardJourney(previousPageToLoad, true);
        progressBarCalculation(previousPageToLoad)
      }
    }
    if (!backClickConsume.current) {
      backClickConsume.current = true;
      if (parentDiv.current) {
        parentDiv.current.focus();
        console.log("BACK CLICK FOCUS SENT: ");
      }
    }
  };

  const requestChangeImpact = async (state) => {
    isFromChangeImpact.current = true;
    const pageIndex = findChangeImpactPage(state);

    console.log(
      "requestChangeImpact: ",
      pageIndex,
      state.changeImpactPath,
      state.editedImpact,
      state.relationPath,
      state.selectedImpact
    );

    if (pageIndex >= -1) {
      currentPage.current = pageIndex
      const request = {
        relationPath: state.relationPath,
        parentRelationPath: state.relationPath,
        screenName: "",
        endOfJourney: true,
      };
      const response = await editJourney(request);

      console.log("response: ", response, pageIndex);

      if (!(response && response.data && response.data.length > 0)) {
        return;
      }
      updateUIForForwardJourney(response,state.selectedImpact);
    }
  };

  const findChangeImpactPage = (state) => {
    const secondLevel = getLevelTwoSelection();
    return secondLevel.findIndex((item) => item.id === state.editedImpact);
  };

  function loadForwardBackwardJourney(nodeAt, isBackJoruney) {
    console.log("Node At", nodeAt);
    let node;
    let parentRelationNode;
    const items = getLevelTwoSelection();
    const itemsAtTwoC = getLevelThreeSelection();
    console.log("Items at two c",itemsAtTwoC);
    console.log(items);
    if (nodeAt === items.length) {
      node = "";
      parentRelationNode = items[nodeAt - 1];
    } else if (nodeAt === 0) {
      node = items[nodeAt];
      parentRelationNode = "";
    } else {
      node = items[nodeAt];
      parentRelationNode = items[nodeAt - 1];
    }
    console.log("LJ-NodeAt : " + nodeAt + " with Id : " + node.id);
    (async () => {
      setLoading(true)

      try {
        console.log("Params in loadFBJourney : ", node, parentRelationNode, isBackJoruney)
        const forwardJourneyResult = await loadFBJourneyApi(node, parentRelationNode, isBackJoruney)
        console.log("Loaded Data From FJourney : ", forwardJourneyResult.data);
        setLoading(false)

        if (isFromChangeImpact.current) {
          navigation("/checkyouranswers", {
            state: {
              isLoadLastCategory: false,
              editedImpact: ((location.state && location.state.editedImpact) ? location.state.editedImpact : ''),
            }
          });
        } else {
          updateUIForForwardJourney(forwardJourneyResult,node);
          if (isBackJoruney) {
            console.log("Current Page : ", currentPage.current)
            // setCurrentPage(currentPage - 1)
            if (isComingFromThankYouTwo.current) {
              isComingFromThankYouTwo.current = false
              localStorage.setItem(storageKeys.isComingBackFromThankYouTwo, false)
              const pageCurrent = currentPage.current
              currentPage.current = pageCurrent
            }
            else {
              currentPage.current = currentPage.current - 1
            }
            console.log(currentPage.current)
          } else {
            if (isEndOfTheJorueny.current) {
              saveItem(storageKeys.reachedStepTwo, true);
              return navigation(`/${nextPage}`);
            } else {
              console.log("Current Page : ", currentPage.current + 1)
              // setCurrentPage(currentPage + 1)
              currentPage.current = currentPage.current + 1
            }
          }
        }
        if (!continueClickConsume.current) {
          continueClickConsume.current = true;
          if (parentDiv.current) {
            parentDiv.current.focus();
            console.log("CONTINUE CLICK FOCUS SENT: ");
          }
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }

  function getForwardJourneyParam(node, parentRelationNode, isBackJoruney) {
    console.log(
      "Parent realtion node in getForward Journey oaram",
      parentRelationNode
    );
    const response = getSelectedChildsResultForJourney(
      node,
      parentRelationNode,
      isBackJoruney
    );
    const param = {
      relationPath: node ? node.relationPath : "",
      parentRelationPath: isBackJoruney
        ? ""
        : parentRelationNode
          ? parentRelationNode.relationPath
          : "",
      response: response,
      screenName: "Impacts",
      endOfJourney: true,
    };
    return param;
  }

  function getSelectedChildsResultForJourney(
    node,
    parentRelationNode,
    isBackJoruney
  ) {
    if (parentRelationNode && !isBackJoruney) {
      console.log(
        "parentRelationNode : ",
        parentRelationNode,
        " node :",
        node,
        +"Mapped",
        mappedJourney.current
      );
      const resultSelections =
        mappedJourney.current.filter((it) => it.id === parentRelationNode.id) ||
        [];
      console.log(
        "parentRelationNode : " + parentRelationNode.id + " selections :",
        resultSelections
      );

      if (resultSelections.length > 0) {
        const result = resultSelections[0].value.map((item) => ({
          id: item ? item.id : "",
          value: item
            ? item.name.toLocaleLowerCase() === "others"
              ? item.fieldValue
              : ""
            : "",
        }));
        console.log("Result Array : ", result);
        return result;
      }
    }
    return [];
  }

  // Update whole UI on load
  function updateUIForForwardJourney(forwardJourneyResult,node) {
    clearPrevSelectionFromUI();
    const result = forwardJourneyResult.data[0];
    const heading = node.nodeName || "";
    const title = result.title || "";
    const newOptionList = result.node || [];
    const optionListTwo = getLevelThreeSelection();
    console.log("RESULTTTTTT",result,result.node)
    const filternewOptionList = newOptionList.filter((item) => (item.id.startsWith("I")&&item.relationPath.includes(`${node.relationPath}`)));
    const filterOptionListTwo = optionListTwo.filter((item) => (item.relationPath.includes(`${node.relationPath}`)));
    console.log("Filtered Impact",filternewOptionList,"Filtered second list",filterOptionListTwo);
    const formattedArray = filterOptionListTwo.map(item => {
      const parts = item.relationPath.split('_');
      const name = item.fieldType === "preferButton" ? item.name.replace("I'd ", '').replace(/^'|'$/g, '').replace("prefer","Prefer") : item.fieldType === "textbox" ? item.name.replace("(", " (") : item.name;
      return `${parts[1]}^${item.id}^${name}`;
  });

  console.log("Formatted list two",formattedArray);
  setTagsList(formattedArray);
    const selectedResult = newOptionList.filter((item) => item.selected);
    const selectedIds = selectedResult.map((it) => it.id);
    console.log(selectedIds);
    clearPrevSelectionFromUI();
    setOptionList(filternewOptionList);
    setLoading(false);
    changeTitle(title);
    changeHeading(heading);
    if (selectedIds.length > 0) {
      isDisabled.current = false
      setSelectedOptions(selectedIds);
      setSelectedResult(selectedResult);
    }
    else{
      isDisabled.current = true;
    }
    console.log("Selection From Back : ", selectedIds);
    
    const otherItem = selectedResult.find(
      (item) => item.name.toLocaleLowerCase() === "others"
    );
  }

  const progressBarCalculation = (pageIndex) => {
    console.log("Progress bar",totalNumPages,pageIndex)
    if (pageIndex === -1) {
      console.log("First case")
      setProgressBarValue(localStorage.getItem("PBI"))
    } else {

      if (totalNumPages !== undefined) {
        console.log("Second case")
        const val = Math.floor((pageIndex / totalNumPages) * 100)
        setProgressBarValue(val)
        if (val !== 0) localStorage.setItem("PBI", val)
      }
      else {
        console.log("Third case")
        const val1 = Math.floor((pageIndex / totalNumPages) * 100)
        setProgressBarValue(val1)
        if (val1 !== 0) localStorage.setItem("PBI", val1)
      }
    }
  }

  return (

    <div>
      <Joyride
    steps={steps}
    run={run}
    continuous
    callback={handleJoyrideCallback}
    spotlightClicks={false}
    scrollToFirstStep
    hideBackButton={true}
        locale={{last: 'Close'}}
    styles={{
      options: {
        arrowColor: '#fff',
        backgroundColor: '#e3ffeb',
        overlayColor: 'transparent',
        primaryColor: '#000',
        textColor: '#004a14',
        zIndex: 1000,
        spotlightPadding: 0,
      },
    }}
    />
      <div className="head_container">
        <Header tabIndex={0} homepage={false} />
        <ProgressBar tabIndex={0} pageName="Impacts" progressValueC={100} progressValueI={progressBarValue} progressValueS={0} />
        <div className="help_nh" alt="sp_logo" tabIndex={0} onClick={handleShowHelp}><span className="visual">Help Icon</span></div>
      </div>

      <div className="heading-holder">
        <span className="title-icon-impact imgD" tabIndex={0}><span className="visual">Impacts logo</span></span>
        <span className="title-text">
          <h1 className="headingImpact" tabIndex={0}>{literals.Impacts.heading}</h1>
          <p className="title-para" tabIndex={0}>
            {literals.Impacts.subheading}
          </p>
        </span>
      </div>
      <div>
    {loading ? (
      <div className="loader-container">
        <div className="spinner"></div>
      </div>
    ) : (
      <div className="greyContainer">
        <div className="greyContainerImpact fixG">
          <div className="headingContImpact">
            <h2
              tabIndex={0}
              className="category-heading-Impact category-headin"
            >
              {heading}
            </h2>
            <p className="assertive-text" tabIndex={0}>{literals.Impacts.optionSubheading}</p>
          </div>

          <div className='buttons_containerImpacts'>
            {tagsList.length!==0 && tagsList.map((tag,index) => {
              console.log("tag shown",typeof(tag),optionList.filter(option => option.tag.includes(tag)));
              return(
              <div className="tagContainer" key={tag}>
              <h2 className="category-heading-Impact category-headin tagHead tagHeading" tabIndex={0}>{(tag.split('^'))[2]}</h2>
              <div className="buttons_containertwo">
              {optionList.filter((option) => option.tag.includes(tag)).map((option) => {
                if (option.fieldType === "button") {
                  return (
                    <button
                      key={option.name}
                      className={`defaultOption optionsFont ${option.selected ? 'selectedOption tSelected' : 'tNselected'}`}
                      data-option={option.name}
                      onClick={() => { handleCheckChange(option) }}
                      aria-selected={option.selected}
                    >
                      <span className={`button-text`}>{option.name}</span>
                      {option.selected && <span className="selected-icon imgD" />}
                    </button>
                  );
                }
                else if (option.fieldType === "textbox") {
                  return (
                    <input
                      key={option.name}
                      type="text"
                      id="inputField"
                      placeholder="Other (please specify)"
                      className={`textboxOption optionsFont`}
                      value={option.fieldValue!==null?option.fieldValue : someValue.get(option.id)}
                      onInput={(e) => handleTextboxChange(e.target.value,option)}
                    />
                  );
                }
                return null;
              })}
              </div>
              {index !== tagsList.length - 1 && <hr className="hrStyleImpact"/>}
              </div>
              )
})}
            
                            
            
          </div>
        </div>
      </div>

          )}
  
        <div className="bottomBtn">
          <BackButton label={buttonText} onClick={onBackButtonEvent} />
          <ContinueButton classname={isDisabled.current ? "ContinueButtonDisabled" : "ContinueButton"} label={buttonTextContinue} disabled={isDisabled.current} onClick={onContinueButtonEvent} float-end />
        </div>
        <PageTimeTracking pageName="Impacts" />
 
      <div className="third footer_container">
        <Footer
          screenName="Impacts"
          tabIndex={0}
          className="d-footer"
          theme={props.theme}
          homepage={false}
        />
      </div>
    </div>
    </div>
  );
}

export default ImpactPageOne;
