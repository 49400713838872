import Footer from "../../Footer/Footer";
import "../../global.scss";
import React from 'react';
import literals from "../../Literals/Literals";
import "./ThankYouComponent.scss";
import PageTimeTracking from "../../PageTracking/PageTracker";
import BackButton from "../../BackButton/BackButton.jsx";
import { useNavigate } from "react-router-dom";
import Header from "../../Header/Header.jsx";
import ExitButton from "../../ExitButton/ExitButton.jsx";
import { useResumeJourney } from "../../../customHooks/useResumeJourney.js";
import { Navigation } from "@mui/icons-material";


export default function ThankYouComponent(props) {
  const exitapp = "Exit the app";
  var subheading4 = literals.ConsentPage.subheading4
  const linkText = literals.ConsentPage.linkText
  const index = subheading4.indexOf(linkText);
  const beforeSubstring = subheading4.substring(0, index);
  const afterSubstring = subheading4.substring(index + linkText.length);
  console.log(beforeSubstring,afterSubstring);
  const navigation = useNavigate();
  const themeselected = props.theme;

  const handleBack = () => {
    navigation(`/needhelp`);
    }

  return (
    <div className="parent-container-2">
      <Header tabIndex={0} homepage={false} />
      <div className="consentContent mt-5">
      <div tabIndex={0} className="rightImageWellDone"><span className="visual">applauding, expressing appreciation for job well done</span></div>
        <h1 tabIndex={0} className="headerName">{literals.ExitPage.heading1} </h1>
      </div>
          <div className="internal-container-constent">
            <div className="consentContent">
              {/* Main content text here >>> */}
              <div tabIndex={0}> <h2 className="subHeading"> {literals.ExitPage.heading2} </h2> </div>
              <p tabIndex={0} className="customFontBlack"> {literals.ExitPage.description}</p>
              <p tabIndex={0} className="customFontBlack"> {literals.ExitPage.description2}</p>
            </div>
            <div className="btnContainerSupportPack m-5">

                    <BackButton hideImg={true} tabIndex={0} label={"Back"} classname="thankYouExit" onClick={handleBack}/>
            </div>
            
            <div className="internal-child-3">
              {/* Footer here >>> */}
              <Footer theme={themeselected} homepage={false} />
              
              <PageTimeTracking pageName="Consent" />
            </div>
          </div>
    </div>
  );
}
