import React, { useState, useEffect } from 'react';
import '../../themes/SopraTheme/sopra.scss'
import './ModalBox.scss';
import { sanitizeInput } from '../../utils/helper.methods';

const ModalBox = ({onClose, onProceed, initialList, needhelpoption, tags }) => {
    const [options, setOptions] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [isDisabled, setIsDisabled] = useState(true);
    const otherMap = new Map();
    const [someValue, setSomeValue] = useState(otherMap);
    console.log("Option selected from 1st layer",needhelpoption);
    console.log(tags);

    useEffect(() => {
        setSelectedOptions(filterOptions(options));
        const isEnabled = options.filter(option => option.selected).length > 0; 
        setIsDisabled(!isEnabled);
    }, [options]);

    useEffect(() => {
        if(localStorage.getItem(needhelpoption.relationPath)!==null){
            setOptions(JSON.parse(localStorage.getItem(needhelpoption.relationPath)));
        }
        else{
        setOptions(initialList);
        }
        console.log("Value 1",initialList);
        // eslint-disable-next-line
    },[initialList])


    const handleOptions = (option) => {
        const updatedOptions = options.map((opt) => {
            if (opt.id === option.id && opt.fieldType==="button") {
                return { ...opt, selected: !opt.selected };
            }
            else if(opt.fieldType === "preferButton" && opt.tag === option.tag){
                return { ...opt, selected: false,fieldValue:null };
            }
            return opt;
        });
             setOptions(updatedOptions);
                         // localStorage.setItem(needhelpoption.relationPath,JSON.stringify(updatedOptions));
    };

    const handlePreferOption = (option) => {
        const updatedOptions = options.map((opt) => {
            if (opt.id === option.id) {
                return { ...opt, selected: !opt.selected };
            }
            else if((opt.fieldType==="textbox" || opt.fieldType === "button") && opt.tag === option.tag){
                setSomeValue(otherMap.set(option.id,null));
                return { ...opt, selected: false,fieldValue:null };
            }
            return opt;
        });
             setOptions(updatedOptions);
             localStorage.setItem(needhelpoption.relationPath,JSON.stringify(updatedOptions));
    };

    const handleTextboxChange = (value,option) => {
        value = sanitizeInput(value);
        console.log(value,option);
        console.log("SOMEEEE VALUEEEE",someValue);
        otherMap.set(option.id,value)
        console.log("OTHERRRRR MAPPPPPP",otherMap);
        setSomeValue(otherMap);
           
        const updatedOptions = options.map((opt) => {
                if (opt.id === option.id && opt.tag === option.tag) {
                    return { ...opt, fieldValue: value,selected:otherMap.get(option.id).length>0 };
                }
                return opt;
            });
            console.log("Updated Options when other",updatedOptions);
            setOptions(updatedOptions);
            localStorage.setItem(needhelpoption.relationPath,JSON.stringify(updatedOptions));
    }

    const filterOptions = (arr) => {
        return arr.filter((option) => option.selected);
    };

    const handleProceed = () => {
        localStorage.setItem(needhelpoption.relationPath,JSON.stringify(options));
        onProceed(selectedOptions);
        onClose();
    };

    return (


        
        <div tabIndex={0} className="modal-overlay">
            <div className="modalbackground">
                <div className='header'>
                    <span className='headingModal title hColor ms-5 mt-2'>
                        <div tabIndex={0}>{needhelpoption.name}</div>
                    </span>
                    <span>
                        <button className="closeStyle" onClick={onClose}>
                            <div className='closeModalImg imgD'><span className='visual'>Close</span></div>
                        </button>
                    </span>
              
              
  </div>


                <div className="modal-content"> 
                <p className='modal-subheading ms-5' tabIndex={0}>Please select one or more options to describe your circumstances in more detail</p>
                
                    <div className='modalButtons'>
                       {
                       tags.length !== 0 && tags.map((tag) => (
                        <div key={tag}>
                            {tag.length!==0 ? <p className='tagHeading ms-5' tabIndex={0}>{tag}</p> : null}
                         
                    <div className='buttonContainer'>
                        {options.filter(option => option.tag === tag).map((option) => {
                            if (option.fieldType === "button") {
                                return (
                                    <button
                                        key={option.id}
                                        className={`optionsFont twoBOption ${option.selected ? 'selectedOption tSelected' : 'tNselected'}`}
                                        data-option={option.name}
                                        onClick={() => handleOptions(option)}
                                        aria-selected={option.selected}
                                    >
                                        <span className="modalText">{option.name}</span>
                                        {option.selected && <span className="selected-icon fixIcon" />}
                                    </button>
                                );
                            }
                            else if(option.fieldType === "preferButton"){
                                return (
                                    <button
                                        key={option.id}
                                        className={`optionsFont preferNot ${option.selected ? 'selectedTextOption tSelected' : 'tNselected'}`}
                                        data-option={option.name}
                                        onClick={() => handlePreferOption(option)}
                                    >
                                        <span className={`modalText !option.selected ? "preferNotText" : ""}`}>{option.name}</span>
                                        {option.selected && <span className="selected-icon imgD" />}
                                    </button>
                                );
                            }
                            else if(option.fieldType === "textbox"){
                                return (
                                    <input
                                        key={option.id}
                                        type="text"
                                        id="inputField"
                                        value={option.fieldValue!==null?option.fieldValue : someValue.get(option.id)}
                                        placeholder="Something else (please specify)"
                                        className={`textboxOption optionsFont`}
                                        onInput={(e) => handleTextboxChange(e.target.value,option)}
                                    />
                                );
                            }
                            return null
                        })}
                      </div>
                      </div>
                    )
                )}
                    </div>
                </div>

                <div className="modal-footer">
                    <button className={isDisabled?`saveDisabled`:`save`} onClick={handleProceed} disabled={isDisabled}>
                        <span className={isDisabled?`saveDisabledLabel`:`saveLabel`}>Save</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ModalBox;
