import React from 'react';
import './CustomRadioButton.scss';

const CustomRadioButton = ({ value, isChecked, onChange, disabled, color, ariaChecked, ariaLabel }) => {
  const handleKeyPress = (event) => {
    if ((event.key === 'Enter' || event.key === ' ') && !disabled) {
      onChange();
    }
  };

  return (
    <div
      data-testid="custom-radio-button"
      tabIndex={0}
      role="radio"
      aria-checked={ariaChecked}
      aria-label={ariaLabel}
      className={`radioOption ${isChecked ? 'slected' : ''} ${disabled ? 'disableiT' : ''}`}
      onKeyDown={handleKeyPress}
      onClick={onChange}
    >
      <svg data-testid="radio-svg" className="radio-svg" width="30px" height="30px" viewBox="0 0 20 20">
        <circle cx="10" cy="10" r="9" fill="none" stroke={color} strokeWidth="2" />
        {isChecked && <circle cx="10" cy="10" r="6" fill={color} />}
      </svg>
      <label className="radioOptionText">{value}</label>
      <input
        data-testid="hidden-input"
        type="radio"
        role="radio"
        checked={isChecked}
        aria-checked={ariaChecked}
        onChange={() => {}}
        disabled={disabled}
        style={{ display: 'none' }}
      />
    </div>
  );
};

export default CustomRadioButton;
