import React, {useContext} from 'react';
import { ExitContext } from '../../contexts/exit.context';
import './ExitButton.scss';
import { useSelector } from 'react-redux';

const ExitButton = (props) => {

  const { closeAllTabs, isExitClicked, isChildNewTab } = useContext(ExitContext);
  const handleExitClick = (e) => {

    if (isChildNewTab.current) {
      window.close();
    }
    else {
    e.preventDefault();

    localStorage.clear();

    if (isChildNewTab.current && window.opener) {
      window.opener.setParentExitTool(true);
    }
    isExitClicked.current = true; 

    closeAllTabs();}
  }

  return (
    <div className={props.classname ? props.classname : 'exitStyle' }>

          {/* <div className="help_b" alt="sp_logo" tabIndex={0}><span className="visual">Support Point Logo</span>   </div> */}
      <button  tabIndex={props.tabIndex}  className="exitButtonStyle" onClick={(e) => { handleExitClick(e) }}
      ><span>{props.label ? props.label : "EXIT"}</span></button>
      {/* <Link className='exitLinkStyle' onClick={(e) => { handleExitClick(e) }}>Exit</Link> */}
    </div>
  )
}

export default ExitButton
