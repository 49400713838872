import { useLocation } from "react-router";
import ResumeHelper from "../utils/resumeHelper";

export function useResumeJourney() {
  const location = useLocation();
  const locationString = location.pathname.toString();

  const paths = locationString.split("/");
  const locationFinal = locationString.replace("/", "");
  const actualPath = (paths && paths.length > 1) ? (paths[1] ? paths[1] : locationFinal) : locationFinal;
  return ResumeHelper(actualPath);
}
