import React from 'react'

const ButtonComponent = (props) => {
    const {classname,divClass,name,disabled,onClick} = props;
  return (
    <div className={divClass}>
        <button className={classname} disabled={disabled} onClick={onClick}>{name}</button>
    </div>
  )
}

export default ButtonComponent