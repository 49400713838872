import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState, useContext } from "react";

import literals from "../../Literals/Literals";
import '../CheckYourAnswersStyles.scss';
import { CheckYourAnswerContext } from "../Context/CheckYourAnswerContext";

const AnswerSubPage = (props) => {

    const params = useParams();
    const navigator = useNavigate();
    const indexOfSubAnswers = useRef(1);

    const STARTING_LEVEL = 'LVL1';

    //must be identical levels and in ascending order. like ['LVL1','LVL2','LVL3','LVL4'...]
    const levels = useRef([]);
    const selectedJourney = useRef([]);

    //UI Updater
    const [heading, setHeading] = useState('');
    const [isUIUpdate, setUIUpdate] = useState(false);

    const { journeySummary } = useContext(CheckYourAnswerContext);

    const parentDiv = useRef();

    const changeImpactTabIndex = useRef([]);

    const tabIndex = useRef(4);

    useEffect(() => {
        if (props.topicId) {

            const summaryData = journeySummary.current;

            selectedJourney.current = summaryData.find((item) => Number(item.topicId) === Number(props.topicId));

            if (selectedJourney.current && selectedJourney.current.topics) {
                levels.current = [...new Set(selectedJourney.current.topics.map((item) => item.level))];
                
                //Tab Index Reset
                tabIndex.current = 4;
                changeImpactTabIndex.current = [];
                
                setHeading(selectedJourney.current.topicName);
                setUIUpdate(true);
            }
            if (parentDiv.current) {
                parentDiv.current.focus();
                console.log("COMBINE CLICK FOCUS SENT: ");
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [params])

    const calculateChangeImpactTabIndex = (prevNode, currentLevel) => {
        let nextLevel = '';
        let currentLevelNode;

        const indexOfCurrentLevel = levels.current.findIndex((level) => (level === currentLevel));

        if ((indexOfCurrentLevel + 1) < levels.current.length) {
            nextLevel = levels.current[indexOfCurrentLevel + 1];
        }

        if (currentLevel === STARTING_LEVEL) {
            currentLevelNode = selectedJourney.current.topics.find((item) => item.level === currentLevel);                       
        } else {
            const currentLevelArray = selectedJourney.current.topics.filter((item) => {
                return (item.level === currentLevel);
            });
            currentLevelNode = currentLevelArray.find((item) => (item.journeyPath.endsWith(prevNode.id)));
        }
        
        if (currentLevelNode) {
            
            //For Tab Index assigment of change impact
            if(currentLevel === STARTING_LEVEL) {
                currentLevelNode.nodes.map((item) => {
                    if (!isOtherNode(item.fieldType)) {    
                        changeImpactTabIndex.current[item.id] = 0;                    
                    }
                    return item;
                })
            } else {
                for (let key in changeImpactTabIndex.current) {
                    if (currentLevelNode.journeyPath.includes(key)) {
                        changeImpactTabIndex.current[key] += currentLevelNode.nodes.length;                        
                    }
                }
            }


            currentLevelNode.nodes.map((item) => {
                if(nextLevel) {
                    calculateChangeImpactTabIndex(item, nextLevel)
                }
                return item
            })

        }
    }

    const nLevelProcessing = (prevNode, currentLevel) => {
        console.log('recursiveCall Init prevNode:  ', prevNode, currentLevel);

        let nextLevel = '';
        let currentLevelNode;

        const indexOfCurrentLevel = levels.current.findIndex((level) => (level === currentLevel));

        if ((indexOfCurrentLevel + 1) < levels.current.length) {
            nextLevel = levels.current[indexOfCurrentLevel + 1];
        }

        if (currentLevel === STARTING_LEVEL) {

            currentLevelNode = selectedJourney.current.topics.find((item) => item.level === currentLevel);

        } else {

            const currentLevelArray = selectedJourney.current.topics.filter((item) => {
                return (item.level === currentLevel);
            });

            currentLevelNode = currentLevelArray.find((item) => (item.journeyPath.endsWith(prevNode.id)));

        }

        if (currentLevelNode) {
            let uiElement = <></>;
            console.log("curent node",currentLevelNode,"current level",currentLevel)
            uiElement = <>
              {currentLevelNode.level === "LVL1" ? <div className="title custom-title" tabIndex={0}>{currentLevelNode.nodeName}</div> : <></>}
              {currentLevelNode.nodes.map((item, index) => (
                (
                    (currentLevel === STARTING_LEVEL) ? (
                        <div key={item.id} className="col-md-6 answersbox">
                            {LevelItem(item, currentLevel, currentLevelNode.journeyPath)}
                            {nextLevel ? nLevelProcessing(item, nextLevel) : (<></>)}
                        </div>
                    ) : (
                        <div key={item.id}>
                            {LevelItem(item, currentLevel, currentLevelNode.journeyPath)}
                            {nextLevel ? nLevelProcessing(item, nextLevel) : (<></>)}
                        </div>
                    )
                )
            ))}
            </>
            return uiElement;
     
        }
        else {
            return (<></>);
        }

    }

    const LevelItem = (item, level, journeyPath) => {
        console.log("item",item)
        let localTabIndex = tabIndex.current;
        let uiElement = <></>;
        if (level === STARTING_LEVEL) {
            indexOfSubAnswers.current = 1;
            uiElement = 
                <>
                    <div className="container-change-impact col-md-12">

                        <div tabIndex={0}
                            className="title subtitle">
                            {`${item.nodeName}\u00A0`}
                                {
                                    (item.fieldType === "checkboxandtext") || (item.fieldType === "textbox")? 
                                    <p tabIndex={0}
>
                                       {`${(isOtherNode(item.fieldType)) ? ` - ${item.fieldValue}` : ''}`}
                                    </p>
                            :
                            <></>
                                }
                        </div>

                        {
                            (isOtherNode(item.fieldType)) ?
                                (<></>) :
                                (
                                    (item.fieldType !== "nafmButton")
                                        ?
                                        <button className="editanswers"
                                        tabIndex={0}
                                        onClick={() => { handleChangeImpact(journeyPath, item.id,item) }}>
                                        {literals.summary.change}
                                    </button>
                                        :
                                        <button className="editanswers"
                                        tabIndex={0}
                                        onClick={() => { handleChangeCircumstances() }}>
                                        {literals.summary.change}
                                    </button>
                                   
                                )
                        }

                    </div>
                </>
            
        } else {
            uiElement = 
               <ul>    
                    <li tabIndex={0} className='description custom-description'>
                          {indexOfSubAnswers.current}. &nbsp;
                    {`${item.nodeName}${(isOtherNode(item.fieldType)) ? ` - ${item.fieldValue}` : ''}`}</li> 
               </ul>
                indexOfSubAnswers.current += 1;        
        }
    
        tabIndex.current = localTabIndex;
        return uiElement;
    }

    const updateUI = () => {
        calculateChangeImpactTabIndex({}, STARTING_LEVEL);                
        const temp = nLevelProcessing({}, STARTING_LEVEL);
        return temp;
    }

    const handleChangeImpact = (relationPath, selectedImpact,node) => {
         console.log('handleChangeImpact: ', relationPath, selectedImpact,node);
        const selectedLevel = selectedJourney.current.topics.find((item) => {
            return (item.journeyPath.startsWith(relationPath) && item.journeyPath.endsWith(selectedImpact));
        });

        if (selectedLevel) {
            console.log(
                "Reached to impacts calling with relationPath: "
            )
            navigator('/impacts', {
                state: {
                    relationPath: selectedLevel.journeyPath,
                    changeImpactPath: relationPath,
                    editedImpact: selectedImpact,
                    selectedImpact:node
                }
            });
        }
    }

    const handleChangeCircumstances = () => {
        navigator('/needhelp')
    }

    const isOtherNode = (fieldType) => {
        return ((fieldType === 'checkboxandtext') || (fieldType === 'radioandtext') || (fieldType === 'textbox'));
    }

    return (
        <>
            <div tabIndex={0} className="check-your-answer-heading-2">{heading}</div>
            <div className="check-answer-list row">
                 {isUIUpdate && updateUI()}
            </div>
        </>
    );
}

export default AnswerSubPage;
