import { createContext, useState, useRef, useEffect } from "react";
import { redirectGoogleOrClose } from "../utils/helper.methods";

export const ExitContext = createContext({
    isChildNewTab: null,
    isExitClicked: null,
    privacyNewTab: null,
    tncNewTab: null,
    serviceNewTab: null,
    feedbackNewTab: null,
    outputPDFNewTab: null,

    tncExit: null,
    setTncExit: () => null,
    privacyExit: null,
    setPrivacyNoticeExit: () => null,
    serviceExit: null,
    setServiceExit: () => null,
    feedbackExit: null,
    setFeedbackExit: () => null,
    closeAllTabs: () => null,
});

export const ExitProvider = ({ children }) => {
    const isChildNewTab = useRef(false);
    const isExitClicked = useRef(false);
    const privacyNewTab = useRef(null);
    const serviceNewTab = useRef(null);
    const feedbackNewTab = useRef(null);
    const outputPDFNewTab = useRef(null);
    const tncNewTab = useRef(null);

    const [privacyExit, setPrivacyNoticeExit] = useState(null);
    const [tncExit, setTncExit] = useState(null);
    const [serviceExit, setServiceExit] = useState(null);
    const [feedbackExit, setFeedbackExit] = useState(null);


    const closeAllTabs = () => {
        if (!isExitClicked.current) {
          console.log("EXIT TOOL NOT CLICKED");
          return;
        }
      
        closeTabIndividual(privacyExit, "privacyExit");
        closeTabIndividual(feedbackExit, "feedbackExit");
        closeTabIndividual(serviceExit, "serviceExit");
        closeTabIndividual(tncExit, "tncExit");
      
        if (!isChildNewTab.current) {
          redirectGoogleOrClose(window, "APP PARENT");
          closeTabIndividual(privacyNewTab.current, "privacyNewTab proxy");
          closeTabIndividual(tncNewTab.current, "tncNewTab proxy");
          closeTabIndividual(serviceNewTab.current, "serviceNewTab proxy");
          closeTabIndividual(feedbackNewTab.current, "feedbackNewTab proxy");
        }
      
        if (outputPDFNewTab.current) {
          closeTabIndividual(outputPDFNewTab.current, "outputPDFNewTab proxy");
        }
      };

    const closeTabIndividual = (tab, label) => {
        console.log("value inside closetabInd", label, isExitClicked.current, isChildNewTab.current);
        if (tab) {
            redirectGoogleOrClose(tab, label);
            if (isChildNewTab.current) {
                console.log(`HANDLE PARENT ${label}`);
                redirectGoogleOrClose(tab.opener, label + " parent");
            }
        } else {
            console.log('TAB not found: ', label);
        }
    };

    const value = {
        isChildNewTab,
        isExitClicked,
        privacyNewTab,
        tncNewTab,
        serviceNewTab,
        feedbackNewTab,
        outputPDFNewTab,
        privacyExit, setPrivacyNoticeExit,
        tncExit, setTncExit,
        serviceExit, setServiceExit,
        feedbackExit, setFeedbackExit,
        closeAllTabs,
    };

    return (
        <ExitContext.Provider value={value}>{children}</ExitContext.Provider>
    );
};
