const  SET_PRIVACY_URL= 'SET_PRIVACY_URL';
const SET_TNC_URL = 'SET_TNC_URL';
// Initial State
const initialState = {
  privacyUrl: null,
};

export const privacyUrlReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_PRIVACY_URL:
        return {
          ...state,
          privacyUrl: action.payload,
        };
      default:
        return state;
    }
  };

export const tncUrlReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TNC_URL:
      return {
        ...state,
        tncUrl: action.payload,
      };
    default:
      return state;
  }
};