import React, { useState, useEffect, useRef } from 'react';
import "./SecondComponent.scss"
import '../../../themes/SopraTheme/sopra.scss';
import ModalBox from '../../ModalBox/ModalBox';
import { forwardJourney, moveForwardJourney, backwardJourney } from '../../../services/journeyService';
import literals from '../../Literals/Literals';
import { sanitizeInput } from '../../../utils/helper.methods';

export const NeedHelpSecond = (props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);
    console.log("Item", props.optionSelected);
    console.log("Index received", props.optIndex)
    const [run, setRun] = useState(false)
    const [title, setTitle] = useState(null);
    const [optionList, setOptionList] = useState([]);
    const optionSelected = props.optionSelected;
    const [heading, setHeading] = useState(null);
    const [desc, setDesc] = useState(null);
    const needhelpOption = useRef(null);
    const [tags, setTags] = useState([]);
    const [options, setOptions] = useState([]);
    const [otherValue, setOtherValue] = useState("");
    const [loading, setLoading] = useState(true);
    const showHelp = props.showHelp;

    const loadFBJourneyApi = (node, parentRelationNode, isBackJoruney) => {
        console.log(node, parentRelationNode, isBackJoruney)
        const request = createRequestParams(node, parentRelationNode, isBackJoruney);
        console.log(request);
        const response = isBackJoruney ? backwardJourney(request) : forwardJourney(request)
        return response;
    }

    useEffect(() => {
        props.setClassName("helpContinue");
        props.setHelp(true);
        return () => {
            props.setClassName("");
            props.setHelp(false);
        };
    }, []);
    

    useEffect(() => {
        console.log(props.showHelp)
    }, [props.showHelp])

    const moveForward = async (option) => {
        setTags([]);
        const forwardParam = {
            "relationPath": optionSelected.id,
            "parentRelationPath": "",
            "response": [],
            "screenName": "NeedHelp-Vulnerabilities",
            "endOfJourney": true,
            "notapplicable": false
        }

        const response = await moveForwardJourney(forwardParam);
        console.log("Move forward response", response, `${option.relationPath}_V`);
        const filteredData = response.data[0].node.filter(item => item.relationPath.includes(`${option.relationPath}_V`));
        console.log(filteredData)
        setOptions(response.data[0].node.filter(item => item.relationPath.includes(`${option.relationPath}_V`)));
        const uniqueTags = [...new Set(filteredData.map(item => item.tag))];
        console.log("Unique tags list", uniqueTags);
        setTags(uniqueTags);
        console.log(tags);
    }

    useEffect(() => {
        console.log("Tags", tags, "Options", options)
    }, [tags, options])

    const createRequestParams = (node, parentRelationNode, isBackJoruney) => {
        console.log("Node in Param function", node, node.id, isBackJoruney);
        const param = {
            "relationPath": (node.length !== 0) ? node.id : "",
            "parentRelationPath": (isBackJoruney ? "" : (parentRelationNode ? parentRelationNode : "")),
            "response": [],
            "screenName": "NeedHelp-Vulnerabilities",
            "endOfJourney": false
        }
        console.log("Param", param)
        return param;
    }

    useEffect(() => {
        console.log("Use effect ran", optionSelected);
        async function callForwardApi(optionSelected) {
            const final_response = await loadFBJourneyApi(optionSelected, "", false);
            setLoading(false);
            console.log("final response", final_response.data)
            setTitle(final_response.data[0].title);
            console.log(title)
            if (localStorage.getItem(optionSelected.id)) {
                console.log("optionSelected Id", optionSelected.id)
                setOptionList(JSON.parse(localStorage.getItem(optionSelected.id)));
                console.log(optionList)
            }
            else {
                console.log("option selected id in else part", optionSelected.id)
                setOptionList(final_response.data[0].node)
                console.log("Option List", optionList)
            }

            const optionForwardList = final_response.data[0].node.filter(item => item.selected);

            const moveForward = async (option) => {
                const forwardParam = {
                    "relationPath": option,
                    "parentRelationPath": "",
                    "response": [
                    ],
                    "screenName": "NeedHelp-Vulnerabilities",
                    "endOfJourney": true,
                    "notapplicable": false
                }

                const response = await moveForwardJourney(forwardParam);
                const filteredData = response.data[0].node.filter(item => (item.relationPath.toUpperCase().includes((`${optionSelected.id}_V`).toUpperCase()) && item.id.toUpperCase().startsWith("V")));
                optionForwardList.forEach((option) => {
                    const filteredList = filteredData.filter((item) => (
                        item.relationPath.toUpperCase().startsWith(`${option.relationPath}`)

                    ))
                    const relationPath = option.id.toUpperCase().includes("OTHER") ? `${optionSelected.id}_Other` : `${option.relationPath}`
                    const list = option.id.toUpperCase().includes("OTHER") ? option.fieldValue : JSON.stringify(filteredList)
                    { option.fieldType !== "nafmButton" && localStorage.setItem(relationPath, list) };
                })
            }
            moveForward(optionSelected.id)
        }
        console.log("Option List in use effect", optionList)
        callForwardApi(optionSelected)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        console.log("Option List in use effect", optionList)
        const isEnabled = otherValue.length > 0 || optionList.filter((option) => option.selected).length > 0;
        props.setContinue(!isEnabled);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [optionList, otherValue])

    console.log(selectedOptions);

    useEffect(() => {
        setHeading(optionSelected.name)
        setDesc(optionSelected.description);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const openModal = () => {
        console.log("Option in second component to send", options);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        //------------------------------------------------If clicked on close save previous selections of 2b layer---------------------------------//
        setIsModalOpen(false);

        setOptions([]);
    };

    const handleProceed = (selectedOptions) => {
        console.log('Selected Options:', selectedOptions);
        setSelectedOptions(selectedOptions);
        console.log(selectedOptions.length);
        if (selectedOptions?.length) {
            console.log("Option selected from 1st layer", needhelpOption.current)
            optionList.forEach((option) => {
                if (option.name === needhelpOption.current.name) {
                    option.selected = true;
                    props.setContinue(false);
                }
                else if (option.fieldType === "nafmButton") {
                    option.selected = false;
                }
            });
        }
        localStorage.setItem(optionSelected.id, JSON.stringify(optionList));
        localStorage.removeItem(`${optionSelected.id}_NA`);
        console.log(optionList)
    };

    const handleEditKey = (option) => (event) => {
        if (event.key === "Enter") {
            handleOptions(option)
        }
    }
    const handleOptions = (option) => {
        console.log("Option clicked", option)
        if (localStorage.getItem(optionSelected.id)) {
            console.log("optionSelected Id", optionSelected.id)
            setOptionList(JSON.parse(localStorage.getItem(optionSelected.id)));
            console.log(optionList)
        }
        moveForward(option);
        openModal();
        needhelpOption.current = option;
    };

    const handleOtherOption = (value, option) => {
        value = sanitizeInput(value);
        console.log("OTHREEE", value, option, value.length);
        { value.length > 0 ? setOtherValue(value) : setOtherValue("") };
        console.log("othervalue", otherValue);
        console.log("Option field value", option.fieldValue);
        { value.length > 0 ? localStorage.setItem(`${optionSelected.id}_Other`, JSON.stringify(value)) : localStorage.removeItem(`${optionSelected.id}_Other`) };
        { value.length > 0 ? props.setContinue(false) : props.setContinue(true) };
        const updatedOptions = optionList.map((opt) => {
            if (opt.name === option.name) {
                if (value.length > 0) {
                    return { ...opt, fieldValue: value, selected: true };
                }
                else {
                    return { ...opt, fieldValue: value, selected: false };
                }
            }
            else if (opt.fieldType === "nafmButton") {
                localStorage.removeItem(opt.relationPath);
                localStorage.removeItem(`${optionSelected.id}_NA`)
                return { ...opt, selected: false };
            }
            return opt;
        });
        console.log("Updated Options when other", updatedOptions);
        setOptionList(updatedOptions);
        localStorage.setItem(optionSelected.id, JSON.stringify(updatedOptions));
    }

    const handleNAKey = (option) => (event) => {
        if (event.key === "Enter") {
            handleNotApplicable(option);
        }
    }

    const handleNotApplicable = (option) => {
        console.log("Option NAFM Clicked", option);
        const updatedOptions = optionList.map((opt) => {
            if (opt.name === option.name) {
                return { ...opt, selected: !opt.selected };
            }
            else if (opt.fieldType === "textbox") {
                console.log("Reached iother case in nafm case")
                localStorage.removeItem(`${optionSelected.id}_Other`);
                setOtherValue("");
                return { ...opt, selected: false, fieldValue: null };
            }
            else if (opt.fieldType === "button") {
                return { ...opt, selected: false };
            }
            return opt;
        });
        console.log("Option selected", option.selected);
        props.setContinue(option.selected);
        console.log("updated list")
        setOptionList(updatedOptions);
        if (option.selected === false) {
            localStorage.setItem(`${optionSelected.id}_NA`, JSON.stringify(option.id));
        }
        else {
            localStorage.removeItem(`${optionSelected.id}_NA`);
        }
        localStorage.setItem(optionSelected.id, JSON.stringify(updatedOptions));
    }

    const handleKeyPress = (option) => (event) => {
        if (event.key === "Enter") {
            setSelectedFalse(option);
        }
    }
    const setSelectedFalse = (option) => {
        if (option.selected === true) {
            const updatedOptions = optionList.map((opt) => {
                if (opt.name === option.name) {
                    return { ...opt, selected: !opt.selected };
                }

                return opt;
            });
            console.log("updated list")
            setOptionList(updatedOptions);
            localStorage.setItem(optionSelected.id, JSON.stringify(updatedOptions));
            localStorage.removeItem(`${option.relationPath}`)
        }
        else {
            handleOptions(option)
        }
    }


    return (
        <div>
            {}
            {loading ? (
                <div className="loader-container">
                    <div className="spinner"></div>
                </div>
            ) : (

                <div>
                    <div className='headContainer hideonphone'>
                        <div className='firstImage myCir myCir' tabIndex={0}><span className="visual">My circumstances logo</span></div>
                        <div>
                            <h1 className='headingCircumstance' tabIndex={0}>My circumstances</h1>
                            <p className='descCircumstance' tabIndex={0}>{literals.Circumstances.description1}</p>

                        </div>
                    </div>
                    <div className='m-4'>
                        <div className='parent-container'>
                            <div className="greyContainernh row">
                                <div className={`cardDisplay-${props.optIndex} card col-sm-3`}>
                                    <div className='detailsHolder cardContentnh2'>
                                        <h3 className='card-title' tabIndex={0}>{heading}</h3>
                                        <p className='card-text' tabIndex={0}>{desc}</p>
                                    </div>
                                </div>
                                <div className='col-sm-9'>
                                    <h3 className='ms-5 mt-3' tabIndex={0}>{title}</h3>
                                    <div className='buttons_container mt-4 buttonhelp'>
                                        {optionList.map((option) => {
                                            console.log("Option Name", option.name !== "Not applicable for me", option.name)
                                            if ((option.fieldType === "button" && option.name !== "Not applicable for me")) {
                                                return (
                                                    <div
                                                        key={option.name}
                                                        className={`optionsFont defaultOption ${option.selected ? 'selectedOption tSelected' : 'tNselected'} col-sm-3 m-1`}
                                                        data-option={option.name}
                                                        aria-selected={option.selected}
                                                    >
                                                        <span className="modalText" tabIndex={0} onClick={() => setSelectedFalse(option)} onKeyDown={handleKeyPress(option)}>{option.name}</span>
                                                        {option.selected &&
                                                            <span className="editNeedHelp-icon imgD" tabIndex={0} onClick={() => handleOptions(option)} onKeyDown={handleEditKey(option)}><span className='visual'>Edit icon</span></span>
                                                        }
                                                    </div>
                                                );
                                            }
                                            else if (option.fieldType === "textbox") {
                                                return (
                                                    <input
                                                        key={option.name}
                                                        type="text"
                                                        id="inputField"
                                                        value={option.fieldValue !== null ? option.fieldValue : otherValue}
                                                        onInput={(e) => handleOtherOption(e.target.value, option)}
                                                        placeholder="Other (please specify)"
                                                        className={`textboxOption optionsFont`}
                                                    />
                                                );
                                            }
                                            else if (option.fieldType === "nafmButton" || option.name === "Not applicable for me") {
                                                console.log("Hitting NAFM case", option.name)
                                                return (
                                                    <div
                                                        key={option.name}
                                                        className={`optionsFont defaultOption ${option.selected ? 'selectedOption tSelected' : 'tNselected'}  m-1`}
                                                        data-option={option.name}
                                                        aria-selected={option.selected}
                                                    >
                                                        <span className="modalText" tabIndex={0} onClick={() => handleNotApplicable(option)} onKeyDown={handleNAKey(option)}>{option.name}</span>
                                                        {option.selected &&
                                                            <span className="selected-icon imgD" tabIndex={0} onClick={() => handleNotApplicable(option)} onKeyDown={handleNAKey(option)}><span className='visual'>Deselect icon</span></span>
                                                        }
                                                    </div>
                                                )
                                            }
                                            return null;
                                        })}
                                        {isModalOpen ?

                                            <ModalBox
                                                onClose={closeModal}
                                                onProceed={handleProceed}
                                                initialList={options.map(option => option)}
                                                needhelpoption={needhelpOption.current}
                                                tags={tags}
                                            />
                                            : null}


                                    </div></div>
                            </div>
                        </div>
                    </div> </div>
            )}
        </div>
    )
}

