import React from 'react'
import './EditJourneyPopUp.scss';

function EditJourneyPopUp(props) {
    return (
        <div className='modal-overlay'>
        <div className='modalbackground'>
          <div className='header IDModalHeader fixIdHeader'>
                        <span>
                            <button tabIndex={0} aria-label="Close" aria-pressed="false" className="close-button" onClick={props.cancelEdit}>
                                <div className='closeModalImg imgD'></div>
                            </button>
                        </span>
                    </div>
                    <div tabIndex={0} className='modal-content'>
                        <p className='EditJ-content'>We know your circumstances and the impact of these may change. If so, you can edit them in the traige tool. <br/> Please note, this will reset the progress you've made with support options. You can update this when you're back in your Support Plan again. </p>
                    </div>
                    <div className='modal-footer'>
                        <button tabIndex={0} aria-label="I Agree" aria-pressed="false" className='submitText'>
                            <span className='shareText' onClick={props.editJourney} onKeyDown={(e)=>(e.key === "Enter" && e.key === " ") && props.editJourney}>Edit my circumstances or impacts</span>
                        </button>
                    </div>
                </div>
            </div>
      
    )
}

export default EditJourneyPopUp