import React,{useState} from 'react';
import Joyride from "react-joyride";
import './WelcomeComponent.scss'
import literals from '../Literals/Literals'
import ButtonComponent from '../ButtonComponent/ButtonComponent'
import Footer from '../Footer/Footer'
import { useNavigate } from "react-router-dom";
import PageTimeTracking from "../PageTracking/PageTracker";
import { useResumeJourney } from "../../customHooks/useResumeJourney";
import ExitButton from '../ExitButton/ExitButton'

const WelcomeComponent = (props) => {
  const [run,setRun] = useState(false)
  const navigation = useNavigate();
  const getItemFromHelper = useResumeJourney(); 
  const nextPage = getItemFromHelper.nextPage;
  const themeselected = props.theme;
  // const deviceType = props.deviceType.current;
  const clientContactNumber = props.contactNumber;
  const steps = [
    {   target:".exitHelp",
        content:<h2>{literals.help.help.help_exit}</h2>,
        placement: 'bottom',
        disableBeacon: true,
    },
    {   target:".footerPos",
        content:<h2>{literals.help.help.help_feedback}</h2>,
        disableBeacon: true,
  },
]

    const handleShowHelp = () => {
      setRun(true);
  }

  const handleJoyrideCallback = (data) => {
    const { status, index, type } = data;
    const finishedStatuses = ['finished', 'skipped'];

    if (
      finishedStatuses.includes(status) ||
      (type === 'step:after' && index === steps.length - 1)
    ) {
      setRun(false);
    }
  };

  return (
<div className='joyride-tooltip'>
    <Joyride
    steps={steps}
    run={run}
    continuous
    callback={handleJoyrideCallback}
    spotlightClicks={false}
    scrollToFirstStep
    hideBackButton={true}
    locale={{last: 'Close'}}   
     styles={{
      options: {
        arrowColor: '#fff',
        backgroundColor: '#e3ffeb',
        overlayColor: 'transparent',
        primaryColor: '#000',
        textColor: '#004a14',
        zIndex: 1000,
        spotlightPadding: 0,
        textAlign:'left!important',

      },
    }}
    />
    
    <div className='welcomeStyle'>
      <div className='headerTwo'>
        <div className='logoExit'>  
        <div tabIndex={0} className='headerLogo'><span className="visual">Support Point Logo</span></div>
        <div className=' exitLaptop highlight'><div className="help_b" alt="sp_logo" tabIndex={0} onClick={handleShowHelp}><span className="visual">Help Icon</span></div><div className="exitHelp"><ExitButton/></div></div> 
        </div>



        <h1 tabIndex={0} className='headerName'>Welcome</h1>
        <div tabIndex={0} className='welcomeContent'>{literals.welcome.text4}</div>
      </div>
      <div tabIndex={0} className='leftImage'><span className="visual">Lady gesturing to options</span></div>
      <div className='midFootLayer'>
      <div className='midLayer'>
        <div className='rightContent'>
        <div className='welcomeHeadingLap' tabIndex={0}>{literals.welcome.text2}</div>
            <div className='rightImgContent'>
            {/* <div className='exitClass'><ToggleSwitch/><ExitButton/></div> */}


            <div className='welcomeHeadingMob'>{literals.welcome.text2}</div>
                <div className='card cardContent'>
                    <div className='firstImage' tabIndex={0}><span className="visual">My circumstances image</span></div>
                    <div tabIndex={0} className='firstContent'>
                        <div className='firstHeading'>{literals.welcome.title1}</div>
                        <div className='desc'>{literals.welcome.description1}</div>
                    </div>
                </div>
                <div className='card cardContent'>
                    <div className='secondImage' tabIndex={0}><span className="visual">How this impacts me image</span></div>
                    <div tabIndex={0} className='secondContent'>
                        <div className='secondHeading'>{literals.welcome.title2}</div>  
                        <div className='desc'>{literals.welcome.description2}</div>
                    </div>
                </div>
                <div className='card cardContent'>
                    <div className='thirdImage' tabIndex={0}><span className="visual">Choose support image</span></div>
                    <div tabIndex={0} className='thirdContent'>
                        <div className='thirdHeading'>{literals.welcome.title3}</div>
                        <div className='desc'>{literals.welcome.description3}</div>
                </div>
              </div> 


              <div className='card cardContent CCL'>
                    <div className='fourthImage' tabIndex={0}><span className="visual">My support plan image</span></div>
                    <div tabIndex={0} className='thirdContent'>
                        <div className='thirdHeading'>{literals.welcome.title4}</div>
                        <div className='desc'>{literals.welcome.description4}</div>
                </div>
              </div> 

                

            </div>

            <div className='welsh' >

            <div className='welshbtn' tabIndex={0} > <b>Ydych chi'n Gymro?</b>  <br/> <span className='welscColor'>Ffoniwch ni ar <b> {clientContactNumber} </b> </span>
</div>
            <ButtonComponent tabIndex={0} classname="startButton" divClass="startStyle" disabled={false} name="Let's get started" onClick={() => {
                    navigation(`/${nextPage}`, { replace: true });
                  }}>
            </ButtonComponent>

            </div>
           
        </div>
      </div>
  <div className='highlight'> <Footer  c screenName="Welcome" theme={themeselected} homepage={false}/> </div>
      <PageTimeTracking pageName="Welcome" />
      </div>  
    </div>
    </div>
  )
}

export default WelcomeComponent