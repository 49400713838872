import React from 'react';
import "../../global.scss";
import '../../../themes/SopraTheme/sopra.scss';
import "../LocationSpecific.scss";
import literals from "../../Literals/Literals";

const ShowMoreLess = ({ showAll, toggleShowAll, ID }) => {
    return (
        <>
        {!ID ? <div className="buttonShowMore">
            <label tabIndex={0} htmlFor="toggleButton" className="labelShowMoreless">
                {showAll ? literals.PostCode.ShowLess : literals.PostCode.ShowMore}
            </label>
            <button tabIndex={0} aria-pressed="false" aria-label={`${!showAll ? 'Show more' : 'Show Less'}`} id="toggleButton" onClick={toggleShowAll} className="ShowMoreLess">
                {showAll ? (
                    <div className='showLess1'></div>
                ) : (
                    <div className='showMore1'></div>
                )}
            </button>
        </div> :
         <div className="buttonShowMoreID">
             <span tabIndex={0} htmlFor="toggleButton" className="labelShowMorelessID">
                {showAll ? literals.PostCode.ShowLess : literals.PostCode.ShowMore}
            </span>
            <button tabIndex={0} aria-pressed="false" aria-label={`${!showAll ? 'Show more' : 'Show Less'}`} id="toggleButton" onClick={toggleShowAll} className="ShowMoreLessID">
                {showAll ? (
                    <div className='showLess2'></div>
                ) : (
                    <div className='showMore2'></div>
                )}
            </button>
         </div>
         }
        </>
        
    );
};

export default ShowMoreLess;
