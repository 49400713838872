import Footer from "../Footer/Footer";
import "../global.scss";
import React, { useEffect, useState } from 'react';
import literals from "../Literals/Literals";
import { useNavigate } from "react-router-dom";
import "./DemographicConsentComponents.scss";
import PageTimeTracking from "../PageTracking/PageTracker";
import BackButton from "../BackButton/BackButton";
import ButtonComponent from '../ButtonComponent/ButtonComponent.jsx';
import ExitButton from "../ExitButton/ExitButton";
import { useSelector } from "react-redux";
import { FeedBackNew } from '../Feedbacknew/FeedBackNew';
import Header from "../Header/Header.jsx";
import Joyride from "react-joyride";
import {getRealTimeUserJourney} from "../../services/getSummaryService";


export default function Consent(props) {
  const navigation = useNavigate();
  const buttonText = "Back";
  const quickquestions = "Please share your feedback";
  const supportPack = "No thanks, jump to my Support Plan";
  const [run,setRun] = useState(false)
  //---------------------------------------------- Will need below code once workflow will be corrected------------------------------------------------//
  // var getItemFromHelper = useResumeJourney(); 
  // const prevPage = getItemFromHelper.prevPage;
  const privacy_url = useSelector((state) => state.privacyUrl.privacyUrl);
  console.log("Privacy url in consent", privacy_url);
  var subheading4 = literals.ConsentPage.subheading4
  const linkText = literals.ConsentPage.linkText
  const index = subheading4.indexOf(linkText);
  const beforeSubstring = subheading4.substring(0, index);
  const afterSubstring = subheading4.substring(index + linkText.length);
  console.log(beforeSubstring, afterSubstring);
  const [showHelp, setShowHelp] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [notifServiceReq, setNotifServiceReq] = useState(null);
  const notifRequired = useSelector((state) => state.dataNotifService.notifServiceReq);

  useEffect(() => {
    setNotifServiceReq(notifRequired);
  })

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const FeedbackOpen = () => {
    openModal();
  }

  const steps = [
    {   target:".quickquestionsButton",
        content:<h2>{literals.help.demographic_Consent.demographic_Feedback}</h2>,
        placement: 'bottom',
        disableBeacon: true,
    },
    {   target:".noThanksButton",
        content:<h2>{literals.help.demographic_Consent.demograhic_Consent_SupportPack}</h2>,
        disableBeacon: true,
  },
]

   const handleShowHelp = () => {
      setRun(true);
  }

  const handleJoyrideCallback = (data) => {
    const { status, index, type } = data;
    const finishedStatuses = ['finished', 'skipped'];

    if (
      finishedStatuses.includes(status) ||
      (type === 'step:after' && index === steps.length - 1)
    ) {
      setRun(false);
    }
  };


  async function handleContinue() {
    if(notifServiceReq) {
      await getRealTimeUserJourney();
    }
    navigation("/outputscreen");
  }


  const themeselected = props.theme;

  return (
    <div className="parent-container-2">
      <Joyride
    steps={steps}
    run={run}
    continuous
    callback={handleJoyrideCallback}
    spotlightClicks={false}
    scrollToFirstStep
    hideBackButton={true}
        locale={{last: 'Close'}}
    styles={{
      options: {
        arrowColor: '#fff',
        backgroundColor: '#e3ffeb',
        overlayColor: 'transparent',
        primaryColor: '#000',
        textColor: '#004a14',
        zIndex: 1000,
        spotlightPadding: 0,
      },
    }}
    />

      <Header tabIndex={0} homepage={false} />
      {showHelp &&
        <div className='help'>
          <div className='closeIcon' onClick={handleShowHelp}> Close</div>

          <div className='shareFeedback'>
            <div className='helpHeader'> Lorem ipsum dolor sit amet,</div> consectetur adipiscing elit.
            Duis varius, nisi vel dignissim pellentesque, ante sem pretium nibh
            <div className='upArrow_S'>   </div>  </div>


          <div className='jumptoSupport'>
            <div className='helpHeader'> Lorem ipsum dolor sit amet,</div> consectetur adipiscing elit.
            Duis varius, nisi vel dignissim pellentesque, ante sem pretium nibh,

            <div className='downArrow_S'>   </div>  </div>




        </div>
      }
      <div className="headerConsent mobileDemoheader">
      </div>
      <div className="exitLaptop DC-fixHeader">
        <div className="help_b" alt="sp_logo" tabIndex={0} onClick={handleShowHelp}><span className="visual">Help Icon</span></div>
        <ExitButton />
      </div>
      <div className="consentContent mt-5">
        <div tabIndex={0} className="rightImageWellDone "><span className="visual">applauding, expressing appreciation for job well done</span></div>
        <h1 tabIndex={0} className="headerName welldoneMobile">{literals.Demographic.heading} </h1>
      </div>


      <div className="internal-container-constent">
        <div className="consentContent consentwelldone">
          {/* Main content text here >>> */}
          <div tabIndex={0}> <h2 className="subHeading"> {literals.Demographic.subheading1} </h2> </div>
          <p tabIndex={0} className="customFontBlack"> {literals.Demographic.subheading2} </p>
          <p tabIndex={0} className="customFontBlack"> {literals.Demographic.subheading3}</p>

        </div>


        <div className="btnContainerSupportPack m-5">
          <ButtonComponent
            classname="quickquestionsButton"
            divClass="btnContainerSupportPack"
            tabIndex={0}
            name={quickquestions}
            disabled={false}
            onClick={FeedbackOpen}
          />
          <ButtonComponent
            classname="noThanksButton"
            divClass="btnContainerSupportPack"
            tabIndex={0}
            name={supportPack}
            disabled={false}
            onClick={handleContinue}
          />
        </div>

        {/* Bottom content here */}
        <div className="btnContainer">
          <BackButton label={buttonText} onClick={() => {
            navigation('/postcode');
          }} />
        </div>

        <div className="internal-child-3">
          {/* Footer here >>> */}
          <Footer theme={themeselected} homepage={false} />
          <PageTimeTracking pageName="demographic-consent" />
        </div>
      </div>
      <FeedBackNew screenName="demographic-consent" isOpen={isModalOpen} onClose={closeModal} />
    </div>
  );
}