import React, { useEffect, useRef} from 'react';
import { timeService } from '../../services/timeService';

const PageTimeTracking = (props) => {

  const entryTimeRef = useRef(null);
  const entryTime = useRef(null);
  const exitTime = useRef(null);

  useEffect(() => {
    // Component mount logic (page entry)
    console.log("Component mounted");
    entryTimeRef.current = new Date();

    // Component unmount logic (page exit)
    return () => {
      console.log("Component unmounted");
      const exTime = new Date();
      const exTimeConvert = exTime.toISOString();
      exitTime.current = exTimeConvert;
      const enTime = entryTimeRef.current;
      const enTimeConvert = enTime.toISOString();
      
      entryTime.current = enTimeConvert;
      const request = {
        "user_screentime_screen_name": props.pageName,
        "user_screentime_start_time": entryTime.current,
        "user_screentime_end_time": exitTime.current
    }

        timeService(request).then((response) => {
          console.log(response)
        }).catch((err) => {
          console.log(err)
        }).finally(() => {
        })
    };
  }, [props.pageName]);

  return <></>;
};


export default PageTimeTracking;
