import axios from "axios";
import { REQUEST_HEADER_TOKEN,SOURCE_ORIGIN,SAS_TOKEN } from './default.network';
import { publish } from "../utils/events/events";
import { EVENT_EXIT } from "../utils/events/events.name";

// Set config defaults when creating the instance
const tokenClient = axios.create({
  baseURL:process.env.REACT_APP_API_URL_TOKEN
})


export default tokenClient

// Add a request interceptor

tokenClient.interceptors.request.use(function (config) {

  config.headers = REQUEST_HEADER_TOKEN
  config.headers['sas_token'] = SAS_TOKEN.token
   config.headers['source_origin']=SOURCE_ORIGIN.url
  return config;
  
}, function (error) {
  console.log(error);
  return Promise.reject(error);

});


// Add a response interceptor
tokenClient.interceptors.response.use(function (response) {

  console.log(response.data);

  return response;

}, function (error) {

  console.log(error);
  if (error.response) {
    //Unauthorized
    if (error.response.status === 401) {
       publish(EVENT_EXIT);
          }
    // 400 --Client Id is not passed initially in the request
    if ((error.response.status === 400) && (error.response.data.toLowerCase().includes("the field 'client_id' cannot be empty")===true)) {
      publish(EVENT_EXIT);
    }
    if(error.response.status === 403 || error.response.status === 500 || error.response.status === 503 || error.repsonse.status === 504){
      console.log("redirect to error")
      return Promise.reject(error.response.status);
    }

  }
  return Promise.reject(error);

});
