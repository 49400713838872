const SET_REVIEW_DATE = 'SET_REVIEW_DATE';

// Initial State
const initialState = {
  reviewDate: null,
};
const reviewDateReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_REVIEW_DATE:
        return {
          ...state,
          reviewDate: action.payload,
        };
      default:
        return state;
    }
  };

export default reviewDateReducer;